.multi-selector-wrap{
  width: 100%;
  .multi-selector{
    width: 100%;
    border-radius: 3px;
    padding: 5px 5px 3px;
    background-color: #262626;
    border: 1px solid #363636;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .selected-box{
      display: flex;
      flex-wrap: wrap;
      span{
        &.single-value{
          background-color: #363636;
          padding: 2px 5px;
          border-radius: 3px;
          margin-left: 2px;
          margin-bottom: 2px;
          .fa-times{
            margin: 0 2px;
            &:hover{
              color: blue;
            }
          }
        }
      }
    }
  }
  .selection-box{
    background-color: #262626;
    padding: 5px;
    p{
      &.tag-value{
        padding: 5px;
        &:hover{
          background-color: #363636;
        }
      }
    }
  }
}
