.post-timeline-wrapper{
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 40px;
  padding-right: 40px;

  .single-post-description{
    text-align: left;
  }

  .indexed-title-wrapper{
    display: flex;
    flex-direction: row;
  }

  .post-index-timeline {
    font-weight: bold;
    font-size: 90px;
    color: #D3E6ED;
  }

  .post-title-timeline-wrapper{
    display: flex;
    align-items:center;
    margin-left: -20px;

    .post-title-timeline {
      font-size: 25px;
      text-align: left;
    }

  }

  .post-timeline-image-wrapper {
    width:  100%;
    text-align: center;
    .post-timeline-image{
      object-fit: contain;
      max-width: 100%;
      max-height: 100%;
      width: auto;
      height: auto;
    }
  }

}
