.image-separator {
  .image-container-separator {
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    background-color: transparent;
    padding: 7%;
    display: flex;
    align-items: center;
    justify-content: center;
    span {
      font-size: 40px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #4a4a4a;
    }
  }
  .message-box-separator{
    padding: 50px;
    align-items: center;
    text-align: justify;
    color: #666666;
    text-align: center;
  }
}
