.modal-fluxio {
  &.publish-site-button-modal {
    // .modal-dialog
    //   height: calc(100% - 3.50rem) // 3.50rem is 2x1.75, 1.75 is the top margin given by bootstrap
    // .modal-content
    //   height: 100%
    .outdated-sites-list {
      padding: 20px 0;
      overflow-y: auto;
      display: flex;
 }      // flex: 1 1 100%
    .loading-div {
      margin-top: 150px; }
    .alert-group {
      justify-content: center; } } }
