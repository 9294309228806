.element-hover {
  z-index: 25;
  position: absolute;
  width: 100%;
  height: 100%;
  pointer-events: none;
  .block {
    position: absolute; }
  .property {
    position: relative;
    height: 100%;
    width: 100%;
    .margin {
      position: absolute; }
    .padding {
      position: absolute; }
    .top {
      top: 0; }
    .left {
      top: 0;
      left: 0; }
    .bottom {
      bottom: 0; }
    .right {
      bottom: 0;
      right: 0; } }
  .content {
    width: 100%;
    height: 100%; }
  .margin {
    background-color: rgba(255, 197, 79, 0.5); }
  .padding {
    background-color: rgba(89, 255, 79, 0.5); }
  .content {
    background-color: rgba(79, 181, 255, 0.5); }
  .object-label {
    position: absolute;
    border-radius: 3px;
    white-space: nowrap;
    left: 0;
    display: block;
    font-size: 12px;
    padding: 2px 5px;
    background-color: rgba(74, 177, 251, 0.7);
    z-index: 15;
    color: #fff; }
  //-webkit-filter: brightness(50%)
  //-webkit-transition: all 1s ease
  //-moz-transition: all 1s ease
  //-o-transition: all 1s ease
  //-ms-transition: all 1s ease
  //transition: all 1s ease
  //&:hover
  //  //background-color: rgba(74, 176, 251, 0.6)
  //  cursor: pointer
  //  -webkit-filter: brightness(100%)
  //  transition: 1s ease
  //  .element-hover:after
  //    position: absolute
  //    top: 0
  //    left: 0
  //    width: 100%
  //    height: 100%
  //    background-color: blue
  //  >.object-label
  //    position: absolute
  //    top: 0
  //    left: 0
  //    display: block
  //    font-size: 12px
  //    padding: 2px 5px
  //    background-color: rgb(74, 176, 251)
  //    z-index: 15
 }  //    color: #fff
.object-label {
  display: none; }
