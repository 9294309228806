// = Mixin
// -----------------------------
@mixin overflow-text-vertical($lines, $lineHeight){
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $lines;
  -webkit-box-orient: vertical;
  line-height: $lineHeight;
  max-height: $lineHeight * $lines;
}

@mixin aspect-ratio($width, $height) {
  $devidedValue: $height / $width;
  position: relative;
  &:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: $devidedValue * 100%;
  }
  > .content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
