.board-preview {
  background-color: #f7f8f8;
  height: 100%;
  overflow-y: hidden;
  overflow-x: hidden;
  .browser-header {
    background: #f7f8f8;
    padding: 7px;
    display: flex;
    align-items: center;
    height: 35px;
    border-radius: 3px;
    margin: 10px auto 0 auto;
    box-shadow: 0 5px 20px 0 rgba(32,48,60, 0.15);
    .browser-dot {
      height: 10px;
      width: 11px;
      background: #d9dde1;
      border-radius: 50%; }
    .browser-input {
      background: #d9dde1;
      height: 20px;
      border-radius: 10px;
      width: 90%;
      font-size: 10px;
      padding: 2px 10px;
      color: #333; } }
  .board-preview-frame {
    position: relative;
    display: block;
    height: 100%;
    overflow: hidden;
    box-shadow: 0 5px 20px 0 rgba(32,48,60, 0.15);
    border: none !important;
    body {
      overflow: hidden;
      background: transparent; } } }
