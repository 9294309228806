.stylesheet-panel {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  // .stylesheet-inner-panel
  //   max-height: 400px
  //   overflow-y: auto
  //   overflow-x: visible>
  .stylesheet-panel-colors-grid {
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 10px; }
  .color-group {
    .color-picker-container:hover {
      box-shadow: 0px 0px 1px 1px rgba(0,0,0, 0.2);
      cursor: pointer; } }
  .color-picker-backdrop {
    z-index: 999; }
  // .color-picker-wrap
  //   top: 5px
  //   transform: none
  //   right: initial
  .color-picker-wrap,
  .color-picker-loader {
    left: 100%;
 }    // left: 5px
  .color-picker-container {
    width: 100%; }
  .color-preview {
    width: 100%;
    height: 50px; } }
