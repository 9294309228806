.fluxio-nav-tabs {
  display: flex;
  justify-content: center;
  align-items: center;
  &.nav-tabs-horizontal {
    flex-direction: row; }
  &.nav-tabs-vertical {
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    .nav-tab {
      margin: 0 0 25px 0;
      border-left: 2px solid #C9D6EB;
      border-bottom: none;
      color: #C9D6EB;
      font-size: 16px;
      font-weight: bold;
      padding: 5px 0 5px 15px;
      &.active {
        border-left: 2px solid $fluxio-blue;
        border-bottom: none;
        color: $fluxio-blue; }
      &:hover {
        text-decoration: none;
        color: $fluxio-blue;
        border-left: 2px solid $fluxio-blue; } } }
  .nav-tab {
    font-size: 14px;
    margin: 0 15px 0 0;
    text-align: center;
    color: #009fdb;
    &:last-child {
      margin-right: 0;
      margin-bottom: 0; }
    &.active {
      border-bottom: 1px solid #009fdb; }
    &:hover {
      text-decoration: none;
      color: #009fdb; }
    &.row {
      flex-direction: row !important;
      justify-content: center;
      align-items: center;
      img {
        margin-right: 10px; } } } }
