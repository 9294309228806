$MediaRadius: 5px;

@mixin fade-animation-off {
  visibility: hidden;
  transition: visibility 0s, opacity 0.3s linear;
  opacity: 0; }
@mixin fade-animation-on {
  visibility: visible;
  opacity: 1; }

.page-template-selector {
  .template-card {
    margin-bottom: 10px;
    border-radius: $MediaRadius;
    position: relative;
    height: 150px;
    background: -webkit-linear-gradient(45deg, rgba(0, 0, 0, 0.0980392) 25%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.0980392) 75%, rgba(0, 0, 0, 0.0980392) 0), -webkit-linear-gradient(45deg, rgba(0, 0, 0, 0.0980392) 25%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.0980392) 75%, rgba(0, 0, 0, 0.0980392) 0), white;
    background: -moz-linear-gradient(45deg, rgba(0, 0, 0, 0.0980392) 25%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.0980392) 75%, rgba(0, 0, 0, 0.0980392) 0), -moz-linear-gradient(45deg, rgba(0, 0, 0, 0.0980392) 25%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.0980392) 75%, rgba(0, 0, 0, 0.0980392) 0), white;
    background: linear-gradient(45deg, rgba(0, 0, 0, 0.0980392) 25%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.0980392) 75%, rgba(0, 0, 0, 0.0980392) 0), linear-gradient(45deg, rgba(0, 0, 0, 0.0980392) 25%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.0980392) 75%, rgba(0, 0, 0, 0.0980392) 0), white;
    background-repeat: repeat, repeat;
    background-position: 0px 0, 5px 5px;
    -webkit-transform-origin: 0 0 0;
    transform-origin: 0 0 0;
    -webkit-background-origin: padding-box, padding-box;
    background-origin: padding-box, padding-box;
    -webkit-background-clip: border-box, border-box;
    background-clip: border-box, border-box;
    -webkit-background-size: 10px 10px, 10px 10px;
    background-size: 10px 10px, 10px 10px;
    -webkit-box-shadow: none;
    box-shadow: none;
    text-shadow: none;
    -webkit-transition: none;
    -moz-transition: none;
    -o-transition: none;
    transition: none;
    -webkit-transform: scaleX(1) scaleY(1) scaleZ(1);
    transform: scaleX(1) scaleY(1) scaleZ(1);
    &.small {
      height: 150px; }
    &.big {
      height: 200px;
      margin-right: 10px; } //needs custom-gutter-right at row level
    &.selected {
      box-shadow: 0px 0px 0px 1px $fluxio-blue; }
    img {
      border-radius: $MediaRadius;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: top;
      transition: object-position 1s ease-in-out;
      &:hover {
        object-position: 100%;
        transition: object-position 5s ease-in-out; } }
    .template-card-body {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: rgba(0, 0, 0, 0.5);
      border-radius: $MediaRadius;
      padding: 10px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      &.page-selected {
        border: 2px solid $fluxio-blue; }
      .template-title {
        font-size: 12px;
        font-weight: 600;
        color: #fff; }
      .template-name {
        font-size: 11px;
        font-weight: 400;
        color: #fff; }
      .template-card-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: $fluxio-blue;
        .template-actions {
          @include fade-animation-off;
          > * {
            font-size: 12px;
            color: #fff;
            padding: 5px;
            border-radius: 3px; } }
        ////For the select icon
        .fas {
          &.selected {
            @include fade-animation-on; }
          &.not-selected {
            @include fade-animation-off; } } }
      .template-card-info {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        color: #fff;
        .fas, .far {
          @include fade-animation-off;
          font-size: 12px;
          color: #fff;
          padding: 5px;
          border-radius: 3px; } }
      &:hover {
        .template-card-header {
          .template-actions {
            @include fade-animation-on;
            >*:hover {
              background-color: rgba(0, 0, 0, 0.5);
              cursor: pointer; } } }
        .template-card-info {
          >*:hover {
            background-color: rgba(0, 0, 0, 0.5);
            cursor: pointer; }
          .fas, .far {
            @include fade-animation-on; } } } } }
  p {
    color: #fff;
    text-transform: uppercase; } }
