.profile-info-content{
  background-color: #f8f8f8;
}

.profile-content{
  .loading-profile{
    margin-top: 59px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .tabs{
    .nav-tabs{
      border: none;
      li.nav-item{
        width: 100%;
        text-align: center;
        a.nav-link{
          cursor: pointer;
          border: none;
          font-weight: 300;
          &.active{
            border: none;
            border-bottom: 2px solid #62b0e1;
            font-weight: 700;
          }
          &:hover{
            font-weight: 700;
          }
        }
      }
    }
  }
}



.pagination{
  margin-top: 10px;
  justify-content: center;
  align-items: center;

  ul{
    li{
      &.active{
        a{
          color: #0275d8;
          font-weight: bold;
          border: 1px solid #cfcfcf;
          border-radius: 2px;
          padding: 5px 10px;
        }
      }
      a{
        color: #191919;
        padding: 5px 10px;
        &:hover{
          border: 1px solid #cfcfcf;
          border-radius: 2px;
          cursor: pointer;
        }
      }
    }

  }
}

.profile-powerwall{
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 2px;

  h1{
    text-transform: uppercase;
    font-weight: 700;
    text-align: center;
    text-decoration: underline;
    font-size: 1em;
  }

}

/* LIBRARY POSTS */
@mixin aspect-ratio($width, $height) {
  $devidedValue: $height / $width;
  position: relative;
  &:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: $devidedValue * 100%;
  }
  > .content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

.col-md-6.library-post{
  margin-bottom: 20px;
}

.library-post-single{
  background-color: #fff;
  box-shadow: 0 2px 4px 0 rgba(187, 187, 187, 0.5);
  .default-header{
    .header-img{
      @include aspect-ratio(1920, 1080);
      background-position: center center;
      background-size: cover;
      background-repeat: no-repeat;
    }
  }
  .header-info{
    padding: 20px;
    .title{
      font-weight: bold;
      font-size: 1.5em;
      line-height: 1.25;
    }
    .message{
      font-size: 1em;
      color: #999;
      line-height: 1.63;
    }
    .description{
      font-size: 0.875em;
      color: #797979;
      line-height: 1.57;
    }
    .date{
      font-size: 0.750em;
      color: #acacac;
      line-height: 1.83;
    }
  }
  .body-info{
    .content-info{
      h6{
        font-size: 0.750em;
        color: #acacac;
        text-transform: uppercase;
      }
      .row{
        .col-sm-6, .col-12{
          margin-bottom: 20px;
        }
        .blue-pill{
          background-color: #5a7dde;
          border-radius: 3px;
          color: #fff;
          font-weight: bold;
          padding: 7px 10px;
          border: 0;
          text-align: left;
          font-size: 0.750em;
          margin-right: 10px;
          margin-bottom: 10px;
        }
        .purple-pill{
          background-color: #9e78f4;
          border-radius: 3px;
          color: #fff;
          font-weight: bold;
          padding: 7px 10px;
          border: 0;
          text-align: left;
          font-size: 0.750em;
          margin-right: 10px;
          margin-bottom: 10px;
        }
        .green-pill{
          background-color: #3ac4a2;
          border-radius: 3px;
          color: #fff;
          font-weight: bold;
          padding: 7px 10px;
          border: 0;
          width: 100%;
          text-align: left;
          font-size: 0.750em;
        }
      }
      .Collapsible__trigger{
        color: #666;
        text-transform: uppercase;
        font-size: 0.750em;
        border-top: 1px solid #e3e3e3;
        border-bottom: 1px solid #e3e3e3;
        width: 100%;
        display: inline-block;
        padding: 15px;
        margin-bottom: 10px;
      }
    }
  }
  .footer-info{
    padding: 15px;
    display: flex;
    justify-content: space-between;
    a{
      color: #bbbbbb;
      &.external-link{
        border-radius: 3px;
        padding: 5px 8px;
        border: 1px solid #dddddd;
      }
    }
  }
}

.library-facebook-single{
  background-color: #fff;
  box-shadow: 0 2px 4px 0 rgba(187, 187, 187, 0.5);
  .facebook-header{
    padding: 15px;
    img{
      width: 30px;
      border-radius: 50%;
    }
    span{
      color: #333333;
      font-weight: bold;
      font-size: 0.875em;
      margin-left: 10px;
    }
    .quote{
      display: flex;
      margin: 25px 0 30px;
      i{
        margin: 0 10px 0;
        color: #797979;
      }
      .message{
        color: #797979;
        font-size: 0.875em;
        line-height: 1.57;
        margin-top: 0;
      }
    }
  }
  .default-header{
    .header-img{
      @include aspect-ratio(1920, 1080);
      background-position: center center;
      background-size: cover;
      background-repeat: no-repeat;
    }
  }
  .header-info{
    padding: 20px;
    .title{
      font-weight: bold;
      font-size: 1.5em;
      line-height: 1.25;
    }
    .message{
      font-size: 1em;
      color: #999;
      line-height: 1.63;
    }
    .description{
      font-size: 0.875em;
      color: #797979;
      line-height: 1.57;
    }
    .date{
      font-size: 0.750em;
      color: #acacac;
      line-height: 1.83;
    }
  }
  .body-info{
    .content-info{
      h6{
        font-size: 0.750em;
        color: #acacac;
        text-transform: uppercase;
      }
      .row{
        .col-sm-6, .col-12{
          margin-bottom: 20px;
        }
        .blue-pill{
          background-color: #5a7dde;
          border-radius: 3px;
          color: #fff;
          font-weight: bold;
          padding: 7px 10px;
          border: 0;
          text-align: left;
          font-size: 0.750em;
          margin-right: 10px;
        }
        .purple-pill{
          background-color: #9e78f4;
          border-radius: 3px;
          color: #fff;
          font-weight: bold;
          padding: 7px 10px;
          border: 0;
          text-align: left;
          font-size: 0.750em;
          margin-right: 10px;
        }
        .green-pill{
          background-color: #3ac4a2;
          border-radius: 3px;
          color: #fff;
          font-weight: bold;
          padding: 7px 10px;
          border: 0;
          width: 100%;
          text-align: left;
          font-size: 0.750em;
        }
      }

      .Collapsible__trigger{
        color: #666;
        text-transform: uppercase;
        font-size: 0.750em;
        border-top: 1px solid #e3e3e3;
        border-bottom: 1px solid #e3e3e3;
        width: 100%;
        display: inline-block;
        padding: 15px;
        margin-bottom: 10px;
      }
    }
  }
  .footer-info{
    padding: 15px;
    display: flex;
    justify-content: space-between;
    a{
      color: #bbbbbb;
      &.external-link{
        border-radius: 3px;
        padding: 5px 8px;
        border: 1px solid #dddddd;
      }
    }
  }
}
