.extra-counter-content,
.extra-weather-content,
.extra-calendar-content {
  border: 1px solid #e3e3e3;
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  margin: 5px 0;
  .ecc-header {
    display: flex;
    border-bottom: 1px solid #e3e3e3;
    justify-content: space-around;
    .action {
      display: flex;
      justify-content: center;
      align-items: center;
      color: #8a8a8a !important;
      cursor: pointer;
      .fas {
        align-self: center !important;
        font-size: 14px;
        color: #8a8a8a !important; } } }
  .ecc-body {
    font-size: 14px; }
  img {
    width: 100%;
    height: auto; }
  span {
    margin: 0 10px; } }

.extra-counter {
  .btn-wrap {
    display: flex;
    .apply-layout {
      margin: 0 2px; } }
  .input-wrap {
    .select-wrapper {
      display: flex;
      align-items: center;
      .select-input {
        background-color: yellow;
        width: auto;
        margin-right: 10px; } }
    .input {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      .loading-image {
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgba(255, 255, 255, 0.8); } } } }
