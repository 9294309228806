.card {
  &.card-post {
    position: relative;
    margin-bottom: 20px;
    border: none;
    box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.09), 0 1px 1px 0 rgba(0, 0, 0, 0.05), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
    &.card-medium {
      .card-img-top {
        height: 175px; }
      .card-body {
        .card-title {
          font-size: 18px; }
        .card-text {
          display: none; }
        .date {
          font-size: 14px; } }
      .card-footer {
        width: 100%;
        .Collapsible {
          margin-top: 0;
          .extraPostGalleryInfo {
            padding: 13px; } }
        .post-share {
          padding: 10px 0; } } }
    &.card-small {
      flex-direction: row;
      flex-wrap: wrap;
      .card-img-top {
        max-width: 200px;
        height: 100px;
        flex: 1; }
      .card-body {
        flex: 1;
        padding: 13px;
        .card-title {
          font-size: 16px;
          margin-bottom: 0; }
        .card-text {
          display: none; }
        .date {
          font-size: 12px; } }
      .card-footer {
        width: 100%;
        .Collapsible {
          margin-top: 0;
          .extraPostGalleryInfo {
            padding: 10px; } }
        .post-share {
          padding: 10px 0; } } }
    .card-img-top {
      width: 100%;
      height: 300px;
      background: #d3d6dc;
      img,
      .card-img-placeholder {
        width: 100%;
        height: 100%;
        object-fit: cover; }
      .card-img-placeholder {
        background: #d3d6dc;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        font-size: 50px; } }
    .card-entity {
      position: absolute;
      top: 10px;
      left: 10px;
      background: #6d6d6d75;
      padding: 2px 5px;
      border-radius: 2px;
      display: flex;
      align-items: center;
      &.edit {
        left: auto;
        right: 10px; }
      i,
      >p {
        color: #fff;
        font-size: 12px;
        text-transform: uppercase;
        text-shadow: 1px 1px 1px #424242;
        font-weight: 600; } }
    .card-title {
      font-size: 20px;
      font-weight: 600; }
    .card-text {
      .dangerousHTML {
        &.message {
          margin: 0; } } }
    .card-footer {
      background-color: #fff;
      padding: 0;
      .post-share {
        padding: 15px 0;
        display: flex;
        justify-content: space-around;
        align-items: center; }
      .Collapsible {
        border-top: 1px solid #e3e3e3;
        border-bottom: 1px solid #e3e3e3;
        font-size: 12px;
        text-transform: uppercase;
        cursor: pointer;
        .extraPostGalleryInfo {
          padding: 20px; } }
      .card-extra-panel {
        padding: 20px;
        background-color: rgba(163, 175, 183, 0.1);
        .plus-info {
          display: flex;
          flex-wrap: wrap; }
        .tags {
          margin-bottom: 10px;
          &.content {
            .inputWrapper {
              padding: 5px 0;
              .smallInputText {
                font-size: 12px; }
              .btn:disabled {
                background: #afb0b3; } } }
          h6 {
            font-size: 12px;
            color: #333;
            text-transform: uppercase; }
          button {
            border-radius: 3px;
            color: #fff;
            font-weight: 700;
            padding: 2px 3px;
            border: 0;
            margin-left: 5px;
            &.red-pill {
              background-color: #e91e63; }
            &.green-pill {
              background-color: #3ac4a2; }
            &.purple-pill {
              background-color: #5a7dde; }
            &.orange-pill {
              background-color: #FF8000; } } } } } } }
