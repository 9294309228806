.info {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 50px 25px;
  .msg {
    font-size: 13px;
    font-weight: 500;
    letter-spacing: -0.1px;
    text-align: center;
    color: #a3a7af;
    margin: 0; } }

.buttons-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px; }
.btn-em {
  background-color: $meshapp-grey;
  color: #ffffff;
  font-size: 13px;
  font-weight: 500;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  &.btn-em-loading {
    background-color: $meshapp-grey-dark;
    cursor: wait; }
  &.btn-em-enable {
    background-color: $fluxio-blue; }
  &.btn-em-disable {
    background-color: $meshapp-grey-light; }
  &.btn-em-block {
    width: 100%; }
  &.btn-em-small {
    font-size: 10px; } }

.link-primary {
  color: #0d6efd;
  cursor: pointer;
  &:hover {
    text-decoration: underline; } }

.relative {
  position: relative; }
