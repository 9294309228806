.adam-pills-menu {
    margin-top: 30px;
    //background: #cecece;
}

.adam-pills-content{
    width: 100%;
}

.pills-vertical{
    display: flex;
    .nav{
        display: flex;
        flex-direction: column;
    }
}

.pills-horizontal {
    position: relative;
    display: block;
    width: 100%; 
    border-bottom: 1px solid #ddd;

    .nav {
        margin: 0;
        display: table;
        table-layout: fixed;
        width: 100%;
        height: auto;
        //background: #eee;

        .nav-item {
            font-size: 14px;
            display: table-cell;
            vertical-align: middle;
            text-align: center;

            .nav-link {
                padding: 10.5px 0;

                &.active {
                    font-weight: bold;
                    //background: #333;
                }
            }
        }
    }
    .img-fluid {
        display: block;
    }
}