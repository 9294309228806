.experience-manager-navbar {
  &.experience-manager-navbar-contentPage {
    background: $fluxio-light-blue-60;
    .tab, .btn {
      &:hover,
      &.selected {
        background: $fluxio-light-blue; } } } }
.post-demo {
  display: flex;
  width: 100%;
  margin-bottom: 10px;
  padding: 10px;
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.09), 0 1px 1px 0 rgba(0, 0, 0, 0.05), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
  cursor: pointer;
  &:hover {
    background-color: #f1f1f1; }
  img {
    width: 50px;
    height: 50px;
    object-fit: cover;
    margin-right: 10px;
    border-radius: 3px; }
  .post-info {
    display: flex;
    flex-direction: column;
    h2.post-title {
      font-size: 0.9rem;
      font-weight: 600; }
    .post-description {
      font-size: 0.7rem; } } }
.post-empty-list {
  h3 {
    font-size: 15px;
    font-weight: 800; }
  p {
    font-size: 12px; }
  a {
    font-size: 12px; } }
