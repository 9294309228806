.single-object {
  position: relative;
  display: flex;
  flex-direction: column;
  &.single-object-head {
    .single-object-content {
      max-height: 40px;
      min-height: 40px;
      background: #dadcdf;
      .single-object-text {
        font-weight: 600;
        height: 40px; } } }
  &:hover {
    background-color: rgba(64, 80, 252, 0.2);
    .show-on-hover {
      visibility: visible!important; } }
  .single-object-wrap {
    position: relative;
    height: 30px;
    display: flex;
    align-items: center;
    cursor: default;
    justify-content: space-between;
    padding: 5px 0px 5px 5px; }
  .single-object-content {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    &.isDisabled {
      filter: opacity(.5);
      pointer-events: none; }
    &.single-object-hover {
      background-color: rgba(64, 80, 252, 0.2); }
    &.single-object-selected {
      background-color: rgba(64, 80, 252, 0.5);
      .single-object-text {
        &.input-disabled {
          color: #fff; } } } }
  .single-object-input {
    position: relative;
    background: transparent;
    border: none;
    outline: none;
    &:focus {
      outline: none !important; }
    .fas {
      margin-right: 0; }
    .input-disabled {
      cursor: default;
      outline: none; }
    .input-enabled {
      background-color: #fff;
      outline: 1px solid #333; }
    .mover {
      height: 100%;
      padding-right: 5px;
      i {
        width: 10px; } }
    [class^='fa-chevron'],
    [class*=' fa-chevron'] {
      font-size: 8px;
      margin-right: 0;
      margin-left: 0;
      color: #666;
      width: 15px;
      padding-right: 5px;
      height: 100%;
      text-align: center;
      vertical-align: middle;
      display: flex;
      align-items: center;
      justify-content: center; } }
  .single-object-actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 5;
    &>* {
      margin-left: 5px; } }
  .single-object-text {
    border: 0;
    width: 100%;
    padding: 0 5px;
    height: 28px;
    text-overflow: ellipsis;
    &.isInput {
      border: 2px solid #71aef4;
      outline: none; } }
  .single-object-text,
  .far,
  .fas {
    font-size: 11px;
    letter-spacing: 0px;
    color: #333;
    margin-right: 5px; }
  .single-object-add {
    display: flex;
    align-items: center;
    padding: 5px;
    margin-left: 5px;
    .single-object-text {
      color: #367db9;
      font-size: 9px; } }
  .single-object-child {
    display: flex;
    flex-direction: column;
    padding-left: 10px;
    &.single-object-child-open {
      background-color: rgba(76, 153, 241, 0.3); } }
  .show-on-hover {
    visibility: hidden; }

  .mover {
    display: flex;
    align-items: center; }
  .btn-group {
    font-size: 10px;
    button {
      font-size: 10px;
      background-color: #a3a7af;
      border-color: #a3a7af; }
    i {
      font-size: 12px;
      color: #fff !important; }
    img {
      display: flex; } } }
