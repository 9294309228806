@import '../utils/_variables';
.fluxio-form_deprecated {
  &[disabled],
  *[disabled] {
    background: #f7f8f8;
    opacity: 0.3;
    pointer-events: none;
    cursor: not-allowed; }
  &:last-child,
  &.form-inline {
    margin-bottom: 0; }
  .form-separator {
    background: $meshapp-grey-light;
    height: 2px;
    width: 100%;
    display: block;
    margin: $meshapp-spacing-md 0; }

  // form main styles
  .form-title {
    font-size: $meshapp-font-size-lg;
    font-weight: $meshapp-font-weight-semibold;
    color: $meshapp-dark;
    margin-bottom: $meshapp-spacing-sm; }
  .form-label {
    font-size: $meshapp-font-size-sm;
    font-weight: $meshapp-font-weight-semibold;
    line-height: 1.25;
    letter-spacing: -0.1px;
    color: $meshapp-grey-dark; }
  .form-group {
    max-width: 100%;
    margin: 0;
    &.disabled {
      cursor: not-allowed;
      .box {
        position: relative;
        &::after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          background-color: rgba(255, 255, 255, 0.7);
          width: 100%;
          height: 100%; } } }
    .box {
      display: flex;
      flex-direction: column;
      .box-row {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        position: relative;
        &:last-child {
          .inner-box {
            border-bottom: 0; } }
        .inner-box {
          display: flex;
          flex-direction: column;
          flex: 1 1 auto;
          align-items: flex-start;
          justify-content: center;
          padding: 5px;
          &.no-sides,
          &:last-child {
            border-right: 0; }
          >*:not(.__react_component_tooltip):not(.unset-signal) {
            min-width: 100%;
            border: 0;
            flex-wrap: wrap; }
          // inner-box inputs
          input,
          .group-input {
            &~.group-label {
              margin-left: $meshapp-spacing-sm; } }
          select,
          .default-select-container {
            margin-left: -$meshapp-spacing-sm;
            width: calc(100% + #{$meshapp-spacing-sm} * 2); // add 2 times spacing-sm, 1 for the supposed right negative margin and another to account the left negative margin
            .default-select__control {
              margin-left: $meshapp-spacing-sm;
              margin-right: $meshapp-spacing-sm; }
            .default-select__value-container,
            .default-select__indicators {
              padding: 5px 8px;
              background: rgba(0,0,0,0.05); }
            .default-select__value-container {
              padding: 5px 8px; }
            .default-select__menu {
              margin-left: 0;
              margin-right: 0; } } } } }
    .group-label {
      font-weight: $meshapp-font-weight-semibold;
      color: $meshapp-grey-dark;
      font-size: $meshapp-font-size-xs;
      text-transform: uppercase;
      word-break: keep-all;
      &.lg {
        text-transform: none;
        font-size: $meshapp-font-size-sm;
        color: $meshapp-dark; }
      &.alt {
        color: $meshapp-grey-darker;
        margin: 0 10px; } }
    // form-group inputs
    &>input,
    &>.group-input {
      border: $meshapp-border-md solid $meshapp-grey;
      border-radius: $meshapp-border-radius-md;
      padding: $meshapp-spacing-sm;
      .default-select__menu {
        margin-left: -$meshapp-spacing-sm;
        margin-right: -$meshapp-spacing-sm; } }
    &>.boundary-range {
      padding: 40px;
      height: 170px;
      margin: 20px; }
    &>*,
    &~.group-label {
      &>* {
        &~.group-label {
          margin-left: $meshapp-spacing-sm; } } } }
  .group-label-spacer {
    width: 100%;
    background-color: #f7f8f8;
    padding: 5px;
    margin: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    white-space: nowrap; }
  .form-trigger { // used for icons(whatelse..) that are actionable
    font-size: 12px;
    font-weight: 600;
    color: #a3a7af;
    cursor: pointer;
    &.selected {
      color: $fluxio-blue; } }
  img {
    max-width: 100%; }
  a {
    font-size: 11px; }
  .invalid-input {
    input,
    .em-dropdown,
    .group-input {
      border: 2px solid red !important; }
    .custom-control-label {
      &:before {
        box-shadow: 0px 0px 0px 2px red !important; } } }
  input {
    &.invalid-input {
      box-shadow: 0px 0px 0px 2px red; } }

  // form inputs defaults
  input,
  .group-input,
  .em-dropdown {
    max-width: 100%;
    font-size: $meshapp-font-size-sm;
    font-weight: normal;
    border: 0;
    outline: 0;
    text-align: center;
    font-size: 11px;
    letter-spacing: 0.3px;
    color: #333; }
  input,
  .em-dropdown {
    background: rgba(0,0,0,0.05);
    padding: 8px 8px;
    &[type='number'] {
      -moz-appearance: textfield;
      text-align: center; }
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      text-align: center; } }
  .input-range {
    .input-range__track {
      background: $meshapp-grey-light; }
    .input-range__track--active {
      background: $meshapp-grey-light; }
    .input-range__slider {
      background: $fluxio-blue;
      border-color: $fluxio-blue; }
    .input-range__label {
      display: none; } }
  .custom-control {
    padding-left: 14px;
    min-height: 0;
    * {
      margin-bottom: 0; }
    &.custom-radio {
      display: flex;
      align-items: center;
      height: 100%;
      .custom-control-input {
        &:checked {
          &~.custom-control-label {
            &::after {
              background: none; }
            &::before {
              border: $meshapp-border-md solid #fff;
              background-color: $fluxio-blue; } } } }
      .custom-control-label {
        cursor: pointer;
        &::before {
          box-shadow: 0px 0px 0px 2px $meshapp-grey-light;
          width: 14px;
          height: 14px;
          top: 50%;
          transform: translateY(-50%);
          background: none; } } }
    &.custom-checkbox {
      min-height: 16px; // checkbox default height, usefull when ther's no text inside the label
      padding-left: 1.5rem;
      .custom-control-input {
        &:checked {
          &~.custom-control-label {
            &::after {
              background-color: $fluxio-blue;
              transform: translateY(-50%);
              top: 50%; } } }
        &:disabled,
        &.disabled {
          &~.custom-control-label {
            &::before {
              box-shadow: 0px 0px 0px 2px $meshapp-grey; }
            &::after {
              background-color: $meshapp-grey; } } } }
      .custom-control-label {
        &::before {
          box-shadow: 0px 0px 0px 2px $fluxio-blue;
          transform: translateY(-50%);
          top: 50%;
          background: none;
          border-radius: 1px; } } } }
  .input-group { // append/prepend an content(ex:icon) to an input
    .input-group-prepend {
      display: flex;
      align-items: center;
      font-size: 13px;
      padding-left: 10px;
      padding-left: 8px;
      color: #767676;
      background: #f2f2f2;
      margin: 0; }
    .input-group-append {
      display: flex;
      align-items: center;
      font-size: 13px;
      padding-left: 10px;
      padding-right: 8px;
      color: #767676;
      background: #f2f2f2;
      margin: 0; }
    &>input {
      position: relative;
      -ms-flex: 1 1 auto;
      flex: 1 1 auto;
      width: 1%;
      margin-bottom: 0;
      &:disabled {
        cursor: not-allowed; } }
    .loading-container {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 5%; } }
  // input without boxes
  &>input {
    border-bottom: 2px solid #d3d6dc;
    font-size: inherit;
    font-weight: inherit; }

  //Experience Manager Select
  .em-dropdown {
    width: 100%;
    position: relative;
    font-size: 12px;
    font-weight: 700;
    button.toggle {
      position: relative;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0;
      margin: 0;
      background-color: transparent;
      border: none;
      outline: none;
      cursor: pointer;
      text-transform: capitalize;
      font-size: 10px;
      color: #868686;
      font-weight: 600;
      .fa-chevron-down {
        color: #868686; }
      .signal-indicator {
        display: none; } }
    .menu {
      position: absolute;
      left: 0;
      top: 100%;
      display: none;
      width: 100%;
      background-color: #fff;
      box-shadow: 0px 2px 2px 1px #eee;
      border-bottom-left-radius: 3px;
      border-bottom-right-radius: 3px;
      padding: 4px;
      max-height: 300px;
      overflow-y: auto;
      &.isOpen {
        display: flex;
        flex-direction: column;
        z-index: 5; }
      .item {
        position: relative;
        text-align: left;
        border-bottom: 2px solid #fff;
        padding: 2px 0;
        cursor: pointer;
        font-size: 11px;
        letter-spacing: 0.3px;
        font-weight: normal;
        border-bottom: 1px solid #fff;
        display: flex;
        justify-content: space-between;
        align-items: center;
        &:hover {
          border-bottom: 1px solid $fluxio-blue; }
        .signal-indicator {
          left: initial;
          right: 5px;
          top: 4px; } } } }

  .boundary-range {
    border: $meshapp-border-md solid $meshapp-grey;
    background-color: #eff1f5;
    border-radius: 3px;
    position: relative;
    &.selected {
      border: $meshapp-border-md solid $fluxio-blue;
      .fas {
        color: $fluxio-blue; } }
    &>.boundary-range {
      width: 100%;
      height: 100%;
      padding: 15%; }
    .block-example {
      background-color: #a3a7af;
      width: 100%;
      height: 100%; } }
  .input-wrap {
    display: flex;
    border: $meshapp-border-md solid $meshapp-grey;
    background-color: #ffffff;
    border-radius: 3px;
    justify-content: center;
    align-items: center;
    .fas {
      padding: 4px;
      color: $meshapp-grey;
      cursor: pointer; }
    input {
      outline: none;
      width: 30px; }
    &.top-spot {
      position: absolute;
      top: -14px;
      left: 50%;
      transform: translateX(-50%);
      .fas {
        border-right: $meshapp-border-md solid $meshapp-grey; } }
    &.left-spot {
      position: absolute;
      top: 50%;
      left: -17px;
      transform: translateY(-50%);
      .fas {
        border-bottom: $meshapp-border-md solid $meshapp-grey; } }
    &.right-spot {
      position: absolute;
      top: 50%;
      right: -17px;
      transform: translateY(-50%);
      .fas {
        border-bottom: $meshapp-border-md solid $meshapp-grey; } }
    &.bottom-spot {
      position: absolute;
      bottom: -14px;
      left: 50%;
      transform: translateX(-50%);
      .fas {
        border-right: $meshapp-border-md solid $meshapp-grey; } } }

  .lean-prop-controller {
    .drag-boundary {
      width: 100%;
      height: 120px;
      position: relative;
      padding: 10px;
      .wrap-example {
        background-color: #d3d6dc;
        padding: 10px;
        height: 100%; }
      .handle {
        width: 20px;
        height: 20px;
        border: 2px solid $fluxio-blue;
        border-radius: 50%;
        position: absolute;
        top: 0;
        left: 0; } } }
  .radius {
    width: 6px;
    height: 6px;
    background-color: $fluxio-blue;
    position: absolute;
    max-width: 6px;
    min-width: 6px !important;
    border-radius: 2px;
    &.bottom-left {
      bottom: 5px;
      left: 5px; }
    &.top-right {
      top: 5px;
      right: 5px; }
    &.top-left {
      top: 5px;
      left: 5px; }
    &.bottom-right {
      bottom: 5px;
      right: 5px; } }
  .group-toggle {
    border: 0;
    background-color: #e9ebef;
    width: 100%;
    margin-right: 5px;
    font-size: 11px;
    letter-spacing: 0.3px;
    &+.group-toggle,
    &:last-child {
      margin-right: 0px; } }
  // bob controllers
  &.bob-heads {
    position: relative;
    margin-bottom: 10px;
    &.bob-heads-content {
      >.form-group {
        background-color: #f7f8f8;
        margin: 0 -5px;
        padding: 0 5px;
        &:hover {
          background-color: #f7f8f8; }
        .group-label {
          cursor: default; } } }
    >.form-group {
      padding: 0 10px;
      &:hover {
        background-color: rgba(64, 80, 252, 0.2); }
      &.active {
        background: rgba(64, 80, 252, 0.5);
        color: #eee; }
      &>.group-label,
      &.group-label-spacer {
        font-size: 10px;
        color: #000;
        width: 100%;
        background-color: transparent;
        text-transform: none;
        padding: 1px 0;
        margin: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        &.active {
          background: #a5ccf8; }
        i {
          font-size: 15px;
          color: #8E66FF;
          margin-right: 10px; }
        img {
          max-width: 13px;
          margin-right: 10px; } }
      .group-label {
        font-size: 11px;
        letter-spacing: .3px;
        font-weight: normal;
        margin-right: 5px;
        height: 32px;
        i, img {
          margin-right: 5px;
          font-size: 13px; } } }
    input,
    .group-input,
    .em-dropdown {
      font-size: 10px;
      letter-spacing: 0;
      color: #868686;
      background: rgba(0,0,0,0.05);
      padding: 3px 5px;
      button.toggle {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0;
        margin: 0;
        background-color: transparent;
        border: none;
        outline: none;
        cursor: pointer;
        text-transform: capitalize;
        font-size: 10px;
        color: #868686;
        font-weight: 600;
        .fa-chevron-down {
          color: #868686;
          margin: 0;
          font-size: 13px; } } } }
  .bob-controller {
    position: relative;
    border-bottom: 1px solid #E4E4E4;
    padding: 2px 5px;
    font-size: 10px;
    &.bob-draft {
      .form-group {
        .box {
          .box-row {
            .inner-box {
              padding: 0; } } } } }
    &.state-controller {
      background: #f7f8f8;
      border: none;
      .em-dropdown {
        button.toggle {
          justify-content: flex-end; } } }
    .em-dropdown {
      button.toggle {
        background: transparent;
        font-weight: normal;
        i {
          margin-left: 5px; } } }
    input,
    .group-input,
    .em-dropdown {
      font-size: 10px;
      letter-spacing: 0;
      color: #868686; }
    input,
    .em-dropdown {
      background: rgba(0,0,0,0.05);
      padding: 3px 5px; }
    .btn {
      &.btn-sm {
        font-size: 10px;
        font-weight: 600; } }
    .group-label {
      font-size: 10px;
      text-transform: none;
      color: #000;
      margin-bottom: 0;
      font-weight: normal;
      &.lg {
        color: #000;
        margin: 5px 0;
        font-size: 11px;
        letter-spacing: .3px;
        font-weight: 600; } }
    .box {
      background: transparent;
      border-top: none;
      border-bottom: none;
      max-width: 100%;
      .box-row {
        max-width: 100%;
        .inner-box {
          border: none;
          .range-wrapper {
            border: 1px solid #dfdfdf;
            border-radius: 3px;
            display: flex;
            justify-content: center;
            align-items: center;
            flex: 1;
            &:first-child {
              margin-right: 2px; }
            &>* {
              display: flex;
              flex: 1;
              width: 100% !important;
              justify-content: center;
              align-items: center; }
            .range-input {
              max-width: 35px; }
            .range-icon-side {
              color: #868686; }
            .range-icon-middle {
              color: #ffffff;
              background: #868686;
              padding: 2px;
              border-radius: 50%;
              max-width: 15px; }
            .range-auto {
              cursor: pointer; }
            .bob-extra-controller-wrapper {
              border-bottom: 1px solid #E4E4E4; } }
          .border-wrap {
            display: flex;
            .border-box {
              width: 20px;
              height: 20px;
              background: #d3d6dc;
              margin-right: 2px;
              border: 1px dashed #444;
              &.border-box-selected {
                background: $fluxio-blue; }
              &.border-box-top {
                border-top: 2px solid #444; }
              &.border-box-bottom {
                border-bottom: 2px solid #444; }
              &.border-box-left {
                border-left: 2px solid #444; }
              &.border-box-right {
                border-right: 2px solid #444; }
              &.border-box-all {
                border: 2px solid #444; } } } } } } }
  .bob-extra-controller {
    .box:not(:last-child) {
      border-bottom: 1px solid #E4E4E4; }
    .shadow-preview {
      height: 50px;
      background-color: #eee;
      margin: 10%; } }

  .group-separator {
    position: relative;
    padding-bottom: 10px; }
  .fluxio-form-collapse {
    padding: 10px 10px;
    background: #F3F4F8; }
  .fluxio-form-collapse {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    h3 {
      font-family: 'Eina', sans-serif;
      font-weight: 600;
      text-transform: uppercase;
      font-size: 13px;
      color: #27272D;
      &.section-separator {
        margin-bottom: 0; } }
    .fas {
      font-size: 15px;
      color: #767A81; } } }

//BorderController
.color-preview {
  width: 25px;
  min-width: 25px;
  height: 25px;
  box-shadow: 0px 0px 1px #a1a1a1;
  &+.color-preview-input {
    border: none;
    margin-left: 5px; } }
.border-box-wrapper {
  min-width: 112px;
  min-height: 112px;
  width: 112px;
  height: 112px;
  position: relative;
  padding: 5px;
  .border-box-example {
    position: relative;
    width: 100%;
    height: 100%;
    background-color: #d3d6dc; }
  .selector {
    position: absolute;
    cursor: pointer;
    &.top {
      top: 0;
      left: 50%;
      transform: translateX(-50%); }
    &.bottom {
      bottom: 0;
      left: 50%;
      transform: translateX(-50%); }
    &.left {
      left: 0;
      top: 50%;
      transform: translateY(-50%); }
    &.right {
      right: 0;
      top: 50%;
      transform: translateY(-50%); }
    &.all {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%); } } }
.alignment-square {
  display: flex;
  flex-direction: column;
  .alignment-wrap {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 2px;
    &:last-child {
      margin-bottom: 0; }
    .alignment-position {
      width: 30%;
      height: 13px;
      background: #d3d6dc;
      &.active {
        background: $fluxio-blue; } } } }
