ul.fluxio-pagination {
  display: flex;
  list-style: none;
  justify-content: center;
  margin-top: 20px;
  padding-left: 0;
  li {
    padding: 0 5px;
    box-shadow: 10px 10px 60px 0px rgba(0, 74, 184, 0.05);
    a {
      border-radius: 3px;
      cursor: pointer;
      border: none;
      color: $fluxio-blue;
      padding: 9px 15px;
      margin-left: -1px;
      background-color: transparent;
      transition: background .2s ease-out,border-color 0s ease-out,color .2s ease-out;
      outline: none;
      &:hover {
        text-decoration: none;
        transition: background .2s ease-out,border-color .2s ease-out,color .2s ease-out;
        z-index: 2;
        color: $fluxio-blue;
        background-color: #fff;
        outline: none; } }
    &.active a {
      background-color: $fluxio-blue;
      color: #ffffff;
      outline: none; }
    &.disabled {
      color: #ccd5db;
      cursor: not-allowed; } }
  &.fluxio-pagination-md {
    font-size: 15px;
    margin: 10px 0;
    li {
      padding: 5px 5px;
      a {
        padding: 7px 10px; } } }
  &.fluxio-pagination-sm {
    font-size: 14px;
    margin: 5px 0;
    li {
      padding: 0 2px;
      a {
        padding: 3px 10px; } } }
  &.fluxio-pagination-xs {
    font-size: 11px;
    margin: 5px 0;
    li {
      padding: 0 2px;
      a {
        padding: 3px 10px; } } } }
