.responsive-property,
.revert-signal,
.responsive-inline-property {
  display: inline-block;
  min-width: 0 !important;
  vertical-align: middle;
  cursor: pointer;
  padding: 5px;
  &.normal {
    .dot {
      background-color: orange;
      box-shadow: 0px 0px 3px 1px orange; } }
  &.state {
    .dot {
      background-color: $fluxio-blue;
      box-shadow: 0px 0px 3px 1px $fluxio-blue; } }
  .dot {
    width: 5px;
    min-width: 5px !important;
    height: 5px;
    border-radius: 50%; } }
.responsive-property {
  position: absolute;
  top: 10px;
  right: 0; }

.unset-signal {
  pointer-events: auto;
  font-size: 10px;
  cursor: pointer;
  height: auto;
  padding: 5px;
  width: auto;
  display: inline;
  position: absolute;
  left: -13px;
  top: 5px;
  z-index: 11;
  &.active {
    color: $fluxio-blue; } }

.signal-indicator {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: -3px;
  right: -3px;
  font-size: 7px !important;
  color: #4253FC !important;
  text-shadow: 0 .125rem .25rem rgba(0,0,0,.20);
  border-radius: 100%;
  cursor: pointer; }
