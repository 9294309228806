@font-face {
    font-family: 'Eina';
    font-weight: 800;
    font-style: normal;
    src: url('https://cdn.fluxio.cloud/meshapp/fonts/eina/woff/eina-01-bold.woff') format('woff');
}

@font-face {
    font-family: 'Eina';
    font-weight: 400;
    font-style: normal;
    src: url('https://cdn.fluxio.cloud/meshapp/fonts/eina/woff/eina-01-regular.woff') format('woff');
}

@font-face {
    font-family: 'Eina';
    font-weight: 600;
    font-style: normal;
    src: url('https://cdn.fluxio.cloud/meshapp/fonts/eina/woff/eina-01-semibold.woff') format('woff');
}
