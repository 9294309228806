.footer{
  min-height: 35vh;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  .footer-title{
    font-weight: bold;
  }

  .credits{
    font-size: 14px;
  }
  .arrow-top{

  }
  .footer-posts{
    display: flex;

    .posts{
      text-align: left;
    }
  }
}
