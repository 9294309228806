.img-placeholder {
  background: #eee;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 200px;
  i {
    font-size: 50px;
    color: #777; } }
