.powerwallBtn{
  display: none !important;
  position: fixed;
  left: 20px;
  bottom: 20px;
}

.ant-select-dropdown.ant-select-dropdown--multiple.ant-select-dropdown-placement-bottomLeft{
  z-index: 10000;
}
