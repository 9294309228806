.academy-info {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 18px;
  margin: 3px;
  background: #ffffff;
  border-radius: 50%;
  cursor: pointer;
  position: absolute;
  z-index: 11;
  left: 0;
  top: 0;
  i {
    font-size: 16px !important;
    color: $fluxio-orange !important; } }

.academy-modal {
  .modal-dialog {
    max-width: 1100px; }
  .modal-body {
    padding: 40px;
    h2 {
      font-size: 24px; }
    h3 {
      font-size: 20px; }
    h5 {
      font-size: 18px; }
    p {
      margin-bottom: 10px; }
    hr {
      margin: 30px 0; }
    .list-group {
      border: none;
      border-radius: none;
      .list-group-item {
        font-size: 16px; } } } }
