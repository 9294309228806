.highlights-column{
  margin-top: 15px;

  .posts{
    &.cramer-links{
      .card-title{
        font-size: 18px;
      }
    }
  }
  .content{
    margin-bottom: 25px;
    .title{
      font-size: 17px;
      margin-bottom: 20px;
      font-weight: bold;
    }
  }
  .warning-posts-exist{
    background-color: #ffc107;
    padding: 10px;
    border-radius: 4px;
    .msg{
      margin: 0;
      color: #752b08;
    }
    .title{
      color: #752b08;
    }
  }
}
