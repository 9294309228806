 :root {
  --fluxio-blue: #4253FC;
  --main-shadow: 0px 2px 3px rgba(144, 148, 189, 0.1); }
@import url(https://fonts.bunny.net/css?family=nunito-sans:200,300,400,600,700,800,900&display=swap);
.hidden {
  display: none !important; }
.skynet {
  font-family: 'Nunito Sans', sans-serif;
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow-y: hidden;
  .main-em {
    display: flex;
    height: calc(100% - 49px); }
  .skynet-navbar {
    min-height: 50px;
    max-height: 50px;
    .fluxio-logo {
      height: 30px;
      max-height: 30px;
      margin: 0 30px; }
    .skynet-navbar-workspace-avatar {
      height: 100%;
      width: 50px; }
    .skynet-navbar-profile-avatar {
      width: 35px;
      height: 35px;
      border-radius: 50%;
      margin-right: 10px; }
    &.bg-dark {
      background-color: #1A1C23 !important;
      .nav-item {
        min-width: 75px;
        &:not(:first-child):not(:last-child) {
          border-right: 1px solid #6F7A89; }
        .nav-item-menu {
          display: flex;
          flex-direction: column;
          justify-content: center;
          text-transform: capitalize;
          span {
            font-size: 9px;
            color: #ffffffb3;
            position: absolute;
            bottom: -8px;
            right: 10px; } } }
      .nav-item.icon-only {
        max-width: 35px;
        min-width: 24px; }
      .navbar-nav {
        align-items: center; }
      .nav-link {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: flex-end;
        font-size: 12px;
        letter-spacing: 0.1px;
        font-weight: bold;
        cursor: pointer;
        padding-top: 5px;
        padding-bottom: 5px;
        background-color: transparent;
        border: none;
        outline: none;
        color: rgba(255,255,255, 0.7); }
      .dropdown-menu {
        padding: 0;
        max-height: 80vh;
        overflow-y: auto; }
      .dropdown-divider {
        margin: 0; }
      .dropdown-item {
        cursor: pointer;
        font-size: 14px;
        letter-spacing: 0.1px;
        font-weight: 400;
        &:hover {
          background-color: #293941;
          color: #fff; }
        img, .fas {
          width: 20px;
          height: 20px;
          border-radius: 50%;
          margin-right: 15px; } }
      .dropdown-toggle.caret-off::after {
        display: none; } } }
  .create-profile-modal {
    .modal-dialog {
      max-width: 700px;
      .modal-content {
        .modal-footer {
          padding: 0;
          display: flex;
          justify-content: center;
          button {
            margin: 10px;
            border: none;
            outline: none;
            border-bottom: 1px solid gray;
            cursor: pointer;
            width: 100%; } }
        .modal-body {
          img {
            width: 150px;
            height: 150px; }
          .form-group {
            margin-bottom: 0; }
          .username-error {
            margin: 0;
            font-size: 12px;
            color: red;
            padding-left: 10px; }
          .dropzone-title {
            margin-top: 10px; }
          .drop-zone {
            cursor: pointer;
            width: 150px;
            height: 150px;
            border: 3px dashed #2a9add;
            border-radius: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            p {
              letter-spacing: 2px;
              font-weight: bold;
              color: #2a9add;
              text-align: center;
              margin: 0; } } } } } }
  .sidebar {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: #f7f8f8;
    height: 100vh;
    width: 260px;
    padding: 20px;
    box-shadow: 0 2px 4px rgba(0,0,0,.3);
    z-index: 11;
    transition: all .25s;
    // sidebar colapsed/ extend
    &.extend {
      .sidebar-trigger,
      .profiles,
      .profile-entity,
      .fas,
      .meshapp-logo,
      .profile-menu-trigger {
        transition: all .25s; } }
    &.collapse {
      width: 60px;
      .sidebar-trigger {
        margin-right: 0;
        transition: all .25s; }
      .profile-menu-trigger {
        display: none; }
      .profiles {
        flex-direction: column;
        transition: all .25s;
        .profile-entity {
          display: none !important;
          transition: all .25s;
          .fas {
            transition: all .25s; } } }

      .collapse-icon,
      .meshapp-logo {
        display: none; }
      // hide submenus
      .menu-list {
        overflow: visible;
        .menu-entry {
          .fas {
            font-size: 20px;
            color: #466576;
            transition: all .25s; }
          .entry-label {
            display: none; } }
        .menu-list {
          position: absolute;
          right: -120px;
          background: #fff;
          width: 140px;
          top: 0;
          box-shadow: 2px 2px 5px rgba(0,0,0,0.06);
          &.extend {
            .menu-entry {
              display: block; } }
          .menu-entry {
            display: none; } } } }
    .menus-header {
      display: flex;
      align-items: center;
      img {
        max-width: 100%;
        max-height: 30px; }
      .sidebar-trigger {
        margin-right: 10px; } }
    .sidebar-trigger {
      font-size: 25px;
      color: #466576;
      cursor: pointer; }
    .profiles {
      position: relative;
      border-top: 1px solid #ccd5db;
      border-bottom: 1px solid #ccd5db;
      padding-top: 20px;
      padding-bottom: 17px;
      margin-top: 17px;
      margin-bottom: 30px;
      display: flex;
      align-items: center;
      .profile-info {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
        .profile-entity {
          margin-left: 10px;
          display: flex;
          flex-direction: column;
          .profile-type {
            color: #8b8e93;
            font-size: 12px;
            font-style: italic; } }
        .profile-menu-trigger {
          color: #466576;
          margin-left: auto;
          cursor: pointer;
          font-size: 18px; }
        .no-profile {
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 38px; // must be lower than the imge width because of the background geting out of theimage
          height: 38px; // must be lower than the imge height because of the background geting out of theimage
          background-color: #fe5745;
          color: #ae2727;
          font-size: 1.2em;
          border-radius: 50%;
          img {
            width: 40px;
            height: 40px;
            border-radius: 50%; }
          &.disabled {
            cursor: not-allowed; } } }
      .drop-list {
        position: absolute;
        z-index: 11;
        background-color: #fff;
        left: 0;
        top: 100%;
        border: 1px solid #dcdfe5;
        border-radius: 3px;
        .drop-list-container {
          padding: 0 10px; }
        .scroll-list {
          overflow-y: auto;
          height: 100%;
          max-height: 50vh; }
        .drop-entry {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          padding: 10px;
          color: black;
          &:hover {
            background-color: #fff;
            text-decoration: none; }
          p.name {
            margin: 0 10px; }
          img {
            width: 50px;
            height: 50px; } }
        .drop-entry-create {
          cursor: pointer;
          height: 70px;
          padding: 10px;
          .dashed {
            border: 3px dashed #2a9add;
            border-radius: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
            p {
              margin: 0;
              font-weight: bold;
              color: #2a9add;
              letter-spacing: 2px; } }
          &:hover {
            background-color: #fff;
            text-decoration: none; } }
        .menu-list {
          &:after {
            content: "";
            display: block;
            margin: 0 auto;
            width: 90%;
            margin-bottom: 10px;
            border-bottom: 1px solid #dcdfe5;
            padding-bottom: 10px; }
          .menu-entry {
            a,
            .menu-header,
            &>span {
              padding: 12px; } } } } }
    .navigation {
      // reset contianer side padding
      .menu-list,
      .menu-entry,
      a {
        margin-left: -20px;
        padding-left: 20px;
        margin-right: -20px;
        padding-right: 20px; }
      .menu-entry {
        position: relative;
        a {
          padding-left: 45px; }
        .collapse-icon {
          float: right; } } }
    .menu-list {
      margin: 0;
      padding: 0;
      list-style-type: none;
      transition: max-height 0.5s ease-out;
      height: auto;
      max-height: 500px;
      overflow: hidden;
      &.collapse {
        max-height: 0;
        display: block; }
      .menu-list {
        .menu-entry {
          &:hover,
          a.active {
            background: #dee0e1; }
          .menu-header {
            padding: 10px 0 10px 28px; }
          a,
          &>span {
            padding-left: 45px; } } }
      .menu-entry {
        cursor: pointer;
        &:hover,
        &.active {
          background: rgba(163,175,183, 0.1); }
        a,
        .menu-header,
        &>span {
          color: inherit;
          text-decoration: none;
          display: block;
          padding: 10px 0 10px 0; }
        .fas {
          font-size: 15px;
          margin-right: 10px;
          color: #466576; }
        &.selected {
          background-color: #f5f7f9;
          border-bottom: 4px solid $fluxio-blue; } } }
    .person {
      .user-avatar {
        width: 60px;
        height: 60px;
        img {
          width: 100%;
          height: 100%; } } } }
  .user-avatar-error {
    background-color: #fa5746;
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    a {
      color: #a3262d;
      font-size: 25px; } }
  .work {
    width: 100%;
    height: 100%;
    position: relative;
    overflow-x: hidden;
    .work-container,
    .work-row {
      height: 100%;
      flex: 1; }
    .work-container {
      display: flex;
      flex-direction: column;
      .work-sidebar,
      .work-content {
        padding: 15px;
        height: 100%;
        display: flex;
        flex-direction: column;
        .list-group {
          overflow-x: hidden;
          overflow-y: auto;
          padding: 2px; } }
      .work-sidebar {
        height: 100%; }
      .work-actions {
        margin-bottom: 15px; }
      .work-filters {
        margin-bottom: 5px; } }
    .lab {
      .row {
        .cabinet {
          background-color: #fff;
          height: 100vh;
          .body-section {
            padding: 15px;
            background: #fff;
            .actions {
              margin-bottom: 10px;
              .order-search-wrapper {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 6px 0;
                flex-wrap: wrap;
                .order {
                  display: flex;
                  align-items: center;
                  justify-content: flex-start;
                  p {
                    margin: 0 5px 0 0;
                    color: #767a81;
                    text-transform: uppercase;
                    font-size: 10px;
                    font-weight: 600;
                    letter-spacing: 0.4px; }
                  #order {
                    background-color: #ffffff;
                    border-radius: 3px;
                    border: solid 2px #eff1f5;
                    color: #767a81;
                    font-size: 10px;
                    text-transform: uppercase;
                    font-weight: 600;
                    letter-spacing: 0.4px; }
                  .order-radio {
                    display: flex;
                    align-items: center;
                    .order-wrappper {
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      cursor: pointer;
                      margin: 0 0 0 10px;
                      color: #767a81;
                      text-transform: uppercase;
                      font-size: 10px;
                      font-weight: 600;
                      letter-spacing: 0.4px;
                      input {
                        position: absolute;
                        opacity: 0;
                        cursor: pointer; }
                      .checkmark {
                        width: 11px;
                        height: 11px;
                        border: 2px solid #f0f1f5;
                        border-radius: 50%;
                        box-shadow: inset 0px 0px 0px 1px #ffffff;
                        &:hover {
                          background-color: #ccc; }
                        &.checked {
                          background-color: #50b4ff; } } } } }
                .search {
                  color: #767a81;
                  border-bottom: solid 1px #eff1f5;
                  #search {
                    border: none;
                    outline: none;
                    background-color: #ffffff; } } }
              .breadcrumb-creator-wrapper {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 10px;
                .breadcrumbs {
                  font-size: 10px;
                  font-weight: 600;
                  color: #767a81;
                  text-transform: uppercase;
                  .breadcrumb-element {
                    cursor: pointer;
                    .load-all {
                      background-color: $fluxio-blue;
                      border: none;
                      color: #ffffff;
                      border-radius: 3px;
                      padding: 5px; }
                    &:after {
                      content: ' -- '; } } }
                .create-formula-btn {
                  cursor: pointer;
                  border-radius: 5px;
                  background-color: $fluxio-blue;
                  font-size: 13px;
                  font-weight: 500;
                  color: #ffffff;
                  border: none;
                  padding: 10px 20px;
                  outline: none; } } }
            .cabinet-pad {
              .folder-wrapper {
                cursor: pointer;
                position: relative;
                margin: 1px;
                .image-folder-formula {
                  svg {
                    padding: 2px;
                    vertical-align: top; }
                  .folder-image {
                    width: 100%;
                    height: auto;
                    border-radius: 10px; }
                  .folder-image-fake {
                    width: 100%;
                    border-radius: 10px; } }
                .formula-info {
                  position: absolute;
                  color: #ffffff;
                  bottom: 0px;
                  left: 0px;
                  padding: 10px;
                  text-shadow: 0px 0px 3px rgba(0, 0, 0, 0.50);
                  word-break: break-word;
                  p.name {
                    margin: 0; } } } } }
          ul.pagination {
            justify-content: center;
            margin-top: 20px;
            li {
              a {
                cursor: pointer;
                border: 1px solid #e4eaec;
                color: #76838f;
                padding: 9px 15px;
                margin-left: -1px;
                background-color: #ffffff;
                transition: background .2s ease-out,border-color 0s ease-out,color .2s ease-out;
                outline: none;
                &:hover {
                  text-decoration: none;
                  transition: background .2s ease-out,border-color .2s ease-out,color .2s ease-out;
                  z-index: 2;
                  color: #89bceb;
                  background-color: #f3f7f9;
                  border-color: #e4eaec;
                  outline: none; } }
              &.active a {
                background-color: #62a8ea;
                border-color: #62a8ea;
                color: #ffffff;
                outline: none; }
              &.disabled {
                color: #ccd5db;
                cursor: not-allowed; } } } }
        .test-tube {
          background-color: #f7f8f8;
          height: 100vh;
          .test-tube-wrapper {
            //with header*/
            //height: calc(100vh - 120px)
            height: 100vh;
            .body-section {
              height: 100%;
              overflow-y: auto;
              padding: 20px;
              -webkit-overflow-scrolling: touch; } } }
        .workbench {
          .workbench-wrapper {
            padding: 15px;
            box-shadow: 6px 0 5px -3px rgba(0, 0, 0, 0.2);
            height: 100vh;
            .backdrop {
              position: absolute;
              background: rgba(29, 34, 49, 0.5);
              height: 100vh;
              width: 100vw;
              left: 100%;
              top: 0;
              z-index: 1; }
            .feed-info {
              display: flex;
              justify-content: space-between;
              font-size: 13px;
              padding: 15px;
              font-weight: 600;
              text-transform: uppercase;
              box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.09), 0 1px 1px 0 rgba(0, 0, 0, 0.05), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
              cursor: pointer; }
            .formula-parameters {
              display: flex;
              height: 100%;
              flex-direction: column;
              .redo {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                font-size: 14px;
                color: #767a81;
                padding: 4px 0 10px 0;
                color: $fluxio-blue;
                font-weight: bold;
                .fas {
                  &.fa-angle-left {
                    margin: 0 10px 0 0; }
                  &.fa-chevron-right {
                    margin: 0 0 0 10px; } } }
              .wrap-groups {
                height: 100%;
                overflow-y: auto; }
              .close-wrap {
                cursor: pointer;
                display: flex;
                justify-content: flex-end;
                color: #767a81; }
              .formula-info {
                display: flex;
                .avatar-public-wrapper {
                  width: 30%;
                  padding: 10px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  flex-direction: column;
                  .avatar {
                    position: relative;
                    .delete-avatar {
                      cursor: pointer;
                      position: absolute;
                      top: 5px;
                      right: 5px;
                      background-color: rgba(79, 181, 255, 0.75);
                      color: #ffffff;
                      border: none;
                      font-size: 14px;
                      border-radius: 2px; }
                    .load-avatar {
                      cursor: pointer;
                      position: absolute;
                      bottom: 5px;
                      font-size: 13px;
                      font-weight: 600;
                      letter-spacing: -0.1px;
                      text-align: left;
                      background-color: rgba(79, 181, 255, 0.75);
                      border: none;
                      border-radius: 2px;
                      color: #ffffff; }
                    img {
                      width: 100%;
                      max-width: 110px; } }
                  .public-formula {
                    .order-wrappper {
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      cursor: pointer;
                      font-size: 10px;
                      font-weight: 600;
                      letter-spacing: -0.1px;
                      text-align: left;
                      color: #27272d;
                      input {
                        position: absolute;
                        opacity: 0;
                        cursor: pointer; }
                      .checkmark {
                        margin-right: 5px;
                        width: 11px;
                        height: 11px;
                        border: 2px solid #f0f1f5;
                        border-radius: 50%;
                        box-shadow: inset 0px 0px 0px 1px #ffffff;
                        &:hover {
                          background-color: #ccc; }
                        &.checked {
                          background-color: #50b4ff; } } } } }
                .formula-info-wrapper {
                  width: 100%;
                  padding: 10px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  flex-direction: column;
                  .formula-input-wrapper {
                    width: 100%;
                    input {
                      width: 100%;
                      margin: 0 0 10px;
                      border-radius: 5px;
                      border: solid 2px #eff1f5;
                      line-height: 40px;
                      font-size: 18px;
                      font-weight: 600;
                      letter-spacing: -0.2px;
                      text-align: left;
                      color: #4a4a4a;
                      padding: 0 10px;
                      background-color: #ffffff; }
                    p.input-desc {
                      margin: 0 0 5px;
                      padding: 0;
                      font-size: 10px;
                      font-weight: 600;
                      text-align: left;
                      color: #767a81;
                      text-transform: uppercase; } } } }
              .formula-expression-wrapper {
                padding: 10px;
                font-size: 13px;
                letter-spacing: -0.1px;
                h2.title {
                  margin: 0 0 5px;
                  padding: 0;
                  font-size: 10px;
                  font-weight: 600;
                  text-align: left;
                  color: #767a81;
                  text-transform: uppercase; }
                .group-expression {
                  &+.group-expression {
                    margin-top: 30px; }
                  &.not-selected {
                    cursor: pointer;
                    span.group-name {
                      color: #333; }
                    .group-box {
                      box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.09), 0 1px 1px 0 rgba(0, 0, 0, 0.05), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
                      &:hover {
                        border-color: #4eb5ff; }
                      .group-options {
                        color: #d3d6dc;
                        button {
                          color: #333; }
                        .add-cumulative {
                          border: solid 1px #eff1f5; } } } }
                  &.selected {
                    span.group-name {
                      color: $fluxio-blue; }
                    .group-box {
                      box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.09), 0 1px 1px 0 rgba(0, 0, 0, 0.05), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
                      border-left: 2px solid #4eb5ff;
                      .group-tags {
                        .source {
                          &:hover {
                            background: #f7f8f8; } } }
                      .group-options {
                        color: $fluxio-blue;
                        button {
                          color: $fluxio-blue; }
                        .add-cumulative {
                          cursor: pointer;
                          border: solid 1px rgba(79, 181, 255, 0.25); } } } }
                  span.group-name {
                    font-size: 13px;
                    font-weight: 600;
                    text-align: left; }
                  .group-box {
                    border-left: 2px solid #f7f8f8;
                    .group-selectors {
                      display: flex;
                      padding: 7px;
                      select#created {
                        background-color: $fluxio-blue;
                        border-radius: 3px;
                        border: none;
                        color: #ffffff;
                        font-size: 13px;
                        font-weight: 600;
                        letter-spacing: -0.1px;
                        text-align: left;
                        margin: 3px;
                        padding: 0 5px; }
                      .btn-date-handler {
                        cursor: pointer;
                        background-color: #767a81;
                        color: #ffffff;
                        border: none;
                        border-radius: 3px;
                        padding: 5px 10px;
                        margin: 3px; }
                      .react-datepicker-popper {
                        .react-datepicker {
                          border: none;
                          border-radius: 3px;
                          background-color: #ffffff;
                          box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.5);
                          .react-datepicker__month-container {
                            background-color: #ffffff;
                            font-size: 10px;
                            font-weight: 600;
                            letter-spacing: 0.4px;
                            text-align: left;
                            color: #767a81;
                            .react-datepicker__header {
                              background-color: #ffffff; } }
                          .react-datepicker__time-container {
                            border: none;
                            .react-datepicker__header {
                              background-color: #ffffff; }
                            .react-datepicker__time {
                              .react-datepicker__time-list-item {
                                &:hover {
                                  background-color: $fluxio-blue; } }
                              .react-datepicker__time-list-item--selected {
                                background-color: $fluxio-blue; } } } } }
                      .react-datepicker-wrapper {
                        cursor: pointer;
                        margin: 0; }
                      input {
                        color: #ffffff;
                        width: 100%;
                        background-color: #767a81;
                        border-radius: 3px; } }
                    .group-options {
                      display: flex;
                      align-items: center;
                      font-size: 13px;
                      font-weight: 600;
                      letter-spacing: -0.1px;
                      text-align: left;
                      justify-content: space-between;
                      background: #f7f8f8;
                      padding-left: 10px;
                      padding-right: 5px;
                      button {
                        background-color: transparent;
                        border: none;
                        outline: none;
                        margin: 0;
                        padding: 0;
                        cursor: pointer; }
                      .add-cumulative {
                        margin: -1px;
                        padding: 12px;
                        width: 100%;
                        .fas {
                          margin: 0;
                          padding: 0;
                          border: 0; } }
                      .fas {
                        padding: 10px; } } }
                  .group-tags {
                    padding: 5px;
                    .source {
                      font-size: 12px;
                      font-weight: 400;
                      letter-spacing: -0.1px;
                      text-align: left;
                      padding: 4px;
                      cursor: pointer;
                      display: block;
                      .far {
                        font-size: 13px; }
                      .count {
                        font-size: 11px;
                        font-weight: normal;
                        letter-spacing: -0.1px;
                        text-align: left;
                        color: #ffffff;
                        font-style: normal;
                        margin-left: 5px; } } }
                  .created-toggle {
                    background-color: $fluxio-blue;
                    border-radius: 3px;
                    border: none;
                    color: #ffffff;
                    margin: 3px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: 0 5px;
                    font-size: 13px;
                    font-weight: 600;
                    letter-spacing: -0.1px;
                    text-align: left;
                    cursor: pointer; }
                  select#created {
                    background-color: $fluxio-blue;
                    border-radius: 3px;
                    border: none;
                    color: #ffffff;
                    font-size: 13px;
                    font-weight: 600;
                    letter-spacing: -0.1px;
                    text-align: left;
                    margin: 3px;
                    padding: 0 5px; } }
                .group-expression-compressed {
                  span.group-name {
                    font-size: 10px;
                    font-weight: 600;
                    text-align: left; }
                  .group-wrap {
                    border-radius: 5px;
                    border: 2px solid #a3a7af;
                    display: flex;
                    .group-box {
                      margin: 7px;
                      width: 100%;
                      .group-selectors {
                        display: flex;
                        padding: 0 7px 7px 0;
                        .created-toggle {
                          background-color: $fluxio-blue;
                          border-radius: 3px;
                          border: none;
                          color: #ffffff;
                          margin: 3px;
                          display: flex;
                          justify-content: center;
                          align-items: center;
                          padding: 0 5px;
                          font-size: 13px;
                          font-weight: 600;
                          letter-spacing: -0.1px;
                          text-align: left;
                          cursor: pointer; }
                        select#created {
                          background-color: $fluxio-blue;
                          border-radius: 3px;
                          border: none;
                          color: #ffffff;
                          font-size: 13px;
                          font-weight: 600;
                          letter-spacing: -0.1px;
                          text-align: left;
                          margin: 3px;
                          padding: 0 5px; }
                        .btn-date-handler {
                          cursor: pointer;
                          background-color: #767a81;
                          color: #ffffff;
                          border: none;
                          border-radius: 3px;
                          padding: 5px 10px;
                          margin: 3px; }
                        .react-datepicker-popper {
                          .react-datepicker {
                            border: none;
                            border-radius: 3px;
                            background-color: #ffffff;
                            box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.5);
                            .react-datepicker__month-container {
                              background-color: #ffffff;
                              font-size: 10px;
                              font-weight: 600;
                              letter-spacing: 0.4px;
                              text-align: left;
                              color: #767a81;
                              .react-datepicker__header {
                                background-color: #ffffff; } }
                            .react-datepicker__time-container {
                              border: none;
                              .react-datepicker__header {
                                background-color: #ffffff; }
                              .react-datepicker__time {
                                .react-datepicker__time-list-item {
                                  &:hover {
                                    background-color: $fluxio-blue; } }
                                .react-datepicker__time-list-item--selected {
                                  background-color: $fluxio-blue; } } } } }
                        .react-datepicker-wrapper {
                          cursor: pointer;
                          margin: 0; }
                        input {
                          color: #ffffff;
                          width: 100%;
                          background-color: #767a81;
                          border-radius: 3px; } }
                      .group-tags {
                        padding: 0 7px 0 0;
                        display: flex;
                        flex-wrap: wrap;
                        .source {
                          background-color: $fluxio-blue;
                          font-size: 13px;
                          font-weight: 600;
                          letter-spacing: -0.1px;
                          text-align: left;
                          color: #ffffff;
                          padding: 10px;
                          margin-right: 5px;
                          border-radius: 3px;
                          margin: 3px;
                          .far {
                            font-size: 16px; }
                          .count {
                            font-size: 11px;
                            font-weight: normal;
                            letter-spacing: -0.1px;
                            text-align: left;
                            color: #ffffff;
                            font-style: normal;
                            margin-left: 5px; } } } }
                    .group-options {
                      display: flex;
                      align-items: center;
                      font-size: 13px;
                      font-weight: 600;
                      letter-spacing: -0.1px;
                      text-align: left;
                      button {
                        background-color: transparent;
                        border: none;
                        outline: none;
                        margin: 0;
                        padding: 0;
                        border: solid 1px #dee1e5;
                        height: 100%;
                        cursor: pointer; }
                      .fas {
                        color: $fluxio-blue;
                        margin: -1px;
                        padding: 15px; } } } }
                .cumulative-content {
                  margin: 5px 0 0 10px;
                  &+.cumulative-content {
                    margin-left: 20px; }
                  .cumulative-wrap {
                    border-left: 2px solid #f7f8f8;
                    &.not-selected {
                      box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.09), 0 1px 1px 0 rgba(0, 0, 0, 0.05), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
                      &:hover {
                        border-color: $fluxio-blue;
                        cursor: pointer; }
                      .cumulative-options {
                        button {
                          color: #333; } } }
                    &.selected {
                      box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.09), 0 1px 1px 0 rgba(0, 0, 0, 0.05), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
                      border-left: 2px solid #4eb5ff; }
                    .tags-wrap {
                      display: flex;
                      flex-wrap: wrap;
                      padding: 5px;
                      span.tag {
                        margin: 3px;
                        padding: 6px;
                        color: #ffffff;
                        font-size: 11px;
                        background-color: #9e6cd4;
                        border-radius: 3px;
                        display: flex;
                        align-items: center;
                        cursor: pointer;
                        font-weight: 400;
                        letter-spacing: -0.1px;
                        &.format-tag {
                          background-color: #da5c4e; }
                        &.profile-tag {
                          background-color: #2bcea9; }
                        &.content-tag {
                          background-color: #efc57f; }
                        &.category-tag {
                          background-color: #6c9ad4; }
                        .far {
                          font-size: 12px;
                          margin-right: 3px; } } }
                    .cumulative-options {
                      display: flex;
                      align-items: center;
                      font-size: 13px;
                      font-weight: 600;
                      letter-spacing: -0.1px;
                      text-align: left;
                      justify-content: space-between;
                      background: #f7f8f8;
                      padding-right: 5px;
                      .fluxio-form_deprecated {
                        width: 100%;
                        margin-bottom: 0;
                        .form-group {
                          .box {
                            background: transparent;
                            border: none; }
                          .inner-box {
                            padding: 0; }
                          button {
                            padding: 5px 10px; } } }
                      button {
                        background-color: transparent;
                        border: none;
                        outline: none;
                        margin: 0;
                        padding: 0;
                        cursor: pointer;
                        color: $fluxio-blue;
                        padding: 5px 10px; }
                      .add-cumulative {
                        border: solid 1px #d3ecff;
                        margin: -1px;
                        padding: 12px;
                        width: 100%;
                        cursor: pointer;
                        &.disabled {
                          color: #e3e3e3;
                          cursor: not-allowed;
                          .fas {
                            color: #e3e3e3; } }
                        .fas {
                          margin: 0;
                          padding: 0;
                          border: 0; } } } } } }
              .group-button {
                padding: 10px 10px;
                color: #4eb5ff;
                cursor: pointer;
                background: transparent;
                border: none;
                outline: none;
                &:disabled {
                  color: #eee;
                  cursor: not-allowed; }
                i {
                  margin-right: 5px; } }
              .group-actions {
                .button-info {
                  padding: 5px 20px !important;
                  border-right: 1px solid #e9ebeb !important; } }
              .group-add {
                display: flex;
                align-items: center;
                color: #d3d6dc;
                padding: 20px 0;
                cursor: pointer;
                h6.add {
                  margin: 0;
                  font-size: 14px;
                  font-weight: 600;
                  text-align: left;
                  text-transform: uppercase; } }
              .group-save-preview {
                .sort-limit-wrap {
                  display: flex;
                  justify-content: space-around;
                  align-items: center;
                  .sort-by-warp {
                    span {
                      font-size: 13px;
                      font-weight: 600;
                      letter-spacing: -0.1px;
                      text-align: left;
                      color: #27272d;
                      margin-right: 20px; }
                    select.sort {
                      border-radius: 5px;
                      border: solid 2px #eff1f5;
                      background-color: #ffffff;
                      padding: 5px 10px; } }
                  .limit-by-wrap {
                    span {
                      font-size: 13px;
                      font-weight: 600;
                      letter-spacing: -0.1px;
                      text-align: left;
                      color: #27272d;
                      margin-right: 20px; }
                    select.limit {
                      border-radius: 5px;
                      border: solid 2px #eff1f5;
                      background-color: #ffffff;
                      padding: 5px 10px; } } }
                .btns-wrap {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  .btn-preview {
                    cursor: pointer;
                    width: 70%;
                    background-color: $fluxio-blue;
                    color: #ffffff;
                    border: none;
                    border-radius: 4px;
                    padding: 10px;
                    margin: 2px; }
                  .btn-save {
                    cursor: pointer;
                    width: 100%;
                    background-color: $fluxio-blue;
                    color: #ffffff;
                    border: none;
                    border-radius: 4px;
                    padding: 10px;
                    margin: 2px;
                    &.grey-out {
                      background-color: #a2a2a2;
                      cursor: not-allowed; } } } } } } }
        .sources {
          height: 100%;
          display: flex;
          flex-direction: column;
          .body-section {
            display: flex;
            flex-direction: column;
            height: 100%;
            .search-box {
              padding: 20px 20px 10px 20px;
              display: flex;
              justify-content: flex-end;
              align-items: center;
              color: #767a81;
              input {
                padding: 10px;
                text-align: right;
                border: none;
                outline: none;
                width: 100%;
                background-color: #ffffff; } }
            .sources-selection-box {
              height: 100%;
              overflow-y: auto;
              overflow-x: hidden;
              .source-list {
                padding: 30px;
                .source {
                  display: block;
                  font-size: 11px;
                  font-weight: normal;
                  font-style: normal;
                  font-stretch: normal;
                  line-height: normal;
                  letter-spacing: -0.1px;
                  color: #27272d;
                  cursor: pointer;
                  margin: 10px 0;
                  img {
                    width: 16px;
                    padding: 0 0 0 4px; }
                  .fab, .fas {
                    font-size: 14px;
                    padding: 0 2px 0 4px; }
                  .default-network {
                    text-transform: uppercase; }
                  &.checked {
                    color: #d3d6dc;
                    cursor: not-allowed; }
                  .far {
                    font-size: 16px;
                    margin-right: 5px;
                    &.fa-check-circle,
                    &.fa-circle {
                      margin: 0 2px 0 5px; } }
                  strong {
                    font-size: 13px;
                    font-weight: 600;
                    font-style: normal;
                    font-stretch: normal;
                    line-height: normal; } } }
              &.disabled {
                background-color: #e3e3e3;
                .source-list {
                  .source {
                    color: #8b8b98;
                    cursor: not-allowed; } } }
              .empty-sources,
              .error-sources {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                padding: 50px 25px;
                .message {
                  font-size: 13px;
                  font-weight: 500;
                  letter-spacing: -0.1px;
                  text-align: center;
                  color: #a3a7af;
                  margin: 0; }
                .add-sources {
                  margin: 20px 0;
                  border-radius: 5px;
                  border: solid 2px #78c4fc;
                  color: #78c4fc;
                  padding: 8px 25px;
                  font-size: 13px;
                  font-weight: 500;
                  letter-spacing: -0.1px;
                  text-align: center;
                  background-color: #ffffff; } } } } }
        .all-tags {
          .body-section {
            overflow-y: hidden;
            .search-box {
              padding: 20px 20px 10px 20px;
              display: flex;
              justify-content: flex-end;
              align-items: center;
              color: #767a81;
              input {
                padding: 10px;
                text-align: right;
                border: none;
                outline: none;
                width: 100%;
                &.disabled {
                  background-color: #ffffff;
                  cursor: not-allowed; } } } }
          .Collapsible {
            margin-top: 2px; }
          .Collapsible__contentInner {
            height: calc(100vh - 425px);
            overflow-y: auto; }
          .section-collapse {
            background-color: #767a81;
            display: flex;
            align-items: center;
            padding: 15px 25px;
            cursor: pointer;
            p {
              margin: 0; }
            .tag-class {
              font-size: 13px;
              font-weight: 600;
              letter-spacing: -0.1px;
              text-align: left;
              color: #ffffff; }
            .tag-count {
              font-size: 13px;
              font-weight: 500;
              letter-spacing: -0.1px;
              text-align: left;
              color: #a7abb1; }
            .ball {
              width: 6px;
              height: 6px;
              background-color: #a3a7af;
              border-radius: 25px;
              margin: 10px; } } }
        .meta-list-wrap {
          padding: 20px;
          display: flex;
          flex-wrap: wrap;
          .error-tags {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            padding: 50px 25px;
            .message {
              font-size: 13px;
              font-weight: 500;
              letter-spacing: -0.1px;
              text-align: center;
              color: #a3a7af;
              margin: 0; } }
          .all-tags-btn {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            font-size: 13px;
            font-weight: 600;
            font-style: normal;
            letter-spacing: -0.1px;
            text-align: left;
            color: #27272d;
            width: 100%;
            margin: 10px 5px;
            cursor: pointer;
            &.pink {
              .far {
                color: #9e6cd4;
                font-size: 18px;
                margin-right: 5px; } }
            &.red {
              .far {
                color: #da5c4e;
                font-size: 18px;
                margin-right: 5px; } }
            &.green {
              .far {
                color: #2bcea9;
                font-size: 18px;
                margin-right: 5px; } }
            &.yellow {
              .far {
                color: #efc57f;
                font-size: 18px;
                margin-right: 5px; } } }
          .format-tag {
            cursor: pointer;
            font-size: 11px;
            border-radius: 3px;
            color: #ffffff;
            margin: 3px;
            background-color: #da5c4e;
            padding: 10px;
            strong {
              font-size: 13px;
              font-weight: 600; } }
          .generated-tag {
            cursor: pointer;
            font-size: 11px;
            border-radius: 3px;
            color: #ffffff;
            margin: 3px;
            background-color: #efc57f;
            padding: 10px;
            strong {
              font-size: 13px;
              font-weight: 600; } }
          .profile-tag {
            cursor: pointer;
            font-size: 11px;
            border-radius: 3px;
            color: #ffffff;
            margin: 3px;
            background-color: #2bcea9;
            padding: 10px;
            strong {
              font-size: 13px;
              font-weight: 600; } }
          .native-tag {
            cursor: pointer;
            font-size: 11px;
            border-radius: 3px;
            color: #ffffff;
            margin: 3px;
            background-color: #9e6cd4;
            padding: 10px;
            strong {
              font-size: 13px;
              font-weight: 600; } }
          .category-tag {
            cursor: pointer;
            font-size: 11px;
            border-radius: 3px;
            color: #ffffff;
            margin: 3px;
            background-color: #6c9ad4;
            padding: 10px;
            strong {
              font-size: 13px;
              font-weight: 600; } } } }
      .header-section {
        height: 120px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        background-color: #f4f6f8;
        padding: 13px 60px;
        h1.title {
          font-size: 20px;
          font-weight: bold;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: -0.2px;
          text-align: left;
          color: #1e2230;
          margin: 0; }
        p.description {
          font-size: 11px;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: -0.1px;
          text-align: left;
          color: #767a81;
          margin: 0; } }
      .text-info {
        margin: 120px 120px 0 60px;
        &.error {
          p.description {
            margin-bottom: 10px; } }
        h2.title {
          font-size: 30px;
          font-weight: 600;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.13;
          letter-spacing: -0.2px;
          text-align: left;
          color: #767a81;
          margin-bottom: 20px; }
        p.description {
          font-size: 14px;
          font-weight: 600;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.14;
          letter-spacing: -0.1px;
          text-align: left;
          color: #a3a7af;
          margin-bottom: 50px; }
        .formula-box {
          display: flex;
          justify-content: space-between;
          margin-bottom: 20px;
          .create-formula-box {
            width: 110px;
            height: 110px;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            border-radius: 6px;
            background-color: $fluxio-blue;
            padding: 10px;
            cursor: pointer;
            p, i {
              color: #fff;
              font-size: 15px;
              font-weight: 600;
              font-style: normal;
              font-stretch: normal;
              line-height: 1.2;
              letter-spacing: -0.1px;
              text-align: left;
              margin: 0; } } } } } }
  .panel {
    background-color: #F9F9FC;
    height: 100%;
    padding: 28px 16px;
    .panel-header {
      color: #C4C9D8;
      border-bottom: 1px solid #C4C9D8;
      width: 100%;
      line-height: 0em;
      margin: 10px 0 20px;
      font-size: 10px;
      font-weight: bold;
      text-transform: uppercase;
      span {
        background: #F9F9FC;
        padding: 0 10px 0 0; } }
    .tree-item {
      font-size: 12px;
      color: #202645;
      margin-bottom: 4px;
      padding: 4px;
      cursor: pointer;
      &.selected {
        color: #4253FC;
        //Change svg color
        svg {
          path {
            fill: #4253FC; } } }
      &:hover {
        background-color: #F1F2FB; } } }
  .main-panel {
    padding: 32px 12px; } }
.modal-delete-formula {
  .modal-dialog {
    .modal-content {
      padding: 25px;
      .modal-header {
        border: none;
        padding: 0; }
      .modal-footer {
        padding: 0;
        border: none;
        display: flex;
        justify-content: center;
        align-items: center;
        button.btn {
          width: 100%;
          font-size: 18px;
          font-weight: 500;
          letter-spacing: -0.1px;
          &.btn-cancel {
            color: #27272d; }
          &.btn-confirm {
            color: #ffffff;
            background-color: $fluxio-blue; } } }
      .modal-body {
        padding: 0;
        h2 {
          font-size: 20px;
          font-weight: 600;
          text-align: left;
          color: #1e2230; }
        p {
          font-size: 18px;
          text-align: left;
          color: #1e2230; } } } } }

.modal-organize-formula {
  .modal-dialog {
    .modal-content {
      padding: 25px;
      .modal-header {
        border: none;
        padding: 0; }
      .modal-footer {
        border: none;
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        button.btn {
          width: 100%;
          font-size: 18px;
          font-weight: 500;
          letter-spacing: -0.1px; } }
      .modal-body {
        padding: 0;
        h2 {
          font-size: 18px;
          font-weight: 600;
          text-align: left;
          color: #777777;
          text-transform: uppercase; }
        p {
          font-size: 18px;
          text-align: left;
          color: #777777; } } } } }

.iframe-grafly {
  width: 100%;
  height: 100vh;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  position: absolute; }
.sources-tags-list {
  height: 100vh;
  background-color: #fff;
  ul.pagination,
  ul.pagination-small {
    justify-content: center;
    margin-top: 20px;
    li {
      a {
        cursor: pointer;
        border: 1px solid #e4eaec;
        color: #76838f;
        padding: 9px 15px;
        margin-left: -1px;
        background-color: #ffffff;
        transition: background .2s ease-out,border-color 0s ease-out,color .2s ease-out;
        outline: none;
        &:hover {
          text-decoration: none;
          transition: background .2s ease-out,border-color .2s ease-out,color .2s ease-out;
          z-index: 2;
          color: #89bceb;
          background-color: #f3f7f9;
          border-color: #e4eaec;
          outline: none; } }
      &.active a {
        background-color: #62a8ea;
        border-color: #62a8ea;
        color: #ffffff;
        outline: none; }
      &.disabled {
        color: #ccd5db;
        cursor: not-allowed; } } } }
.info-box {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 50px 25px;
  .message {
    font-size: 13px;
    font-weight: 500;
    letter-spacing: -0.1px;
    text-align: center;
    color: #a3a7af;
    margin: 0; }
  .info-box-btn {
    margin: 20px 0;
    border-radius: 5px;
    border: solid 2px #78c4fc;
    color: #78c4fc;
    padding: 8px 25px;
    font-size: 13px;
    font-weight: 500;
    letter-spacing: -0.1px;
    text-align: center;
    background-color: #ffffff; } }
.file-title {
  font-size: 18px;
  font-weight: 600;
  color: #363D5E; }
