.list-group {
  border: $meshapp-border-md solid $meshapp-grey;
  border-radius: $meshapp-border-radius-md;
  position: relative;
  &.list-group-flush {
    border: 0;
    .list-group-item {
      border-left: $meshapp-border-xs solid $meshapp-grey;
      border-right: $meshapp-border-xs solid $meshapp-grey;
      border: none; } }
  &.list-group-well {
    background: #F6F8FB;
    padding: 20px;
    overflow-y: auto;
    display: flex; }
  .list-group-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-width: $meshapp-border-xs;
    border-color: $meshapp-grey;
    border-radius: 0;
    font-size: $meshapp-font-size-sm;
    font-weight: $meshapp-font-weight-semibold;
    letter-spacing: -0.1px;
    color: $meshapp-dark;
    padding: 13px 10px;
    outline: none;
    &.list-group-item-grid {
      display: grid;
      grid-template-columns: 10px 50px 1fr 30px;
      align-items: center;
      grid-gap: 5px; }
    &.list-group-item-action {
      cursor: pointer;
      box-shadow: 0px 1px 5px #cbcbcb66;
      box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.09), 0 1px 1px 0 rgba(0, 0, 0, 0.05), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
      margin-bottom: 7px;
      &.disabled {
        color: #a6abaf;
        pointer-events: none; }
      &:hover {
        background: #fcfcfc; }
      &:active {
        background: #dfebf7; }
      &.active {
        background: #dfebf7; } }
    &.list-group-item-drop {
      box-shadow: 0px 1px 5px #cbcbcb66;
      box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.09), 0 1px 1px 0 rgba(0, 0, 0, 0.05), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
      margin-bottom: 7px; }
    img {
      max-height: 30px; }
    .list-group-item-cover {
      width: 100%;
      height: 230px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #f6f8fb;
      min-height: 150px;
      margin-bottom: 10px;
      img {
        width: 100%;
        max-height: 100%;
        object-fit: cover;
        object-position: 0 0; } }
    .list-group-item-title {
      font-weight: 600;
      font-size: 14px;
      margin-bottom: 5px; }
    .list-group-item-description {
      font-weight: normal;
      word-break: break-word; }
    // For more complexed list items
    .list-group-container {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      color: initial;
      &:hover {
        text-decoration: none; }
      .list-group-image {
        max-height: 40px;
        width: 40px;
        height: 40px;
        min-width: 40px;
        min-height: 40px;
        object-fit: cover;
        border-radius: 50%;
        margin-right: 10px;
        &.list-group-placeholder {
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #fff;
          text-shadow: 0 0 3px #3e3e3e;
          .fas {
            position: absolute;
            bottom: 0;
            right: 0; } } }
      .list-group-inner {
        display: flex;
        align-items: center;
        .fa-times-circle {
          position: absolute;
          left: 50px;
          bottom: 10px;
          color: red;
          font-size: 14px; } }
      .actions-trigger {
        margin-left: auto; } } }
  &.list-group-card {
    a {
      text-decoration: none; }
    .list-group-item {
      flex-direction: column;
      padding: 0;
      background: transparent;
      box-shadow: none;
      align-items: flex-start;
      margin-bottom: 40px;
      &:hover {
        background: initial;
        filter: brightness(80%); }
      .avatar {
        width: 100%;
        height: 130px;
        max-height: none;
        object-fit: cover;
        border-radius: 5px; }
      .list-group-box {
        padding: 5px;
        word-break: break-word; }
      .label {
        font-size: 18px;
        font-weight: 600;
        text-transform: capitalize;
        margin-bottom: 5px; }
      .name {
        font-size: 12px;
        color: #A7ADBF;
        font-weight: 600; } } }
  &.list-group-stripe {
    .list-group-item {
      margin-bottom: 20px;
      padding: 10px;
      border-radius: 5px;
      box-shadow: 10px 10px 60px 0px rgba(0, 74, 184, 0.05);
      p {
        font-size: 14px;
        font-weight: 600; }
      img {
        border-radius: 50%; } } }
  &.list-group-bob {
    .list-group-item {
      margin-bottom: 5px;
      padding: 5px 10px;
      border-radius: 2px;
      box-shadow: none;
      background-color: #fff;
      p {
        font-size: 10px;
        font-weight: normal; } } }
  .list-group-loading {
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(255, 255, 255, 0.6);
    z-index: 5;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center; }
  .loading {
    position: absolute;
    background: rgba(0,0,0,0.5);
    width: 100%;
    height: 100%;
    i {
      position: absolute;
      transform: translate(-50%, -50%);
      left: 50%;
      top: 50%; } }

  // inner element styles
  .btn.btn-fluxio {
    &.btn-sm {
      font-size: 11px; } } }

.fluxio-list-group {
  display: flex;
  flex-direction: column;
  row-gap: 5px;
  .fluxio-list-group-item {
    font-size: 12px;
    color: #27272d;
    display: block;
    padding: 10px;
    background: #fff;
    border: 1px solid #D3D4E1;
    border-radius: 3px;
    box-shadow: var(--main-shadow);
    &:hover {
      text-decoration: none;
      border: 1px solid #aaaab3; }
    &.active {
      border: 1px solid var(--fluxio-blue); }
    .fluxio-list-group-container {
      display: flex;
      align-items: center;
      &.fluxio-list-group-spaced {
        justify-content: space-between; }
      .btn {
        font-size: 11px; }
      .fluxio-list-group-item-title {
        font-weight: 600;
        font-size: 14px;
        margin-bottom: 5px; }
      .fluxio-list-group-item-description {
        font-weight: normal;
        word-break: break-word; }
      .fluxio-list-group-inner {
        display: flex;
        align-items: center; }
      .fluxio-list-group-image {
        max-height: 40px;
        width: 40px;
        height: 40px;
        min-width: 40px;
        min-height: 40px;
        object-fit: cover;
        border-radius: 50%;
        margin-right: 10px; } } } }
