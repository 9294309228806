.adam-tabs{
  .full-sticky{
    width: 100% !important;
    left: 0;
  }

    .tab-content {
        text-align: left;
        margin-top: 0;
    }
    .nav-pills {

		.nav-link {
			font-size: 14px;
			color: white;
			line-height: 14px;
			letter-spacing: 0.6px;
			text-transform: uppercase;
			display: block;
			padding: 18px 5px;

		}
	}
    .nav-pills .nav-link{
        border-radius: 0;
        &:hover{
          text-decoration: none;
        }
    }
}

@media screen and (max-width: 768px) {
  .adam-tabs{
    margin-top: 0px;
  }
}