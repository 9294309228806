.fluxio-form {
  &.fluxio-form-compact {
    .form-group {
      margin-bottom: 5px; } }
  .form-group {
    position: relative; }
  .form-control,
  .default-select__control,
  .em-dropdown .toggle,
  .input-group-prepend,
  .input-group-text {
    box-sizing: border-box;
    padding: 8.5px 9px;
    height: initial; // overrides bootstrapp
    background: #FFFFFF;
    border: 1px solid #E8ECF1;
    border-radius: 3px;
    font-size: 12px;
    line-height: 15px;
    color: #262626;
    outline: none;
    &::placeholder {
      color: #C4C7D8; }
    &:hover {
      border-color: #B3B7FC; }
    &:focus {
      border-color: #4253FC;
      box-shadow: 0px 0px 2px #828CF3; }
    &:disabled {
      opacity: 0.6;
      border-color: #E8ECF1;
      color: #BFBFBF; } }
  .form-control {
    min-width: 0; }
  .input-group {
    .input-group-text {
      background: #fff;
      border-color: #E8ECF1; }
    .input-group-prepend {
      border-bottom-left-radius: 0;
      border-top-left-radius: 0; } }
  .custom-control-label {
    vertical-align: middle; }
  .form-label-link {
    color: #27272D;
    font-size: 11px;
    strong,
    small {
      color: #767A81; } }
  label,
  .custom-control-label ,
  .group-label {
    font-size: 14px;
    text-transform: capitalize; }
  // custom inputs
  .default-select__control,
  .em-dropdown .toggle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    background-clip: padding-box;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    text-align: left; }
  .em-dropdown {
    position: relative;
    .toggle {
      &:disabled {
        background: #eee; }
      .signal-indicator {
        display: none; } }
    input {
      box-sizing: border-box;
      padding: 6px 9px;
      height: initial;
      background: #FFFFFF;
      border: 1px solid #E8ECF1;
      border-radius: 3px;
      font-size: 12px;
      line-height: 15px;
      color: #262626;
      outline: none;
      width: 100%; }
    .menu {
      position: absolute;
      left: 0;
      top: 100%;
      display: none;
      width: 100%;
      background-color: #fff;
      box-shadow: 0px 2px 2px 1px #eee;
      border-bottom-left-radius: 3px;
      border-bottom-right-radius: 3px;
      padding: 4px;
      max-height: 300px;
      overflow-y: auto;
      &.isOpen {
        display: block;
        z-index: 5; }
      .item {
        position: relative;
        display: flex;
        text-align: left;
        justify-content: space-between;
        align-items: center;
        border-bottom: 2px solid #fff;
        padding: 2px 0;
        cursor: pointer;
        font-size: 11px;
        letter-spacing: 0.3px;
        font-weight: normal;
        border-bottom: 1px solid #fff;
        align-items: center;
        &:hover {
          border-bottom: 1px solid $fluxio-blue; }
        .signal-indicator {
          left: initial;
          right: 5px;
          top: 4px; } } } }
  &.fluxio-form-em-bar {
    width: 220px;
    height: 30px;
    .input-group {
      height: 100%; }
    .form-control {
      background-color: #4253FC;
      color: white;
      height: 100%;
      border: none;
      outline: none;
      text-align: center;
      &:focus {
        outline: none; } }
    .input-group-prepend {
      background-color: #4253FC;
      border: none;
      height: 100%;
      .input-group-text {
        background-color: #4253FC;
        color: white;
        border: none;
        padding: 0; } } }
  .custom-control {
    &.custom-radio {
      .custom-control-input {
        &~.custom-control-label {
          &:after {
            background-image: none !important;
            top: 0; }
          &::before {
            display: block;
            height: 16px;
            width: 16px;
            top: 50%;
            margin-top: -8px;
            color: #fff;
            background-color: #fff;
            border: 3px solid #fff;
            box-shadow: 0 0 0 1px #E1E2EB;
            &:hover {
              box-shadow: 0 0 0 1px #4253FC; } } }
        &:not(:checked) {
          &~.custom-control-label {
            &:hover {
              &::before {
                box-shadow: 0 0 0 1px #4253FC; } } } }
        &:checked {
          &~.custom-control-label {
            &::before {
              color: #fff;
              background-color: #4253FC;
              box-shadow: 0 0 0 1px #4253FC;
              border-color: #fff; } } } } }
    &.custom-checkbox {
      min-height: 10px; // checkbox default height, usefull when ther's no text inside the label
      padding-left: 20px;
      .custom-control-input {
        &:checked {
          &~.custom-control-label {
            &::after {
              background-color: $fluxio-blue;
              box-shadow: 0px 0px 0px 2px $fluxio-blue;
              background-size: 8px 8px; } } }
        &:disabled,
        &.disabled {
          &~.custom-control-label {
            cursor: default;
            &::before {
              box-shadow: 0px 0px 0px 2px $meshapp-grey; }
            &::after {
              background-color: #e1e2eb;
              box-shadow: 0px 0px 0px 2px #e1e2eb; } } } }
      .custom-form-label {
        font-size: 11px;
        font-weight: 600; }
      .custom-control-label {
        margin-left: 0;
        &::before,
        &::after {
          width: 10px;
          height: 10px;
          left: -20px;
          transform: translateY(-50%);
          top: 50%;
          border-radius: 1px; }
        &::before {
          border: none;
          box-shadow: 0px 0px 0px 2px #e1e2eb;
          background: none; } } } }
  .color-picker {
    width: 35px;
    height: 35px;
    border: none; }
  // Validation
  .form-control.is-invalid,
  &.was-validated .form-control:invalid,
  .form-control.is-valid,
  &.was-validated .form-control:valid {
    background-image: none; }
  .form-control.is-invalid,
  &.was-validated .form-control:invalid {
    border-color: #dc3545 !important; } // must be forced so we cand have form default validation in combination with custom validation
  .form-control.is-invalid:focus,
  &.was-validated .form-control:invalid:focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25) !important; } // must be forced so we cand have form default validation in combination with custom validation
  span.invalid {
    position: absolute;
    bottom: -15px;
    color: red;
    font-size: 11px; }

  //EM Forms
  $secondary-color: #B9BABC;
  .fluxio-form-secondary {
    color: $secondary-color;
    font-size: 12px; }
  label.form-section-label {
    background-color: #F6F7F7;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 10px;
    margin-bottom: 0;
    font-size: 11px;
    font-weight: 600;
    .expand {
      font-size: 11px; }
    .fas {
      margin-right: 5px;
      line-height: inherit; } }
  label.form-label {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 0;
    margin-bottom: 0;
    font-size: 11px;
    font-weight: 600;
    .expand {
      font-size: 11px; }
    .fas {
      margin-right: 5px;
      line-height: inherit; } }
  &.fluxio-form-separator,
  .fluxio-form-separator {
    border-bottom: 1px solid #E8E8E8;
    margin-bottom: 5px; }
  .fluxio-form-container {
    padding: 0 10px;
    margin-bottom: 7px;
    .ql-editor {
      padding: 0 !important; } }
  .fluxio-form-container.f-row {
      display: flex;
      column-gap: 5px;
      align-items: center; }
  .ql-toolbar {
    flex-direction: column;
    margin: 0 5px;
    .form-group {
      margin: 0 !important;
      padding-left: 0 !important;
      padding-right: 0 !important;
      width: initial !important; } }
  .fluxio-form-info {
    font-size: 12px;
    color: #4059F3;
    cursor: default; }
  label.group-label {
    font-size: 9px;
    color: #56565F; }
  label.group-main-label {
    font-size: 10px;
    margin: 0 0 .5rem 0; }
  .fluxio-form-dropdown {
    button.btn-primary {
      &.dropdown-toggle {
        border-radius: 0;
        color: #868686;
        border: none;
        outline: none;
        box-shadow: none;
        background: rgba(0, 0, 0, 0.05);
        padding: 8px 8px;
        font-size: 10px;
        font-weight: 600;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        &.active {
          background: rgba(0, 0, 0, 0.05);
          padding: 8px 8px;
          color: #868686;
          border: none;
          outline: none;
          box-shadow: none;
          font-size: 12px; }
        &:after {
          display: none; } } }
    .dropdown-menu {
      min-width: initial;
      font-size: 10px;
      box-shadow: 0px 2px 2px 1px #eee;
      border: none; }
    &.no-toggle-arrow {
      .dropdown-toggle::after {
        display: none !important; } } }
  label.form-sub-label {
    font-size: 9px;
    color: #767A81; }
  .fluxio-form-dropdown-compact {
    //Rewrite the style for the dropdown
    button.btn-primary {
      &.dropdown-toggle {
        background-color: transparent;
        color: $secondary-color;
        border: none;
        outline: none;
        box-shadow: none;
        padding: 0;
        font-size: 9px;
        font-weight: 600;
        border-radius: 0;
        color: #868686;
        &.active {
          background-color: transparent;
          color: $secondary-color;
          border: none;
          outline: none;
          box-shadow: none;
          padding: 0;
          font-size: 12px; } } }
    .dropdown-menu {
      min-width: initial;
      font-size: 9px;
      box-shadow: 0px 2px 2px 1px #eee;
      border: none; }
    &.no-toggle-arrow {
      .dropdown-toggle::after {
        display: none !important; } } }
  .fluxio-form-upload-media {
    display: grid;
    grid-template-columns: 20% 15% 65%;
    align-items: center;
    span {
      text-align: center;
      font-size: 10px; } }
  .fluxio-toggle {
    background-color: #F6F7F7;
    color: #A5A5A5;
    font-size: 9px;
    display: flex;
    justify-content: space-evenly;
    & button {
      border: none;
      outline: none;
      &:hover,
      &:focus,
      &:active {
        border: none;
        outline: none; } }
    .toggle-option {
      width: 100%;
      padding: 6px;
      border-radius: 3px;
      text-align: center;
      cursor: pointer;
      &.selected {
        background-color: #4253FC;
        color: #fff;
        svg {
          fill: #fff; } }
      &:hover {
        background-color: rgba(64, 80, 252, 0.6);
        color: #fff;
        svg {
          fill: #fff; } }
      svg {
        height: 13px;
        fill: #a5a5a5; } } }
  .fluxio-form-uploaded-media {
    display: grid;
    grid-template-columns: 30px auto 20px 20px;
    background-color: #F9F9F9;
    align-items: center;
    column-gap: 5px;
    height: 30px;
    .preview-image {
      border-radius: 3px;
      width: 100%;
      height: 100%;
      max-height: 30px;
      object-fit: cover; }
    span {
      font-size: 9px;
      color: #56565F;
      word-wrap: break-word; }
    .fas {
      color: #b8bac5;
      font-size: 13px;
      cursor: pointer; } }
  .column-picker {
    display: flex;
    gap: 5px;
    flex-wrap: wrap;
    justify-content: space-evenly;
    cursor: X;
    .w-60px {
      max-width: 60px; }
    .column-bar {
      width: 100%;
      height: 35px;
      background-color: #dbe0e7;
      .selected {
        background-color: $fluxio-blue; } }
    .column {
      display: flex;
      width: 60px;
      gap: 3px;
      &:hover {
        .column-bar {
          background-color: $fluxio-blue; } } }
    p {
      text-align: center; }
    .column-size {
      &.selected {
        .column-bar {
          background-color: $fluxio-blue; } }
      // fix when column-picker is inside form-fluxio_deprecated
 }      // fixes radio icon clicking position
    .custom-control {
      &.custom-radio {
        padding: 0;
        .custom-control-input {
          &~.custom-control-label {
            &:after {
              top: -1rem;
              left: -10px; }
            &:before {
              left: -10px; } } } } } } }
//TODO add to main class fluxio-form
.input-wrap-loading {
  position: relative;
  input {
    width: 100%; }
  .input-loading {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    border-radius: 3px; }
  .input-invalid {
    border: 1px solid red; } }

//bob-controllers
.bob-controllers {
  margin-left: -5px;
  margin-right: -5px; }

//Flex controller
.flex-icon {
  &.align-items-end,
  &.justify-content-end,
  &.align-content-end {
    transform: rotate(180deg);
    &.rotated90 {
      transform: rotate(270deg); }
    &.rotated180 {
      transform: rotate(0deg); }
    &.rotated-90 {
      transform: rotate(-270deg); }
    &.rotated270 {
      transform: rotate(90deg); }
    &.rotated0 {
 } }      //nothing needs to happen
  &.rotated90 {
    transform: rotate(90deg); }
  &.rotated180 {
    transform: rotate(180deg); }
  &.rotated-90 {
    transform: rotate(-90deg); }
  &.rotated270 {
    transform: rotate(270deg); }
  &.rotated0 {
 } }    //nothing needs to happen
