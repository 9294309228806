@mixin fade-animation-off {
  visibility: hidden;
  transition: visibility 0s, opacity 0.3s linear;
  opacity: 0; }
@mixin fade-animation-on {
  visibility: visible;
  opacity: 1; }

.content-library {
  .card {
    border: none;
    width: 100%;
    box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.09), 0 1px 1px 0 rgba(0, 0, 0, 0.05), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
    &.card-compact {
      flex-direction: row;
      min-width: 100%;
      .card-media-box {
        width: 50px;
        height: 50px; }
      .card-img-top {
        height: 100%;
        min-height: 100%; }
      .actions {
        i {
          font-size: 14px;
          color: #000; } } }
    .card-media-box {
      position: relative;
      padding: 7px;
      .cover {
        position: absolute;
        width: 50%;
        height: 50%;
        bottom: 0;
        right: 0;
        z-index: 1;
        img {
          width: 100%;
          height: 100%;
          transition: opacity 1s ease-in-out;
          &:hover {
            opacity: 0.1; } } } }
    .card-media {
      height: 125px;
      width: 100%;
      object-fit: cover;
      border-radius: 5px; }
    .card-body {
      position: relative;
      padding: 10px;
      .actions {
        justify-content: flex-end; } }
    .title {
      font-weight: 800;
      font-size: 20px; }
    .sub-title {
      font-weight: 600;
      font-size: 16px; }
    .description {
      font-size: 14px;
      margin-bottom: 5px;
      img {
        width: 100%; } }
    .link {
      color: #007bff;
      font-size: 14px;
      margin-bottom: 5px; }
    .card-tags {
      .tag {
        background: #999999;
        color: #fff;
        font-size: 12px;
        font-weight: bold;
        text-transform: uppercase;
        padding: 3px;
        border-radius: 3px;
        margin-right: 5px; } }
    &:hover {
      .actions {
        .hidden-actions {
          @include fade-animation-on;
          >*:hover {
            background-color: rgba(0, 0, 0, 0.5);
            cursor: pointer;
            color: #fff; } } } }
    .actions {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      padding: 10px;
      .not-selected {
        visibility: hidden; }
      .selected {
        visibility: visible;
        color: $fluxio-blue; }
      .hidden-actions {
        display: flex;
        @include fade-animation-off;
        > * {
          line-height: 1;
          font-size: 12px;
          color: #fff;
          padding: 5px;
          border-radius: 3px; } } } }
  .msg {
    font-size: 14px;
    color: #333; }
  .content-library-grid {
    display: grid;
    gap: 10px;
    &.single { //TODO temp until removed from EM
      grid-template-columns: 1fr; }
    @media all and (min-width: 2400px) {
      &.multiple {
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr; } }
    @media all and (max-width: 2399px) {
      &.multiple {
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr; } }
    @media all and (max-width: 1999px) {
      &.multiple {
        grid-template-columns: 1fr 1fr 1fr 1fr; } }
    @media all and (max-width: 1299px) {
      &.multiple {
        grid-template-columns: 1fr 1fr 1fr; } } } }
.content-library-wrap {
  position: relative;
  .on-top {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #fff;
    &.ot-1 {
      z-index: 1; }
    &.ot-2 {
      z-index: 2; } } }
.content-library-sidebar {
  width: 450px;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  background-color: white;
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.12), 0 2px 3px 0 rgba(0, 0, 0, 0.22); }
.wrap-header {
  background-color: #F3F5F9;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  color: #4C5157; }
