.post-component{
  margin-bottom: 20px;
  .card{
    border: 0;
  }
}

.line{
  margin: 2em auto 0 !important;
  width: 2% !important;
  border: 1px solid #2aa7e1;
}
