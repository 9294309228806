.global-styles-selector {
  width: 100%;
  align-items: center;
  .selector-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 5px;
    height: 35px;
    &+* {
      border-top: 1px solid #E4E4E4; }
    .fluxio-form {
      flex-grow: 1;
      label {
        width: 100%; } } }
  .selector-header-actions {
    .btn:first-child {
      margin-right: 5px; } }
  .selector-section {
    padding: 16px 10px;
    border-bottom: 1px solid #E4E4E4;
    .form-group {
      height: 100%;
      input {
        height: 100%; } } }
  .selector-list {
    .form-row {
      margin-bottom: 10px; } }
  .fluxio-form {
    .btn-option {
      i.fas {
        color: #A8ABBF; } } }
  .btn.btn-fluxio.btn-sm {
    padding: 6px;
    i {
      font-size: 10px; } } }
