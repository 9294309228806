.color-picker-wrap {
  position: absolute;
  right: 100%;
  background-color: #ffffff;
  top: 50%;
  transform: translateY(-50%);
  z-index: 999;
  &.color-picker-extended {
    box-shadow: 0 1px 5px #ccc;
    border: 1px solid #ccc;
    .rc-color-picker-panel-inner {
      border: none;
      border-bottom: 1px solid #dee2e6;
      border-radius: 0;
      box-shadow: none; } }
  .has-foundation {
    position: relative;
    pointer-events: none;
    cursor: not-allowed;
    .rc-color-picker-panel-inner {
      background: #f7f8f8;
      opacity: 0.3; } }
  .color-picker-loader {
    position: absolute;
    background: rgba(255,255,255, 0.5);
    width: 220px;
    height: 254px;
    right: 100%;
    top: 50%;
    transform: translateY(-50%);
    z-index: 99999;
    i {
      display: flex;
      width: 100%;
      height: 100%;
      justify-content: center;
      align-items: center;
      color: #fff; } } }
.color-picker-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: transparent; }
