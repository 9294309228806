.page-header {
  background: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .fluxio-nav-tabs {
    padding-top: 15px;
    .nav-tab {
      width: 100%;
      color: #333;
      display: flex;
      flex-direction: column;
      border-bottom: 3px solid #C9D6EB;
      font-size: 20px;
      font-weight: bold;
      &.active {
        color: $fluxio-blue;
        border-bottom: 3px solid $fluxio-blue; }
      img {
        margin-bottom: 10px;
        height: 25px; }
      p {
        margin-bottom: 10px; } } }
  .entity-wrap {
    padding-top: 50px;
    .entity-title {
      font-size: 28px;
      font-weight: 600;
      margin-bottom: 0; }
    .entity-description {
      font-size: 18px;
      font-weight: 200;
      color: #A7ADBF; } }
  .entity {
    display: flex;
    align-self: flex-start;
    justify-content: flex-start;
    align-items: center;
    padding: 25px 0;
    .entity-avatar {
      border-radius: 50%;
      max-width: 100%;
      object-fit: cover;
      margin-right: 25px;
      &.float {
        margin-bottom: -100px; } } } }

