.adam-navbar{
  min-height: 56px;
  .navbar-nav{
    text-shadow: none !important;
    margin-top: 0 !important;
  }
  .nav-link{
    color: inherit !important;
  }
}

.adam-navbar-safe-space{
  height: 58px;
}
