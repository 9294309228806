.adam-hero{
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
  //dirty fix for image resize when the address bar hides in chrome mobile
  transition: height 999999s;
  .container, .row{
    height: 100%;
  }
  .text-center{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .col-md-6.image{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .col-md-6.text{
    display: flex;
    text-align: left;
    flex-direction: column;
    justify-content: center;
    h1{
      &.title{
        margin-bottom: 0.5em;
      }
    }
    p{
      &.description{
        font-size: 23px;
      }
    }
  }
  .hexagon-wrap{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    height: 100%;

    svg{
      filter: drop-shadow(5px 5px 4px #a9a9a9);
      padding: 10px;
    }

    .hexagon0{
      position: absolute;
      left: 2.5em;
      width: 68%;
      top: 4.5em;
    }
    .hexagon1{
      position: absolute;
      width: 30%;
      right: 1em;
      top: 4em;
    }
    .hexagon2{
      position: absolute;
      bottom: 0;
      width: 50%;
      right: -6em;
    }
  }
}

.carousel.carousel-slider{
  //margin-top: 65px;
  height: 40vh;
  margin-bottom: 10px;

  .control-arrow{
    padding: 15px;
  }

  .slider-wrapper{
    height: 100%;
    ul.slider{
      height: 100%;

      .heroElements{
        display: flex;
        height: 40vh;
        align-items: center;
        text-align: left;
        background-size: cover;
        background-position: top center;
        background-repeat: no-repeat;
        //dirty fix for image resize when the address bar hides in chrome mobile
        //transition: height 999999s;

        // &:before{
        //   position: absolute;
        //   content: " ";
        //   top: 0;
        //   left: 0;
        //   width: 100%;
        //   height: 100%;
        //   background: rgba(0, 0, 0, 0.5);
        // }

        .container{
          margin: 0 auto;
          .text{
            padding: 0 10px;            
            .title{
              font-size: 40px;
              font-weight: bold;
              color: #fff;
              margin-bottom: 0.25em;
              //text-shadow: 0 4px 3px rgba(0,0,0,.4), 0 8px 13px rgba(0,0,0,.1), 0 18px 23px rgba(0,0,0,.1);
            }
            .description{
              font-size: 20px;
              color: #fff;
              //text-shadow: 0 4px 3px rgba(0,0,0,.4), 0 8px 13px rgba(0,0,0,.1), 0 18px 23px rgba(0,0,0,.1);
            }
          }
        }
      }
    }
  }
}

.eva-hero-image{
  &.heroElements{
    background-attachment: fixed;
  }
}

// MEDIA QUERIES

@media screen and (max-width: 1000px) {
  .col-md-6.hexagon {
      display: none !important;
  }
}

@media screen and (max-height: 900px) {
  .col-md-6.hexagon {
      display: none !important;
  }
}

// MEDIA QUERIES

@media screen and (max-width: 768px) {
  .adam-hero {
    height: auto !important;
    .col-md-6.text{
      margin-top: 80px;
    }
    .col-md-6.image{
      align-items: flex-start;
    }
    h1.title{
      font-size: 1.5em;
    }
    .description{
      font-size: 1.1em !important;
    }
  }
}
