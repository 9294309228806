.main-dashboard,
.user-settings,
.workspace-settings {
  padding: 0 0 40px 0;
  h4 {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 10px; }
  hr {
    margin: 30px 0; }
  .dashboard-user {
    margin-bottom: 40px; }
  .user-entity {
    display: flex;
    align-items: center;
    .avatar {
      font-size: 48px;
      margin-right: 10px;
      width: 75px;
      height: 75px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #009fdb;
      color: #fff; } }
  .dashboard-workspace {
    margin-bottom: 180px; }
  .workspace-entity {
    position: relative;
    display: flex;
    justify-content: center;
    height: 250px;
    padding-bottom: 20px;
    background-color: #eee;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    &>.container {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      align-items: flex-end;
      color: #ffffff;
      text-shadow: 0px 0px 5px rgba(0,0,0,0.3); } }
  .workspace-avatar {
    width: 100%;
    border-radius: 5px; }
  .workspace-title {
    font-size: 28px;
    font-weight: 600;
    text-transform: capitalize;
    margin-bottom: 0; }
  .workspace-description {
    font-size: 14px;
    font-weight: 200;
    color: #A7ADBF; }
  .workspace-date {
    font-size: 14px;
    color: #A7ADBF; }
  .list-square {
    display: flex;
    flex-wrap: wrap;
    &.expandable {
      max-height: 280px;
      overflow: hidden;
      transition: max-height 0.5s ease;
      &.expanded {
        max-height: 1000000px;
        transition: max-height 1s ease; } }
    &.no-guts {
      .list-item,
      [class^="col-"] {
        margin: 0; }
      [class^="col-"] {
        padding: 0; } }
    &.borders {
      border: 1px solid #ddd;
      .list-item {
        border-right: 1px solid #ddd;
        &:lst-child {
          border-right: none;
          border-bottom: none; } } }
    a {
      text-decoration: none!important; }
    .list-item {
      text-align: center;
      font-size: 14px;
      color: #333;
      background: #eee;
      display: flex;
      padding: 10px;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      flex-direction: column;
      height: 120px;
      margin: 10px 0;
      &:hover {
        background: #ddd; }
      &.active {
        background: #ddd; }
      img {
        width: 50px;
        height: 50px;
        margin-bottom: 5px; }
      i {
        font-size: 22px;
        color: $fluxio-blue;
        margin-bottom: 10px; } } } }
