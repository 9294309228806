//PageTree Context Menu
.react-contexify__theme--blue {
  background-color: #4253FC !important;
  font-family: 'Eina';
  & .react-contexify__submenu {
    background-color: rgba(40, 40, 40, 0.98) !important; }
  & .react-contexify__separator {
    background-color: #eee !important; }
  & .react-contexify__item__content {
    color: #ffffff !important;
    font-size: 12px;
    p {
      margin-left: 5px; }
    i {
      color: #000; } }
  & .react-contexify__item:not(.react-contexify__item--disabled):hover > .react-contexify__item__content,
  .react-contexify__item:not(.react-contexify__item--disabled):focus > .react-contexify__item__content {
    background: #3441d6 !important; } }

//Inline Content Text Menu
.react-contexify__theme--text-white {
  background-color: #fff !important;
  font-family: 'Eina';
  & .react-contexify__submenu {
    background-color: rgba(40, 40, 40, 0.98) !important; }
  & .react-contexify__separator {
    background-color: #eee !important; }
  & .react-contexify__item__content {
    flex-direction: column;
    align-items: normal;
    color: #000 !important;
    font-size: 12px;
    p {
      margin-left: 5px; }
    i {
      color: #000; } }
  & .react-contexify__item:not(.react-contexify__item--disabled):hover > .react-contexify__item__content,
  .react-contexify__item:not(.react-contexify__item--disabled):focus > .react-contexify__item__content {
    background: #fff !important; } }
