.react-datepicker-popper {
  .react-datepicker {
    border: none;
    border-radius: 3px;
    background-color: #ffffff;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.5);
    display: flex;
    .react-datepicker__month-container {
      background-color: #ffffff;
      font-size: 10px;
      font-weight: 600;
      letter-spacing: 0.4px;
      text-align: left;
      color: #767a81;
      .react-datepicker__header {
        background-color: #ffffff; } }
    .react-datepicker__time-container {
      border: none;
      .react-datepicker__header {
        background-color: #ffffff; }
      .react-datepicker__time {
        .react-datepicker__time-list-item {
          &:hover {
            background-color: $fluxio-blue; } }
        .react-datepicker__time-list-item--selected {
          background-color: $fluxio-blue; } } } } }
.react-datepicker-wrapper {
  cursor: pointer;
  margin: 0;
  width: 100%;
  .react-datepicker__input-container {
    width: 100%; } }
