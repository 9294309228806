.meshGrid{
  display: inline-block;
  max-width: 100%;
  vertical-align: top;
  padding: 0 5px;
  text-align: left;
}

.infinite-scroll-component{
  .row{
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}

.pills-menu{
  .row{
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}

// @media (min-width: 576px) {
//     .mesh-1{
//       width: 45px;
//     }
//     .mesh-2{
//       width: 90px;
//     }
//     .mesh-3{
//       width: 135px;
//     }
//     .mesh-4{
//       width: 180px;
//     }
//     .mesh-5{
//       width: 225px;
//     }
//     .mesh-6{
//       width: 270px;
//     }
//     .mesh-7{
//       width: 315px;
//     }
//     .mesh-8{
//       width: 360px;
//     }
//     .mesh-9{
//       width: 405px;
//     }
//     .mesh-10{
//       width: 450px;
//     }
//     .mesh-11{
//       width: 495px;
//     }
//     .mesh-12{
//       width: 540px;
//     }
//   }
//
//   @media (min-width: 768px) {
//       .mesh-1{
//         width: 60px;
//       }
//       .mesh-2{
//         width: 120px;
//       }
//       .mesh-3{
//         width: 180px;
//       }
//       .mesh-4{
//         width: 240px;
//       }
//       .mesh-5{
//         width: 300px;
//       }
//       .mesh-6{
//         width: 360px;
//       }
//       .mesh-7{
//         width: 420px;
//       }
//       .mesh-8{
//         width: 480px;
//       }
//       .mesh-9{
//         width: 540px;
//       }
//       .mesh-10{
//         width: 600px;
//       }
//       .mesh-11{
//         width: 660px;
//       }
//       .mesh-12{
//         width: 720px;
//       }
//     }

@media (min-width: 992px) {
    .mesh-1{
      width: 80px;
    }
    .mesh-2{
      width: 160px;
    }
    .mesh-3{
      width: 240px;
    }
    .mesh-4{
      width: 320px;
    }
    .mesh-5{
      width: 400px;
    }
    .mesh-6{
      width: 480px;
    }
    .mesh-7{
      width: 560px;
    }
    .mesh-8{
      width: 640px;
    }
    .mesh-9{
      width: 720px;
    }
    .mesh-10{
      width: 800px;
    }
    .mesh-11{
      width: 880px;
    }
    .mesh-12{
      width: 960px;
    }
  }

  @media (min-width: 1200px) {
      .mesh-1{
        width: 95px;
      }
      .mesh-2{
        width: 190px;
      }
      .mesh-3{
        width: 285px;
      }
      .mesh-4{
        width: 380px;
      }
      .mesh-5{
        width: 475px;
      }
      .mesh-6{
        width: 570px;
      }
      .mesh-7{
        width: 665px;
      }
      .mesh-8{
        width: 760px;
      }
      .mesh-9{
        width: 855px;
      }
      .mesh-10{
        width: 950px;
      }
      .mesh-11{
        width: 1045px;
      }
      .mesh-12{
        width: 1140px;
      }
    }
