.wrapper-component {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  // .row-wrapper
  //   margin: 0
  //   >.col
 }  //     padding: 0

.wrapper-empty {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 10px;
  .msg {
    color: #797979;
    font-size: 14px;
    font-weight: bold; } }

//Fix for super small screens with lean active
/* @media only screen and (max-width: $meshapp-breakpoint-md)
  .wrapper-component
    &.lean-enable
      clip-path: polygon(0% 40px, 100% 0%, 100% 98%, 0% 100%) !important */
