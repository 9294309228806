.adam-anchor{
  margin-top: 1em;

  .anchor-elements{
    padding: 0;
  }

  .anchor-nav{
    list-style: none;
    display: flex;
    justify-content:space-around;
    border-bottom: solid 1px #e3e3e3;
    padding: 0;
    background-color: white;
    .anchor-li{
      padding: 0.6em;
      text-align: center;
      flex-basis: 0;
      flex-grow: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      a{
        text-decoration: none;
        &.anchor-link{
          cursor: pointer;
        }
      }
    }
  }
}
