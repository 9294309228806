@keyframes Pulse {
  0% {
    opacity: 10%; }
  50% {
    opacity: 100%; }
  100% {
    opacity: 10%; } }
#object-selected {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  border: 1px solid rgba(64, 80, 252, 0.3);
  pointer-events: none;
  z-index: 50;
  // animation: Pulse 3s infinite ease-in-out
  &.isSymbol {
    border-color: $fluxio-orange; }
  &.isSelected {
    border-color: rgba(64, 80, 252, 1); } }
#activeElementOverlay {
  pointer-events: none;
  .element-selector-wrapper-padding {
    position: absolute;
    width: 100%;
    height: 100%;
    .element-selector-side {
      background: repeating-linear-gradient(45deg, transparent, transparent 2px, rgba(52, 206, 24, 0.35) 4px, rgba(52, 206, 24, 0.35) 5px);
      position: absolute;
      &.padding-top {
        width: 100%;
        top: 0; }
      &.padding-bottom {
        width: 100%;
        bottom: 0; }
      &.padding-left {
        height: 100%;
        left: 0; }
      &.padding-right {
        height: 100%;
        right: 0; } } }
  .element-selector-wrapper-margin {
    position: absolute;
    width: 100%;
    height: 100%;
    .element-selector-side {
      background: repeating-linear-gradient(45deg, transparent, transparent 2px, rgba(206, 133, 24, 0.35) 4px, rgba(206, 133, 24, 0.35) 5px);
      position: absolute;
      &.margin-top {
        width: 100%; }
      &.margin-bottom {
        width: 100%; }
      &.margin-left {
        height: 100%; }
      &.margin-right {
        height: 100%; } } }
  .badges {
    position: absolute;
    bottom: 100%;
    left: 0;
    .badge:first-child {
      margin-right: 5px; } } }
