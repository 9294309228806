.template-preview {
  position: relative;
  display: flex;
  flex: 1 0 0%;
  flex-direction: column;
  margin-bottom: 40px;
  max-width: 100%;
  .template-preview-info-trigger {
    color: #fff;
    position: absolute;
    top: 5px;
    right: 15px;
    font-size: 22px; }
  .template-preview-loading {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.1);
    z-index: 9;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 35px;
    color: #466576; }
  .template-preview-info {
    .template-preview-status {
      &.primary {
        color: #119fda; }
      &.success {
        color: #00d657; } }
    .template-preview-title {
      font-size: 18px;
      font-weight: 600; } }
  .badge {
    padding: 10px;
    font-weight: normal;
    margin-right: 7px;
    margin-bottom: 7px;
    &:last-child {
      margin-right: 0; } }
  // card component
  .card {
    margin-bottom: 10px;
    background: #e9ecef;
    border: 0;
    cursor: pointer;
    height: 450px;
    max-height: 450px;
    .card-img-top {
      height: 100%;
      object-fit: cover;
      object-position: 0 0;
      border: 1px solid #eee;
      box-shadow: 5px -2px 15px 0 rgba(0,0,0,0.1); }
    .card-img-overlay {
      padding-top: 25%;
      text-align: center; }
    .card-title {
      color: #fff;
      text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.5); }
    .card-body,
    .card-footer {
      display: none; }
    &:hover,
    &.showActions {
      .card-img-overlay {
        background: rgba(0,0,0,0.5); }
      .card-body,
      .card-footer {
        display: block; } } } }
