.splash-screen{
  .splash-heroImage{
    height: 100vh;
    width: 100%;
    background-color: #ffffff;
    background-size: cover;
    background-position: center center;
    display: flex;
    align-items: center;
    justify-content: center;
    img{
      width: 50vh;
    }
  }
  .splash-title{
    color: #fff;
    text-shadow: 0px 0px 5px #000;
  }
}
.splash{
  padding-left: 0 !important;
  padding-right: 0 !important;
}
figure {
	position: relative;
	float: left;
	overflow: hidden;
	margin: 10px 1%;
	background: #3085a3;
	text-align: center;
	cursor: pointer;
}

figure img {
	position: relative;
	display: block;
	min-height: 100%;
	max-width: 100%;
	opacity: 0.8;
}

figure figcaption {
	padding: 2em;
	color: #fff;
	text-transform: uppercase;
	font-size: 1.25em;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
}

figure figcaption::before,
figure figcaption::after {
	pointer-events: none;
}

figure figcaption,
figure figcaption > a {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

/* Anchor will cover the whole item by default */
figure figcaption > a {
	z-index: 1000;
	text-indent: 200%;
	white-space: nowrap;
	font-size: 0;
	opacity: 0;
}

figure h2 {
	word-spacing: -0.15em;
	font-weight: 300;
}

figure h2 span {
	font-weight: 800;
}

figure h2,
figure p {
	margin: 0;
}

figure p {
	letter-spacing: 1px;
	font-size: 68.5%;
}

figure.effect-sadie figcaption::before {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: linear-gradient(to bottom, rgba(72,76,97,0) 0%, rgba(72,76,97,0.8) 75%);
	content: '';
	opacity: 0;
	transform: translate3d(0,50%,0);
}

figure.effect-sadie h2 {
	position: absolute;
	top: 50%;
	left: 0;
	width: 100%;
	color: #ccd0e8;
  text-shadow: 0px 0px 20px #2d2d2d;
	transition: transform 0.35s, color 0.35s;
	transform: translate3d(0,-50%,0);
}

figure.effect-sadie figcaption::before,
figure.effect-sadie p {
	transition: opacity 0.35s, transform 0.35s;
}

figure.effect-sadie p {
	position: absolute;
	bottom: 0;
	left: 0;
	padding: 2em;
	width: 100%;
	opacity: 0;
	transform: translate3d(0,10px,0);
}

figure.effect-sadie:hover h2 {
	color: #fff;
	transform: translate3d(0,-50%,0) translate3d(0,-40px,0);
}

figure.effect-sadie:hover figcaption::before ,
figure.effect-sadie:hover p {
	opacity: 1;
	transform: translate3d(0,0,0);
}

.loginpage-wrap{
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  height: 100%;
  background-color: #f4f6f8;
  background-position: center center;
  background-repeat: repeat;
  background-size: inherit;

  .card{
    background-color: #262626;
    color: #fff;
    .btn{
      border-radius: 3px;
    }
    .card-header, .card-footer{
      background-color: #262626;
    }
  }

  .login-box{
    background-color: #FFFFFF;
    padding: 20px 20px;
    border-radius: 4px;
    .profile-avatar-img{
      border-radius: 50%;
    }
    p{
      margin: 0;
      &.login-name{
        font-weight: 400;
        font-size: 2em;
      }
      &.login-idp{
        text-transform: uppercase;

      }
    }
    form{
      .form-group{
        .field{
          input.form-control, input.form-control:focus{
            background-color: #262626;
            color: #eee;
            border-radius: 3px;
          }
        }
      }
      label{
        text-transform: uppercase;
        letter-spacing: 3px;
        color: #777;
        font-size: 14px;
        font-weight: bold;
      }
    }
    .btn-primary{
      background-color: #204fa2;
      border: none;
      border-radius: 3px;
      text-transform: uppercase;
      letter-spacing: 3px;
      font-size: 12px;
    }
  }
}

/* - - - - - - MODAL - - - - - - */
.loginBtn{
  position: fixed;
  right: 20px;
  bottom: 20px;
}

.modal-login{
  .card{
    img{
      object-fit: cover;
      height: 100%;
    }
    p{
      margin-bottom: 0;
    }
  }
}

.register-frase{
  color: #777;
  padding: 0;
  margin-top: 15px !important;
}

.error-frase{
  color: #fff;
  padding: 0;
  background-color: #dd2c00;
  padding: 10px;
  border-radius: 3px;
  margin: 10px 0 !important;
}

.facebook-login{
  background-color: #3b5998;
  color: white;
  border: none;
  padding: 20px;
  width: 100%;
  border-radius: 4px;
  margin-bottom: 10px;
  text-transform: uppercase;
  letter-spacing: 3px;
  font-size: 12px;
}

.registed-msg-wrapper{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  button{
    margin-top: 10px;
  }
  .registed-msg{
    display: flex;
    justify-content: center;
    align-items: center;
    .fas, .fab{
      margin-right: 10px;
      font-size: 3em;
      color: #00c853;
    }
    p{
      color: #eee;
    }
  }
}
