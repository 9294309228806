.domain-cell-wrapper {
  background-color: #FFFFFF;
  border: 1px solid #e0e3eb;
  margin-bottom: -1px;
  .validate-records-modal {
    .modal-dialog {
      max-width: 700px;
      .modal-content {
        .modal-footer {
          padding: 0;
          display: flex;
          justify-content: center;
          button {
            margin: 10px;
            border: none;
            outline: none;
            border-bottom: 1px solid gray;
            cursor: pointer;
            width: 100%; } }
        .modal-body {
          .google-txt-wrapper {
            .table-txt-record {
              width: 100%;
              margin-top: 20px;
              .table-txt-record-head {
                tr {
                  border: 2px solid #eff1f5;
                  background-color: #4fcfff;
                  th {
                    text-align: center;
                    font-size: 12px;
                    color: #767a81;
                    //width: 50%
                    padding: 5px; } } }
              .table-txt-record-body {
                tr {
                  border: 2px solid #eff1f5;
                  td {
                    text-align: center;
                    font-size: 14px;
                    //width: 50%
                    padding: 5px; } } } } } } } } }
  .inner-cell {
    display: flex;
    div {
      padding: 10px;
      cursor: pointer;
      display: flex;
      align-items: center;
      &.first-column,
      &.second-column,
      &.third-column,
      &.fourth-column,
      &.fifth-column,
      &.sixth-column {
        width: 100%;
        button {
          background-color: blue;
          border: none;
          border-radius: 6px;
          color: #FFF;
          font-weight: bold;
          outline: none;
          cursor: pointer;
          &.disabled {
            cursor: default;
            background-color: gray; } } }
      &.last-column {
        width: 4%;
        background-color: white;
        justify-content: center; }
      &.delete {
        color: white;
        cursor: default;
        p {
          margin: 0; }
        span {
          cursor: pointer;
          margin-right: 20px;
          font-weight: bold; } } }
    &.delete {
      background-color: #515151; } }
  .expanded-wrapper {
    overflow: hidden;
    height: auto;
    padding: 10px;
    // Dont hide child elements that overflow the parent when it's open
    &:not(.not-grow) {
      overflow: visible; }
    .apply-expand-changes {
      background-color: $fluxio-blue;
      color: #ffffff;
      padding: 5px 10px;
      border: 1px solid transparent;
      border-radius: 4px;
      font-weight: 600;
      font-size: 14px;
      cursor: pointer;
      outline: none;
      margin-left: 20px; }
    .expand-field-wrapper {
      margin-bottom: 10px;
      padding: 2px;
      margin-left: 20px;
      .txt-verification-outputters {
        .copy-clipboard-wrapper {
          display: flex;
          .value {
            margin: 0; }
          span {
            padding-left: 10px;
            cursor: pointer;
            i {
              &.copied {
                color: green; } } } } }
      input {
        outline: none;
        border-radius: 4px;
        background-color: transparent; }
      .disabled-expand-input {
        display: flex;
        .disabled-expand-text {
          margin: 0px;
          font-size: 16px;
          padding: 0px;
          border-radius: 4px; } }
      .expand-field-label {
        margin: 0;
        font-size: 16px;
        font-weight: bold;
        color: $fluxio-blue; } }
    &.not-grow {
      height: 0px;
      padding: 0px; } } }
.delete-domain {
  background-color: white;
  z-index: 10;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; }
