.cta-navbar {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  max-width: 100%;
  max-height: 100%;
  height: 65px;
  padding: 5px;
  .cta-navbar-image {
    max-height: 30px;
    object-fit: contain; }
  .cta-navbar-text {
    text-align: center;
    font-size: 14px;
    margin: 0px;
    flex: 1;
    &.awithhover {
      text-decoration: none; }
    &.pwithimage {
      flex: none;
      margin-right: 5px; } } }
