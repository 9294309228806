.manager-wrapper {
  margin: 0 -15px;
  height: 100vh;
  overflow-y: auto;
  padding: 5px 20px;
  .form-control {
    color: #bbb;
    padding: 5px;
    background-color: #262626;
    border: none;
    border-radius: 3px; }
  .filter-btn:first-of-type {
    margin: 5px 0; }
  .modal-body, .modal-footer {
    animation: fadein 1s; } }

.profile-tags-wrap {
  background-color: #363636;
  color: #bbb;
  padding: 10px;
  margin: 5px;
  .profile-tag-name, .profile-tag-id {
    margin: 0; }
  .profile-tag-id {
    font-size: 12px; }
  &:hover {
    background-color: #303030;
    cursor: default; } }
