.library-table-wrap {
  border: 1px solid #E8ECF1;
  border-radius: 3px;
  padding: 16px;
  margin: 16px 0;
  .table-name {
    font-size: 14px;
    font-weight: 600;
    color: #202645;
    margin: 0; }
  table.library-table {
    width: 100%;
    border: 1px solid #e8ecf1;
    box-shadow: 0px 1px 8px rgba(144, 148, 189, 0.1);
    border-radius: 3px;
    margin: 16px 0;
    table-layout: auto;
    thead {
      border: 1px solid #E8ECF1;
      th {
        font-size: 10px;
        font-weight: bold;
        text-transform: uppercase;
        color: #C4C9D8;
        padding: 16px 16px 8px; }
      th.cell-xs {
        width: 75px; }
      th.cell-pd {
        padding-left: 25px; } }
    tbody {
      td {
        padding: 8px 16px 8px;
        font-size: 12px;
        color: #202645; }
      td.last-cell {
        position: relative;
        border-bottom: 1px solid #E8ECF1; }
      td.vertical-aligned {
        vertical-align: baseline; }
      td.notEditable {
        cursor: default; } } }
  .table-load {
    font-size: 10px;
    color: #ADB5CB;
    button {
      display: flex;
      border: none;
      background: transparent;
      outline: none;
      align-items: center;
      color: #ADB5CB;
      * {
        flex: 1; } } }
  .table-input,
  .color-preview-input {
    border-color: transparent;
    padding-top: 3px;
    padding-bottom: 3px; }
  .devices-controller {
    i {
      padding: 0 5px; } }
  input {
    max-width: 100%; } }

.shadow-square-preview {
  background-color: #F4F4F4;
  width: 25px;
  height: 25px; }

ul.action-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
  .list-item {
    border: 1px solid #e8ecf1;
    box-shadow: 0px 1px 8px rgba(144, 148, 189, 0.1);
    border-radius: 3px;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px 0;
    h3 {
      font-size: 16px;
      margin: 0; }
    span {
      font-size: 12px;
      font-weight: bold;
      color: #767A81; }
    .btn {
      height: fit-content; } } }
