.finder-rightbar {
  position: absolute;
  right: 0;
  height: 100%; }
.finder-wrap {
  font-family: 'Eina', Arial, Helvetica;
  background-color: #d3d6dc;
  height: 100%;
  position: absolute;
  z-index: 15;
  display: flex;
  .finder-right {
    right: 0; }
  .finder-left {
    left: 0; }
  flex-direction: column;
  &[disabled],
  *[disabled] {
    background: #f7f8f8;
    opacity: 0.3;
    pointer-events: none;
    cursor: not-allowed; }
  &.is-expanded {
    position: absolute;
    width: 100vw;
    top: 0;
    left: 0;
    z-index: 9999; }
  .Collapsible {
    margin: 0; }
  .section-collapse {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px;
    background-color: #fff;
    border-bottom: 1px solid $fluxio-blue;
    text-transform: capitalize;
    font-size: 11px;
    font-weight: bold;
    letter-spacing: 0.5px; }
  .preview-wrap {
    right: 100%;
    top: 0;
    padding: 0;
    height: 100%;
    overflow: auto;
    width: 100%;
    position: absolute;
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    .on-top {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #fff;
      z-index: 2; }
    .wrap-header {
      background-color: #F3F5F9;
      font-size: 14px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 15px;
      color: #4C5157; }
    .wrapper {
      padding: 10px 5px; }
    .card {
      border: 0;
      &+.card {
        margin-top: $meshapp-spacing-md; }
      >.card-title {
        margin-bottom: $meshapp-spacing-sm; }
      .card-img-top {
        max-height: 150px;
        object-fit: cover; } }
    .backdrop {
      position: fixed;
      background: rgba(29, 34, 49, 0.5);
      height: 100vh;
      width: 100vw;
      left: 0;
      z-index: -1; }
    .media-library {
      height: 100%; } }
  ul.pagination {
    justify-content: center;
    margin-top: 20px;
    li {
      a {
        cursor: pointer;
        border: 1px solid #e4eaec;
        color: #76838f;
        padding: 9px 15px;
        margin-left: -1px;
        background-color: #ffffff;
        transition: background .2s ease-out,border-color 0s ease-out,color .2s ease-out;
        outline: none;
        &:hover {
          text-decoration: none;
          transition: background .2s ease-out,border-color .2s ease-out,color .2s ease-out;
          z-index: 2;
          color: #89bceb;
          background-color: #f3f7f9;
          border-color: #e4eaec;
          outline: none; } }
      &.active a {
        background-color: #62a8ea;
        border-color: #62a8ea;
        color: #ffffff;
        outline: none; }
      &.disabled {
        color: #ccd5db;
        cursor: not-allowed; } } }
  &>.wrapper {
    background-color: #ffffff;
    height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;
    &>.wrap {
      padding: 0 5px; }
    .header {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .fa-long-arrow-alt-left {
        color: $fluxio-blue;
        cursor: pointer; }
      h2.title {
        margin: auto;
        font-size: 22px;
        font-weight: bold;
        color: #27272d; } }
    .actions-wrap {
      margin: 10px 0;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      .search-wrap {
        input.search {
          border: none;
          text-align: right;
          outline: none;
          padding: 0 10px;
          background-color: #ffffff; } } }
    .content-finder {
      ul.formulas-list {
        list-style-type: none;
        margin: 0;
        padding: 0;
        li.formula {
          padding: 7px 5px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          border-radius: 5px;
          margin: 3px 0;
          position: relative;
          pointer-events: all;

          @mixin hideHover {
            opacity: 0;
            transition: opacity 0s ease; }

          @mixin showHover {
            opacity: 1;
            transition: opacity 0.3s ease;
            transition-delay: 1s; }

          .hover-wrapper {
            position: absolute;
            left: 2%;
            right: 2%;
            height: 250px;
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;
            z-index: 999999 !important;
            pointer-events: none;
            display: flex;
            flex-direction: column;
            img {
              box-shadow: 0px 0px 37px 11px rgba(0,0,0,0.53); }
            @include hideHover(); }
          &:hover {
            background-color: #525252;
            cursor: pointer;
            .hover-wrapper {
              @include showHover(); }
            .fa-folder,
            .fa-paint-brush,
            p.name {
              color: #ffffff; } }
          .fa-folder,
          .fa-paint-brush {
            font-size: 30px;
            margin: 0 10px 0 0;
            color: #d3d6dc;
            padding: 0 3px; }
          p.name {
            margin: 0;
            font-size: 15px;
            font-weight: 600;
            color: #27272d; }
          p.description {
            margin: 0;
            font-size: 13px;
            color: #27272d; } } } } }
  .panel-wrap {
    background-color: #ffffff;
    height: 100%;
    display: flex;
    position: relative;
    .panel-content {
      width: 100%;
      border-right: 2px solid #f4f6f8;
      display: flex;
      flex-direction: column;
      padding: 5px;
      .panel-scrollable {
        overflow-x: hidden;
        height: 100%; } } }
  .lists {
    display: flex;
    flex-direction: row;
    overflow-y: auto;
    overflow-x: hidden;
    .component {
      display: flex;
      flex-direction: column;
      margin: 0 2px;
      &.tags {
        align-items: flex-start;
        width: 40%;
        span.tag {
          cursor: pointer;
          background-color: #ffffff;
          border-radius: 2.5px;
          border: 2px solid $fluxio-blue;
          margin-bottom: 5px;
          color: $fluxio-blue;
          padding: 5px 10px;
          font-size: 12px;
          font-weight: 600;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: -0.1px;
          &.selected {
            background-color: $fluxio-blue;
            color: #ffffff; } } }
      &.previews {
        display: flex;
        flex-direction: column;
        width: 100%;
        .preview {
          position: relative;
          width: 100%;
          margin-bottom: 20px;
          border-bottom: 1px solid #ccc;
          padding: 5px;
          color: #27272d;
          img {
            object-fit: cover;
            margin-bottom: 15px;
            width: 100%; }
          p.name {
            font-size: 12px;
            font-weight: 600;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: -0.1px;
            text-align: left;
            margin: 0 0 10px 0; }
          p.category {
            font-size: 8px;
            font-weight: 600;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: -0.1px;
            text-align: left;
            margin: 0 0 5px 0;
            text-transform: uppercase;
            color: #888; }
          .loading {
            position: absolute;
            background: rgba(0,0,0,0.5);
            width: 100%;
            height: 100%;
            i {
              position: absolute;
              transform: translate(-50%, -50%);
              left: 50%;
              top: 50%; } }
          &:hover {
            background-color: #fafafa; }
          &.selected {
            background-color: #fafafa; } } } } }
  .components {
    background-color: #ffffff;
    height: 100%;
    display: flex;
    .body-section {
      width: 100%;
      border-right: 2px solid #f4f6f8;
      display: flex;
      flex-direction: column;
      .wrapper {
        overflow-x: hidden; }
      .selector {
        margin-bottom: 15px; } }
    span.type {
      font-size: 9px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: 0.2px;
      color: #a3a7af;
      text-transform: uppercase;
      margin-bottom: 4px; } }
  .finder-tabs {
    display: flex;
    align-items: center;
    justify-content: space-around;
    background-color: #ffffff;
    .tab {
      background-color: #e9ebef;
      font-size: 14px;
      font-weight: 600;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: -0.1px;
      color: #27272d;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      padding: 10px 0;
      &:hover {
        background-color: #f5f5f5; }
      p.name {
        margin: 0; }
      &.selected {
        background-color: #ffffff;
        align-items: flex-end;
        p.name {
          border-bottom: 2px solid $fluxio-blue; } } } }
  // react tooltip styles
  // must force width due to tooltip going outside of screen
  .tippy-popper {
    width: 300px; }
  .finder-actions-bar {
    width: 100%;
    padding: 15px;
    border-top: 1px solid $meshapp-grey;
    background: #fff; } }
.finder-actions-bar-fixed {
  position: absolute;
  left: 0;
  right: 0;
  margin: 10px;
  bottom: 50px;
  text-align: center;
  &.no-pagination {
    bottom: 0; } }

.main-actions {
  display: flex;
  align-items: center;
  padding: 20px;
  background-color: #ffffff;
  justify-content: space-between;
  height: 60px; //same value as tab height
  //border-bottom: 1px solid $meshapp-grey-light
  &>* {
    margin-bottom: 0; }
  img {
    padding: 6px;
    cursor: pointer; }
  .back {
    font-size: 14px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: -0.1px;
    color: $fluxio-blue;
    width: 100%;
    text-align: right;
    cursor: pointer; }
  .main-actions-title {
    font-size: 15px;
    font-weight: 600;
    text-transform: uppercase; } }
