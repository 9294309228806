.highlights-row{
  margin-bottom: 20px;
  .highlights-row-image-wrapper{
    padding: 10px;
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
    .highlights-row-image{
      width:  100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
