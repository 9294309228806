.meshapp-tooltip {
  color: #fff;
  font-size: 17px;
  margin-right: 5px; }

.popover {
  border: none;
  box-shadow: 0 4px 20px 4px rgba(0, 20, 60, 0.1), 0 4px 80px -8px rgba(0, 20, 60, 0.2);
  font-family: "Nunito Sans", sans-serif;
  .popover-header {
    border: 0;
    background-color: #f4f5f6;
    font-size: 15px;
    font-weight: 600; }
  .popover-body {
    font-size: 13px; } }
