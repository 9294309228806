.logo-fixed-right {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 100;
  width: 50px;
  height: auto; }

.temp-grafly {
  margin: 0 0 10px 5px; }

.b-cabinet-header,
.b-workbench-header,
.b-test-tube-header {
  background-color: #f5f6f8;
  padding: 40px 25px;
  font-family: 'Poppins', sans-serif;
  h2.title {
    font-weight: bold;
    margin: 0;
    color: #212435;
    display: inline; }
  p.sub-title {
    color: #c7c8cc;
    margin: 0; } }

.b-sidebar {
  background-color: #dde0e5;
  .user-avatar {
    margin-top: auto;
    img {
      width: 50px;
      height: 50px; } }
  .top-content {
    height: 100vh;
    display: flex;
    flex-direction: column;
    .main-menu {
      margin: 0;
      padding: 0;
      color: #fff;
      list-style-type: none;
      li.sidebar-item {
        display: flex;
        justify-content: center;
        align-items: center;
        a {
          width: 50px;
          max-width: 50px;
          height: 50px;
          max-height: 50px;
          border-left: 5px solid #dde0e5;
          text-align: center;
          line-height: 50px;
          font-size: 20px;
          color: #000000;
          &:hover {
            cursor: default;
            background-color: #f6f7f9;
            border-left: 5px solid #4eceff;
            margin-left: 0; }
          &.active {
            cursor: default;
            background-color: #f6f7f9;
            border-left: 5px solid #4eceff;
            margin-left: 0; } } } }
    .profile-switch {
      .profile-modal {
        position: absolute;
        background-color: #dde0e5;
        max-height: 50vh;
        overflow: auto;
        z-index: 10;
        a,a:hover {
          text-decoration: none;
          .modal-items {
            display: flex;
            align-items: center;
            cursor: pointer;
            padding: 10px;
            border-left: 5px solid #dde0e5;
            &:hover {
              background-color: #f5f6f8;
              border-left: 5px solid #4eceff; }
            img {
              width: 40px;
              height: 40px;
              object-fit: cover;
              border-radius: 3px; }
            p {
              color: #000000;
              padding: 0 15px;
              margin: 0; } } } }

      .profile-info {
        .avatar-wrap {
          img.avatar {
            width: 50px; } } } } } }

.b-cabinet-wrapper {
  font-family: 'Poppins', sans-serif;
  .xFormula {
    background-color: #f0f1f5;
    .cabinet-functions {
      color: #757a7f;
      .search-order-wrapper,
      .all-layout-wrapper {
        margin: 10px 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .order-wrapper {
          display: flex;
          flex-direction: row;
          .order-cabinet {
            background-color: #ffffff;
            border: 0 !important;
            border-radius: 3px;
            text-transform: uppercase; }
          p {
            text-transform: uppercase;
            margin: 0 5px 0 0; } }

        .breadcrumbs-wrapper {
          text-transform: uppercase;
          cursor: pointer;
          .breadcrumb-element {
            margin-right: 10px;
            &:after {
              content: ' > '; } } }
        .layout-changer {
          color: #d5d5dd;
          i.fas, i.fab {
            margin: 5px;
            &.selected {
              color: #76797e; } } } } }
    .new-formula {
      background-image: url('https://cdn.fluxio.cloud/meshapp/new_formula.png');
      border-radius: 10px;
      display: flex;
      justify-content: flex-end;
      align-items: flex-start;
      flex-direction: column;
      padding: 3%;
      font-weight: bold; } }

  .cabinet-squares {
    max-height: calc(100vh - 260px);
    overflow-y: auto;
    overflow-x: hidden; } }


.test-tube-item-count {
  background-color: #ffffff;
  color: #747a80;
  border-radius: 20px;
  padding: 5px 15px;
  margin-left: 10px;
  font-size: 16px; }

.workbench-action {
  font-family: 'Poppins', sans-serif;
  display: flex;
  padding: 0 5px;
  .filter-btn {
    margin: 0 5px;
    padding: 15px 0;
    &.reset {
      width: 25%;
      background-color: #767b81; }
    &.preview {
      width: 75%; } } }

.expression-description {
  h3.title {
    font-weight: bold; }
  p.text {
    color: #848486; } }

.selected-formula-info {
  display: flex;
  justify-content: space-between;
  .formula-info {
    h5 {
      font-weight: bold; }
    p {
      color: #909195;
      margin: 0;
      font-size: 14px;
      text-transform: uppercase; } }
  .formula-actions {
    display: flex;
    justify-content: center;
    align-items: center;
    i.fas, i.fab {
      margin-left: 10px;
      margin-right: 10px;
      color: #4db5fc;
      text-decoration: underline; }
    .formula-view {
      margin-right: 10px;
      .private-radiobox {
        display: flex;
        span {
          width: 20px;
          height: 20px;
          border: 2px solid #f0f1f5;
          border-radius: 50%;
          margin-right: 5px;
          &.false {
            background-color: #50b4ff;
            box-shadow: inset 0px 0px 0px 2px #ffffff; } }
        p {
          text-transform: capitalize;
          font-weight: bold; } } } } }

p.publish {
  color: #909195;
  text-transform: uppercase;
  margin: 0 !important;
  font-size: 14px; }

.trigger-wrapper {
  position: fixed;
  z-index: 10;
  display: flex;
  flex-direction: column;
  right: 50px;
  top: 0;
  .trigger-view {
    background-color: #ffffff;
    border: none;
    outline: none;
    width: 50px; } }
