
@media only screen and (max-width: 768px) {
  .vertical-menu-sidebar-wrapper {
    display: none;
  }

  .dropdown-vm-wrapper {
    width: 100%;
    background-color: #55b9e8;
    overflow: hidden;

    .vm-dropdown-inneroption {
      width: 100%;
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      display: flex;
      flex-direction: column;

      .vm-dropdown-inneroption-text {
        font-size: 20px;
        color: #FFFFFF;
        font-family: 'Open Sans', sans-serif;
      }

      .vm-dropdown-inneroption-separator {
        width: 70%;
        height: 2px;
        background-color: #FFFFFF;
        margin-top: 5px;
      }
    }
  }

  .vertical-menu-wrapper {
    padding: 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

  }

}

@media only screen and (min-width: 769px) {

  .vertical-menu-wrapper {
    padding: 0px;
    display: flex;
    flex-direction: row;

  }

  .dropdown-vm-wrapper {
    display: none;
  }

  .vertical-menu-sidebar-wrapper {
    background-color: #71B9E1;
    height: inherit;
    width: 20%;
    padding-top: 10px;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    .menu-sidebar-option {
      margin: 15px -2px 15px 15px;
      display: flex;
      flex-direction: row;
      align-items: center;
      cursor: pointer;
      color: #FFFFFF;
      font-size: 20px;
      text-transform: uppercase;
      border-left: 2px solid #71B9E1;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .fas{
        color: #71B9E1;
        font-size: 45px;
      }
      &.selected{
        border-left: 2px solid #fff;
        .fas{
          color: #fff;
        }
      }
      &:hover{
        color: #A5D3EC;
      }
      .menu-sidebar-text{
        padding-left: 15px;
        word-break: break-word;
      }
    }
  }
}

.vertical-menu-content-handler-wrapper {
  background-color: #FFFFFF;
  width: 85%;
  height: inherit;
  justify-content: center;

  .vertical-menu-view-more-modal {
    .modal-dialog {
      max-width: 517.5px;

    }

    .modal-content {
      background-color: #ffffff;
      justify-content: center;

      .modal-header{
        border-bottom: none;
        .close {
          color: #4b4b4c;
        }
      }
      .modal-body {
        justify-content: center;
        padding: 1rem 2rem;
        .vm-twitter-post-modal-container {
          .vertical-menu-twitter-post-wrapper{
            padding: 0;
            .vertical-menu-twitter-frame{
              width: 100%;
              margin: 0;
            }
          }
        }
      }

    }

  }

  .vertical-menu-posts-wrapper {
    padding-top: 0px;
    padding-bottom: 15px;
    width: 100%;
    margin-left: 1px;
  }

  .vertical-menu-twitter-post-wrapper {
    padding-top: 0px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
    align-items: center;

    .vertical-menu-twitter-frame {
      border-style: solid;
      height: 360px;
      margin: 2px;
      border-radius: 4px;
      border-color: #DBDBDB;
      background-position: bottom right;
      background-repeat: no-repeat;

      .twitter-body{
        margin: 0 5px;
      }

      .vertical-menu-twitter-header-wrapper {
        display: flex;
        flex-direction: row;
        padding: 10px 10px;

        .vertical-menu-twitter-header-info {
          padding-left: 10px;
          flex: 1;

          .vertical-menu-twitter-header-text-title {
            margin: 0px;
            padding: 0px;

            font-family: 'Open Sans', sans-serif;
            font-size: 13px;
            font-weight: 500;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: left;
            color: #4a4a4a;
          }

          .vertical-menu-twitter-header-text-handler {
            margin: 0px;
            padding: 0px;

            font-family: 'Open Sans', sans-serif;
            opacity: 0.75;
            font-size: 11px;
            font-weight: 500;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: left;
            color: #4a4a4a;
          }

        }

        .vertical-menu-twitter-header-date {
          padding-right: 5px;
          padding-top: 2px;
          padding-bottom: 2px;
          text-align: right;

          font-family: 'Open Sans', sans-serif;
          opacity: 0.75;
          font-size: 11px;
          font-weight: 500;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #4a4a4a;
        }

      }

      .vertical-menu-twitter-content-wrapper {
        margin-top: 15px;
        margin-bottom: 5px;
        padding-left: 0px;
        padding-right: 0px;
        height: 80%;
        display: flex;
        flex-direction: column;

        .twiter-image-group-wrapper{
          display: flex;
          justify-content: center;
          width: 100%;
          height: 100%;
          //min-height: 200px;
          background-color: transparent;
        }

        .vertical-menu-twitter-post-tile {
          color: #4A4A4A;
          font-size: 16px;
          margin-left: 5px;
          margin-right: 5px;
        }

        .vertical-menu-twitter-post-link {
          margin-left: 5px;
          margin-right: 5px;

          font-family: 'Open Sans', sans-serif;
          font-size: 16px;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: left;
          color: #4a4a4a;
        }

        .vertical-menu-twitter-post-image {
          margin: 0 10px 10px;

          font-family: 'Open Sans', sans-serif;
          font-size: 12px;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: left;
          color: #4a4a4a;
        }

        .vertical-menu-overlay-box {
          width: 85%;
          height: 74px;
          margin-top: -74px;
          position: absolute;
          background-color: transparent;
          .vertical-menu-overlay-box-title {
            width: 100%;
            height: 70%;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            font-size: 14px;
            padding: 7px 7px 0;
            .overlay-box-child {
              height: 100%;
              vertical-align: top;
              font-family: 'Open Sans', sans-serif;
              font-size: 14px;
              text-align: left;
              align-items: center;
              color: #4a4a4a;
            }
          }

          .vertical-menu-overlay-box-url {
            width: 100%;
            height: 30%;
            font-size: 12px;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            padding: 7px 7px 0;

            .overlay-box-child {
              height: 100%;
              vertical-align: bottom;

              font-family: 'Open Sans', sans-serif;
              font-size: 12px;
              text-align: left;
              color: #7c7c7c;

            }

          }

        }

        .vertical-menu-twitter-post-quote {
          margin-left: 5px;
          margin-right: 5px;

          font-family: 'Open Sans', sans-serif;
          font-size: 16px;
          font-weight: 500;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: left;
          color: #4a4a4a;
        }

        .vertical-menu-twitter-post-retweet {
          margin-left: 5px;
          margin-right: 5px;

          font-family: 'Open Sans', sans-serif;
          font-size: 12px;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: left;
          color: #4a4a4a;
        }

        .vertical-menu-twitter-image {
          max-width: 100%;
          width: auto;
          height: 60%;
          background-repeat: no-repeat;
          background-size: cover;

        }

      }

      .vertical-menu-mayHaveRetweet {

        .vertical-menu-hasRetweet {
          padding-left: 13%;
          height: 15px;
          line-height: 15px;
          vertical-align: middle;

          .vertical-menu-twitterRetweet {
            margin-left: 5px;

            font-family: 'Open Sans', sans-serif;
            opacity: 0.75;
            font-size: 8px;
            font-weight: 500;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: left;
            color: #7C7C7C;

          }

        }

      }

    }

  }

  .vertical-menu-see-more {
    display: flex;
    justify-content: center;
    align-items: center;
    button{
      border-radius: 25px;
      background-color: #71B9E1;
      color: #fff;
      padding: 7px 25px;
      border: none;
      cursor: pointer;
    }
  }
}
