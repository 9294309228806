.toaster-notification{
  position: fixed;
  bottom: 5px;
  right: 5px;
  //transform: translate(-50%, -50%);
  // display: flex;
  // justify-content: center;
  // align-items: center;
  .wrap{
    background-color: #616161;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    border-radius: 3px;
    cursor: default;
    &.warning{
      background-color: #FBC02D;
    }
    &.success{
      background-color: #2E7D32;
    }
    .toast-content{
      padding: 10px 20px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    p{
      margin: 0;
    }
  }
}

.toast-body{
  p{
    margin: 0;
  }
}
