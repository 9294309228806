.autolinker-link {
  &:hover {
    text-decoration: none; } }
.posts, .twitter {
  .card {
    padding: 20px;
    border: none;
    border-radius: 10px;
    .entity {
      display: flex;
      justify-content: space-between;
      align-items: center; }
    .contact-info {
      display: flex;
      align-items: center; }
    .contacts-wrapper {
      background-color: transparent;
      margin-left: 10px;
      p {
        margin: 0;
        padding: 0;
        color: #4a4a4a;
        font-size: 18px;
        font-weight: 600; }
      span {
        margin: 0;
        padding: 0;
        font-size: 14px;
        color: #4a4a4a; } }
    .retweet-content-wrapper {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      a {
        &:hover {
          text-decoration: none; } }
      .retweet-name-handle {
        display: flex;
        flex-direction: row;
        font-size: 14px;
        color: #4a4a4a;
        margin-bottom: 10px;
        align-items: center;
        padding: 0px 10px 0px 10px;
        i {
          margin-right: 5px; } }
      .retweet-message-handle {
        font-size: 16px;
        color: #4a4a4a;
        margin-bottom: 20px;
        padding: 0px 10px;
        p {
          margin: 0px; } }
      .retweet-message-retweeted {
        font-size: 16px;
        color: #4a4a4a;
        margin-bottom: 20px; }
      .retweeted-content-wrapper {
        box-shadow: 0px 0px 30px -5px rgba(0,0,0,0.43);
        border-radius: 5px;
        padding: 25px;
        margin-left: 15px;
        margin-right: 15px;
        .retweeted-content-header-wrapper {
          display: flex;
          align-items: center;
          padding: 5px 5px 15px 5px;
          margin-bottom: 10px;
          border-bottom: 1px solid rgba(85,172,238,0.35);
          .header-image {
            width: 40px;
            height: 40px;
            border-radius: 20px;
            cursor: pointer; }
          .header-handle-wrapper {
            padding-left: 10px;
            display: flex;
            flex: 1;
            flex-direction: column;
            .header-screen-name {
              width: max-content;
              margin: 0;
              font-weight: bold;
              cursor: pointer;
              font-size: 14px; }
            .header-handler {
              width: max-content;
              margin: 0;
              font-size: 12px;
              color: gray;
              cursor: pointer; } }
          .twitter-logo {
            color: #55acee;
            cursor: pointer; } } }

      .retweeted-link-handle {
        color: #4a4a4a;
        border-radius: 4px;
        align-items: center;
        width: 100%;
        .retweet-link-image {
          width: 100%;
          height: 200px;
          background-color: white;
          background-repeat: no-repeat;
          background-position: center;
          background-size: cover; }
        .retweet-link-preview-text-wrapper {
          width: 100%;
          padding-top: 20px;
          .retweet-link-title {
            width: 100%;
            word-wrap: break-word;
            font-size: 16px;
            color: #4a4a4a;
            font-weight: bold;
            padding-bottom: 15px;
            min-height: 26px; }
          .retweet-link-description {
            font-size: 16px;
            color: #4a4a4a;
            padding-bottom: 15px;
            min-height: 26px; }
          .retweet-link-url {
            font-size: 16px;
            color: #4a4a4a;
            padding-bottom: 15px;
            min-height: 26px; } } }
      .retweeted-image-handle {
        width: 100%;
        height: 300px; } }
    .footer-info {
      .card-footer {
        border-radius: 0 0 10px 10px;
        background-color: #ffffff;
        border: none; } }
    .card-block {
      &:not(.entity) {
        height: 100%; }
      &.entity {
        border: none;
        padding: 0px;
        margin-bottom: 20px;
        a {
          &:hover {
            text-decoration: none; } } } } } }

// @media screen and (min-width: 1201px)
//   .retweeted-link-handle
//     flex-direction: row
//     overflow: hidden
// @media screen and (max-width: $breakpoint-large-desktop)
//   .retweeted-link-handle
//     flex-direction: column
// @media screen and (max-width: $breakpoint-desktop)
//   .retweeted-link-handle
//     flex-direction: column
// @media screen and (max-width: $breakpoint-tablet)
//   .retweeted-link-handle
//     flex-direction: row
//     overflow: hidden
@media screen and (max-width: 768px) {
  .posts, .twitter {
    .card {
      padding: 10px; } } }
//   .retweeted-link-handle
//     flex-direction: column
