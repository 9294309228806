.inputWrapper{
  display: flex;
  max-width: 100%;
  vertical-align: top;
  padding: 0 5px;
  background-color: #D3D3D3;
  border-radius: 3px;
  justify-content: space-between;
  margin-top: 10px;
  .smallInputText{
    display: flex;
    text-align: left;
    padding: 0 5px;
    background-color: transparent;
    border-color: transparent;
    font-size: 0.850em;
    &:focus{
      outline: none;
    }
  }
  .smallInputTextWhiteText{
    display: flex;
    color: #FFFFFF;
    text-align: left;
    padding: 0 5px;
    background-color: transparent;
    border-color: transparent;
    font-size: 0.850em;
    &:focus{
      outline: none;
    }
  }
  .smallSubmitButton{
    border-color: transparent;
    background-color: transparent;
    text-align: right;
    font-weight: bold;
    font-size: 0.950em;
    color: #808080;
    &:focus{
      outline: none;
    }
  }
}
