.starting-component {
  .child-starter {
    padding: 0 25px; }
  p {
    margin: 0; }
  .color-wrap {
    padding: 10px;
    .color {
      color: #ffffff;
      display: flex;
      justify-content: center; } }
  .all-sizes-wrap {
    padding: 10px;
    display: flex;
    justify-content: center;
    color: #ffffff;
    flex-direction: column;
    .wrap {
      text-align: center; }
    .size-wrap {
      text-align: center;
      padding: 10px; } } }
