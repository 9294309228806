.adam-post-left{
  font-weight: 300;
  .row{
    h2{
      &.title{
        .title-link {
          &:link{
              color:inherit;
              text-decoration: none;
            }
          &:active{
              color:inherit;
              text-decoration: none;
            }
          &:visited{
              color:inherit;
              text-decoration: none;
            }
          &:hover{
              color:inherit;
              text-decoration: none;
            }
        }
      }
    }
    .text{
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      .article-button{
        margin: 10px;
      }
    }
    .image{
      display: flex;
      justify-content: center;
      align-items: center;
      .player-wrapper {
        width: 100%;
        position: relative;
        padding-top: 56.25%; // Player ratio: 100 / (1280 / 720)
        .react-player,
        .react-player__preview {
          position: absolute;
          top: 0;
          left: 0;
        }
      }
      @media all and (max-width: 768px) {
        margin-bottom: 30px;
      }
    }
  }
}
