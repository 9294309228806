.toolbox {
  &.board-wrap {
    height: 100%;
    background-color: #f5f5f7; } }
.toolbox-nav {
  height: 70px;
  .toolbox-menu-wrapper {
    display: flex;
    height: 100%;
    margin: 0;
    align-items: center;
    list-style: none;
    .toolbox-menu-items {
      padding: 0 20px;
      .toolbox-menu-entry {
        font-size: 16px;
        font-weight: 500;
        text-transform: uppercase;
        color: #000000;
        padding: 5px;
        &:hover, &:active, &:visited, &:active {
          text-decoration: none;
          color: #000000;
          border-bottom: 3px solid #2a9add; }
        &.active {
          font-size: 16px;
          font-weight: 500;
          text-transform: uppercase;
          color: #000000;
          padding: 5px;
          border-bottom: 3px solid #2a9add;
          &:hover, &:active, &:visited {
            text-decoration: none; } } } } } }

.managers-wrapper {
  font-family: 'Roboto', sans-serif;
  padding: 20px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  .managers-modal-actions {
    .modal-dialog {
      max-width: 700px; }
    .modal-content {
      background-color: #FFFFFF;
      color: #aaa;
      .modal-body {
        padding: 20px 30px;
        text-transform: uppercase;
        letter-spacing: 1px;
        font-size: 14px;
        .search-admin-btn-wrapper {
          background-color: #007bff;
          display: inline-block;
          padding: 5px 10px;
          border-radius: 4px;
          cursor: pointer;
          p {
            margin: 0;
            color: #FFF;
            font-weight: bold; } }
        .admins-search-result-table {
          padding-top: 15px;
          p {
            margin: 0;
            text-transform: lowercase; }
          .admin-person {
            display: flex;
            padding-top: 10px;
            padding-bottom: 10px;
            border-bottom: 1px solid #007bff;
            .admin-person-avatar {
              .avatar-image {
                width: 50px;
                height: 50px;
                border-radius: 25px; }
              .avatar-text {
                color: #000;
                width: 50px;
                height: 50px;
                border-radius: 25px;
                display: flex;
                align-items: center;
                justify-content: center; } }
            .admin-person-detail {
              display: flex;
              align-items: center;
              width: 100%;
              padding: 4px 10px; }
            .add-person-wrapper {
              width: 100%;
              display: flex;
              justify-content: flex-end;
              .add-admin-btn {
                cursor: pointer;
                padding: 5px;
                color: #FFF;
                background-color: #007bff;
                border-radius: 4px;
                text-transform: uppercase;
                font-weight: bold;
                margin: auto 0;
                &.added {
                  cursor: default; } } } } } }
      .modal-footer {
        //border-top: 1px solid #363636
        .action-btn {
          background-color: transparent;
          border: none;
          border-radius: 3px;
          color: grey;
          width: 100%;
          text-transform: capitalize;
          letter-spacing: 3px;
          font-size: 12px;
          padding: 5px; } } } }

  .managers-manager-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .managers-nav-text {
      font-family: 'Roboto', sans-serif;
      font-size: 13px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: left;
      color: #4a4a4a;
      margin-right: 15px;
      cursor: pointer; }
    .managers-nav-text-selected {
      font-family: 'Roboto', sans-serif;
      font-size: 13px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: left;
      color: #2a9add;
      margin-right: 15px;
      cursor: pointer; }
    .managers-manager-link {
      color: #4a4a4a;
      font-weight: 500;
      padding: 10px;
      text-transform: uppercase;
      font-size: 16px;
      i.fas, i.fab {
        margin-right: 5px;
        font-size: 18px; } } }

  .managers-actions-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 15px;
    padding-bottom: 10px;
    .managers-create-tag {
      background-color: #ffffff;
      padding: 10px;
      border-radius: 4px;
      box-shadow: 5px 5px #dfe2ed;
      cursor: pointer;
      span {
        color: #2a9add; } }
    .managers-search-wrapper {
      border-radius: 3px;
      background-color: #ffffff;
      border: solid 1px;
      padding: 0 10px;
      height: 1.7em;
      .managers-search {
        outline: none;
        background-color: transparent;
        border: none; } } }

  .managers-body {
    margin: 10px 0 0;
    height: 100vh;
    overflow-y: scroll;
    .social-login-wrapper {
      padding: 30px 15px 15px 15px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .facebook-social-login {
        margin-bottom: 10px;
        margin-top: 10px;
        width: 30%;
        .facebook-login {
          background-color: #3b5998;
          cursor: pointer;
          outline: none;
          margin: 0px;
          &.disabled {
            background-color: #cccccc;
            cursor: not-allowed;
            color: #666666; } } }
      .twitter-social-login {
        width: 30%;
        border-radius: 4px;
        margin-bottom: 10px;
        margin-top: 10px;
        .twitter-login {
          cursor: pointer;
          outline: none;
          background-color: #00aced;
          width: 100%;
          border: none;
          padding: 20px;
          margin: 0px;
          border-radius: 4px;
          text-transform: uppercase;
          text-align: center;
          letter-spacing: 3px;
          font-size: 12px;
          color: white;
          &.disabled {
            background-color: #cccccc;
            cursor: not-allowed;
            color: #666666; } } }
      .google-social-login {
        width: 30%;
        border-radius: 4px;
        margin-bottom: 10px;
        margin-top: 10px;
        .google-login {
          outline: none;
          background-color: #ffa700;
          width: 100%;
          border: none;
          padding: 20px;
          margin: 0px;
          border-radius: 4px;
          text-transform: uppercase;
          text-align: center;
          letter-spacing: 3px;
          font-size: 12px;
          color: white;
          &.disabled {
            background-color: #cccccc;
            cursor: not-allowed;
            color: #666666; } } } }
    ul.pagination {
      justify-content: flex-end;
      margin-top: 20px;
      li {
        a {
          cursor: pointer;
          border: 1px solid #e4eaec;
          color: #76838f;
          padding: 9px 15px;
          margin-left: -1px;
          background-color: #ffffff;
          transition: background .2s ease-out,border-color 0s ease-out,color .2s ease-out;
          &:hover {
            text-decoration: none;
            transition: background .2s ease-out,border-color .2s ease-out,color .2s ease-out;
            z-index: 2;
            color: #89bceb;
            background-color: #f3f7f9;
            border-color: #e4eaec; } }
        &.active a {
          background-color: #62a8ea;
          border-color: #62a8ea;
          color: #ffffff; }
        &.disabled {
          color: #ccd5db;
          cursor: not-allowed; } } }
    .admins-table-wrapper {
      .admins-header-wrapper {
        display: flex;
        p {
          color: #4a4a4a;
          font-size: 14px;
          font-weight: normal;
          border-collapse: separate;
          padding: 10px 25px;
          margin: 0;
          &:first-child {
            min-width: 100px; }
          &:not(:first-child) {
            width: 100%; } } }
      .admins-row-wrapper {
        background-color: #FFF;
        margin-bottom: 15px;
        border-radius: 10px;
        display: flex;
        align-items: center;
        box-shadow: 0px 15px 50px -3px rgba(0,0,0,0.41);
        &.delete {
          padding: 10px 25px;
          background-color: #232b2b;
          color: white;
          p {
            margin: 0;
            width: 100%; }
          .admins-delete-buttons-wrapper {
            display: flex;
            align-items: center;
            min-height: 50px;
            p {
              font-weight: bold;
              cursor: pointer;
              &:last-child {
                margin-right: 20px;
                margin-left: 10px; } } } }
        .admins-avatar-wrapper {
          min-width: 100px;
          padding: 10px 25px;
          .admins-avatar {
            width: 50px;
            height: 50px;
            border-radius: 50%; }
          .admins-avatar-text {
            width: 50px;
            height: 50px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center; } }
        .admins-name-wrapper {
          width: 100%;
          align-items: center;
          display: flex;
          padding: 10px 25px;
          p {
            margin: 0;
            display: inline-block; } }
        .admins-delete-wrapper {
          align-items: center;
          display: flex;
          padding: 10px 25px;
          i {
            cursor: pointer; } } } }
    .managers-table {
      width: 100%;
      .managers-lines-header {
        th {
          color: #4a4a4a;
          font-size: 14px;
          font-weight: normal;
          border-collapse: separate;
          padding: 10px 25px; } }
      .managers-lines-body {
        background-color: #ffffff;
        border: solid 1px #dfe2ed;
        border-collapse: separate;
        &:hover {
          background-color: rgba(42, 154, 221, 0.06);
          box-shadow: inset 2px -1px 0px 0px #2a9add, inset -1px 2px 0px 0px #2a9add;
          border-collapse: separate;
          // border: solid 1px #2a9add
 }          // z-index: 10
        td {
          padding: 10px 25px;
          border-bottom: solid 1px #dfe2ed;
          border-collapse: separate;
          &.name-cell {
            font-size: 12px;
            span {
              color: #FFFFFF;
              font-size: 14px; } }
          &.date-cell {
            font-size: 12px;
            color: #4a4a4a; }
          &.delete-confirm-cell {
            font-size: 14px;
            color: #FFFFFF;
            cursor: pointer; }
          &.delete-cell {
            &:hover {
              cursor: pointer; }
            .fas, .fab {
              font-size: 16px; } } } } } } }

.inputters-wrapper {
  font-family: 'Roboto', sans-serif;
  padding: 20px;
  max-height: 100vh;
  height: 100vh;
  display: flex;
  flex-direction: column;
  .inputters-manager-wrapper {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .inputters-manager-link {
      color: #4a4a4a;
      font-weight: 500;
      padding: 10px;
      text-transform: uppercase;
      font-size: 16px;
      i.fas, i.fab {
        margin-right: 5px;
        font-size: 18px; } } }

  .inputters-actions-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .add-source-wrapper {
      .btn-wrapper {
        color: #4a4a4a;
        text-transform: uppercase;
        background-color: transparent;
        font-size: 18px;
        border: none;
        &:disabled {
          color: #d3d3d3;
          text-transform: uppercase;
          background-color: transparent;
          font-size: 18px;
          border: none; } }
      .add-source-modal {
        .modal-dialog {
          max-width: 80%;
          .modal-content {
            .modal-footer {
              z-index: 20;
              background-color: #ffffff; }
            .modal-body {
              background-color: #f5f5f7;
              border-top-left-radius: 4px;
              border-top-right-radius: 4px;
              max-height: 80vh;
              overflow-y: auto;
              .source-selector {
                color: #4a4a4a;
                .source-type-title {
                  font-size: 18px;
                  font-weight: 500; }
                .source-type-subtitle {
                  font-size: 16px; }
                .source-all-services {
                  margin: 0;
                  .source-type-wrap {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                    p.source-type {
                      margin-top: 10px;
                      text-align: center;
                      font-size: 14px; }
                    .source-type-square {
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      width: 90%;
                      height: 90%;
                      padding: 20px;
                      box-shadow: 4px 4px #dfe2ed;
                      background-color: #fff;
                      border-radius: 4px;
                      font-size: 14px;
                      .fas, .fab {
                        color: #333333;
                        font-size: 80px; } } } } }

              .add-type-source {
                .row {
                  [class*=col] {
                    margin: 0;
                    padding-left: 0;
                    padding-right: 0;
                    margin: 0; }
                  .twitter-search-select-wrapper {
                    display: flex;
                    p {
                      margin: 0; }
                    .twitter-language-select,
                    .twitter-radius-select,
                    .twitter-result-type-select,
                    .location-select {
                      margin: 10px;
                      .location-input {
                        width: 240px; }
                      .radius-wrapper {
                        select {
                          margin-left: 10px; } }
                      .radius-error {
                        font-weight: bold;
                        color: red;
                        font-size: 14px; } } }
                  .input-wrapper {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    .input-space {
                      width: 100%;
                      textarea, input {
                        color: #aeafbd;
                        border: 1px solid #dfe2ed;
                        border-radius: 4px;
                        width: 100%;
                        font-size: 16px;
                        padding: 10px 25px;
                        outline: none; } }
                    .input-action {
                      button.submit-source {
                        width: 40px;
                        height: 40px;
                        background-color: #2a9add;
                        border-radius: 25px;
                        color: #ffffff;
                        border: none;
                        margin-left: 10px;
                        outline: none;
                        font-size: 25px; } } }

                  .type-source-description {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    .title {
                      font-size: 18px;
                      font-weight: 500; }
                    .description {
                      font-size: 16px;
                      font-weight: 500;
                      line-height: normal;
                      margin-top: 10px; } }

                  .source-type-square {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 90%;
                    height: 90%;
                    padding: 20px;
                    box-shadow: 4px 4px #dfe2ed;
                    background-color: #fff;
                    border-radius: 4px;
                    .fas, .fab {
                      color: #333333;
                      font-size: 80px; } }

                  .loaded-sources {
                    .detail-level {
                      display: flex;
                      justify-content: flex-end;
                      flex-direction: row;
                      margin-top: 15px;
                      .level-type {
                        cursor: pointer;
                        background-color: transparent;
                        border: none;
                        color: #adafbd;
                        margin-right: 10px;
                        outline: none;
                        font-weight: 500;
                        font-size: 12px;
                        text-transform: uppercase;
                        &.selected {
                          color: #2a9add;
                          border-bottom: 2px solid #2a9add; } } }

                    .loaded-sources-table {
                      width: 100%;
                      overflow-y: auto;
                      overflow-x: hidden;
                      max-height: 45vh;
                      padding-right: 5px;
                      .source-table-rss-warning {
                        width: 100%;
                        margin-top: 10px;
                        .line-header td {
                          font-size: 16px;
                          font-weight: 500;
                          font-style: normal;
                          font-stretch: normal;
                          line-height: normal;
                          letter-spacing: normal;
                          padding: 0 10px;
                          color: #4a4a4a;
                          .logo td {
                            width: 50px; } } }
                      .source-list-table {
                        width: 100%;
                        margin-top: 10px;
                        table-layout: fixed;

                        .line-header td {
                          font-size: 14px;
                          font-weight: 500;
                          color: #4a4a4a;
                          padding: 0 10px; }
                        .spacer td {
                          padding: 5px; }
                        .garbadge {
                          text-align: right;
                          cursor: pointer; }
                        .name {
                          white-space: nowrap; }
                        .back-drop {
                          padding: 10px 10px;
                          background-color: #ffffff;
                          border-top: 1px solid #dfe2ed;
                          border-bottom: 1px solid #dfe2ed;
                          &:last-of-type {
                            border-right: 1px solid #dfe2ed;
                            border-top-right-radius: 4px;
                            border-bottom-right-radius: 4px; }
                          &:nth-of-type(2) {
                            border-left: 1px solid #dfe2ed;
                            border-top-left-radius: 4px;
                            border-bottom-left-radius: 4px; } }
                        .rss-cell-wrapper {
                          display: flex;
                          flex-direction: row;
                          flex: 1;
                          background-color: #FFFFFF;
                          height: 80px;
                          padding-top: 5px;
                          padding-bottom: 5px;
                          .rss-cell-icon {
                            display: flex;
                            flex: 0.1;
                            flex-direction: column;
                            align-items: center;
                            justify-content: center; }
                          .rss-cell-name {
                            display: flex;
                            text-align: center;
                            justify-content: center;
                            align-items: center;
                            flex: 0.3;
                            padding: 5px; }
                          .rss-cell-description {
                            display: flex;
                            align-items: center;
                            flex: 0.7;
                            padding: 5px; }
                          .rss-cell-address {
                            display: flex;
                            align-items: center;
                            flex: 0.5;
                            padding: 5px; }
                          .rss-cell-checker {
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            justify-content: flex-end;
                            flex: 0.1;
                            padding: 5px; } }
                        .logo-rss {
                          text-align: center;
                          align-items: center;
                          justify-content: center; }
                        .name-rss {
                          text-align: left; }
                        .name-rss-warning {
                          font-weight: 500;
                          white-space: nowrap; }
                        .description-rss {
                          text-align: left; }
                        .link-rss-wrapper {
                          text-align: left; }
                        .checker-rss {
                          text-align: right;
                          padding: 10px 10px; }
                        .no-feeds {
                          background-color: #EC644B;
                          white-space: nowrap;
                          color: #FFFFFF;
                          font-weight: 500; }
                        .back-drop-no-border {
                          padding: 10px 10px;
                          background-color: #ffffff; }
                        .delete-prop {
                          background-color: #4a4a4a;
                          color: #ffffff; }
                        .logo {
                          width: 50px; }
                        .feeds {
                          .feed-count-wrapper {
                            width: 100%;
                            height: 100%;
                            text-align: center;
                            .feed-count {
                              padding-top: 2px;
                              padding-bottom: 2px;
                              padding-right: 4px;
                              padding-left: 4px;
                              display: inline-block;
                              background-color: #eff1f5;
                              text-align: center;
                              border-radius: 8.5px; }
                            .error {
                              border: 1px solid #e22a2a; } } }
                        .checker {
                          color: $fluxio-blue;
                          cursor: pointer;
                          text-align: right; }
                        .name-rss {
                          font-size: 16px;
                          font-weight: 500;
                          font-style: normal;
                          font-stretch: normal;
                          line-height: normal;
                          letter-spacing: normal;
                          color: #4a4a4a; }
                        .letter-circle {
                          border-radius: 25px;
                          width: 35px;
                          height: 35px;
                          font-weight: bold;
                          text-transform: uppercase;
                          color: #ffffff;
                          text-align: center;
                          line-height: 35px; }
                        .letter-circle-wrapper-rss {
                          display: flex;
                          align-items: center;
                          justify-content: center;
                          .letter-circle-rss {
                            border-radius: 25px;
                            width: 35px;
                            height: 35px;
                            font-weight: bold;
                            text-transform: uppercase;
                            color: #ffffff;
                            text-align: center;
                            line-height: 35px; } }
                        .faded {
                          font-size: 14px;
                          color: #9b9b9b; }
                        .error-cell-rss {
                          padding: 10px 10px;
                          background-color: #ffffff;
                          border-top: 1px solid #e22a2a;
                          border-bottom: 1px solid #e22a2a;
                          border-radius: 4px;
                          font-weight: 500;
                          &:last-of-type {
                            border-right: 1px solid #e22a2a;
                            border-top-right-radius: 4px;
                            border-bottom-right-radius: 4px;
                            color: #000000; }
                          &:nth-of-type(2) {
                            border-left: 1px solid #e22a2a;
                            border-top-left-radius: 4px;
                            border-bottom-left-radius: 4px;
                            font-size: 12px; } }
                        .error-cell {
                          padding: 10px 10px;
                          background-color: #ffffff;
                          border-top: 1px solid #e22a2a;
                          border-bottom: 1px solid #e22a2a;
                          border-radius: 4px;
                          font-weight: 500;
                          color: #a91628;
                          &:last-of-type {
                            border-right: 1px solid #e22a2a;
                            border-top-right-radius: 4px;
                            border-bottom-right-radius: 4px;
                            color: #000000; }
                          &:nth-of-type(2) {
                            border-left: 1px solid #e22a2a;
                            border-top-left-radius: 4px;
                            border-bottom-left-radius: 4px;
                            font-size: 12px; } }
                        .error-cell-top {
                          padding: 10px 10px;
                          background-color: #ffffff;
                          border-top: 1px solid #e22a2a;
                          border-radius: 4px;
                          font-weight: 500;
                          &:last-of-type {
                            border-right: 1px solid #e22a2a;
                            border-top-right-radius: 4px;
                            border-bottom-right-radius: 4px; }
                          &:nth-of-type(2) {
                            border-left: 1px solid #e22a2a;
                            border-top-left-radius: 4px;
                            border-bottom-left-radius: 4px;
                            font-size: 12px; } }
                        .error-cell-bottom {
                          padding: 10px 10px;
                          background-color: #ffffff;
                          border-bottom: 1px solid #e22a2a;
                          border-radius: 4px;
                          font-weight: 500;
                          color: #a91628;
                          &:last-of-type {
                            border-right: 1px solid #e22a2a;
                            border-top-right-radius: 4px;
                            border-bottom-right-radius: 4px; }
                          &:nth-of-type(2) {
                            border-left: 1px solid #e22a2a;
                            border-top-left-radius: 4px;
                            border-bottom-left-radius: 4px;
                            font-size: 12px; } } } } } } } } } } }

      button.inputters-add-source {
        font-family: 'Roboto', sans-serif;
        font-size: 14px;
        color: #2a9add;
        background-color: #fff;
        border: none;
        padding: 15px;
        border-radius: 4px;
        box-shadow: 4px 4px #dfe2ed;
        text-transform: uppercase;
        font-weight: 500;
        i.fas, i.fab {
          margin-right: 5px;
          font-size: 16px; } } }
    .inputters-search-wrapper {
      border-radius: 3px;
      background-color: #ffffff;
      border: solid 1px;
      padding: 0 10px;
      .inputters-search {
        outline: none;
        background-color: none;
        border: none; } } }

  .inputters-body {
    margin: 10px 0 0;
    height: 100vh;
    overflow-y: scroll;
    .inputters-table {
      //table-layout: fixed
      width: 100%;
      .inputters-lines-header {
        th {
          color: #4a4a4a;
          font-size: 14px;
          font-weight: normal;
          text-align: center;
          border-collapse: separate;
          padding: 10px 25px; } }
      .inputters-lines-body {
        background-color: #ffffff;
        border: solid 1px #dfe2ed;
        border-collapse: separate;
        &:hover {
          background-color: rgba(42, 154, 221, 0.06);
          box-shadow: inset 2px -1px 0px 0px #2a9add, inset -1px 2px 0px 0px #2a9add;
          border-collapse: separate;
          cursor: pointer;
          // border: solid 1px #2a9add
 }          // z-index: 10
        td {
          padding: 10px 0px;
          border-bottom: solid 1px #dfe2ed;
          border-collapse: separate;
          &.services-cell {
            color: #333333;
            font-size: 10px;
            text-align: center;
            width: 50px;
            max-width: 50px;
            min-width: 50px;
            .fas, .fab {
              font-size: 16px; } }
          &.avatar-cell {
            color: #ffffff;
            text-align: center;
            width: 50px;
            max-width: 50px;
            min-width: 50px;
            .letter-circle-wrapper {
              display: flex;
              align-items: center;
              justify-content: center;
              .letter-circle {
                border-radius: 25px;
                width: 35px;
                height: 35px;
                font-weight: bold;
                text-transform: uppercase;
                color: #ffffff;
                text-align: center;
                line-height: 35px; } } }
          &.name-cell {
            padding-left: 5px;
            padding-right: 5px;
            font-size: 14px;
            text-align: left;
            width: 310px;
            max-width: 310px;
            min-width: 310px;
            word-wrap: break-word; }
          &.address-cell {
            font-size: 12px;
            text-align: left;
            padding-left: 5px;
            padding-right: 5px;
            width: 260px;
            max-width: 260px;
            min-width: 260px;
            word-wrap: break-word;
            .feed-count-wrapper {
              background-color: transparent;
              display: flex;
              justify-content: space-between;
              .feed-count {
                font-family: 'Poppins';
                display: inline-block;
                padding: 2px 10px;
                border-radius: 8.5px;
                background-color: #eff1f5;
                font-size: 14px;
                font-weight: normal;
                font-style: normal;
                font-stretch: normal;
                line-height: normal;
                letter-spacing: normal;
                color: #27272d; } } }
          &.description-cell {
            font-size: 14px;
            color: #4a4a4a;
            text-align: left;
            padding-left: 5px;
            padding-right: 5px;
            width: 310px;
            max-width: 310px;
            min-width: 310px;
            word-wrap: break-word; }
          &.date-cell {
            font-size: 12px;
            color: #4a4a4a;
            text-align: center;
            width: 100px;
            max-width: 100px;
            min-width: 100px; }
          &.delete-cell {
            text-align: center;
            width: 100px;
            min-width: 100px;
            max-width: 100px; } } }
      .detail-spacer {
        height: 15px; }
      .cluster-source-cell {
        box-shadow: 0px 18px 30px rgba(0, 0, 0, 0.5);
        .cell-cluster {
          background-color: #ffffff;
          .cell-cluster-wrapper {
            width: 100%;
            height: 100%;
            overflow: auto;
            padding-top: 5px;
            padding-left: 5px;
            padding-right: 5px;
            .cluster-sub-feed-wrapper-delete {
              width: 100%;
              height: 60px;
              padding: 5px 60px 5px 60px;
              display: flex;
              flex-direction: row;
              background-color: #4a4a4a;
              border-radius: 4px;
              border: solid 1px #dfe2ed;
              margin-bottom: 5px;
              .cluster-sub-feed-title-delete {
                width: 100%;
                color: #FFFFFF;
                display: flex;
                align-items: center; }
              .cluster-sub-feed-title-options {
                width: 100%;
                color: #FFFFFF;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: flex-end;
                .cluster-sub-feed-title-options-yes {
                  cursor: pointer;
                  margin-right: 15px; }
                .cluster-sub-feed-title-options-cancel {
                  cursor: pointer; } } }
            .cluster-sub-feed-wrapper {
              width: 100%;
              height: 60px;
              padding: 5px 0px 5px 60px;
              display: flex;
              flex-direction: row;
              border-radius: 4px;
              border: solid 1px #dfe2ed;
              margin-bottom: 5px;
              .cluster-sub-feed-image {
                display: flex;
                flex: 0.2;
                align-items: center;
                margin-right: 10px;
                font-size: 14px;
                font-weight: normal;
                font-style: normal;
                font-stretch: normal;
                line-height: normal;
                letter-spacing: normal;
                color: #4a4a4a; }
              .cluster-sub-feed-title {
                display: flex;
                flex: 0.7;
                align-items: center;
                margin-right: 10px;
                font-size: 14px;
                font-weight: normal;
                font-style: normal;
                font-stretch: normal;
                line-height: normal;
                letter-spacing: normal;
                color: #4a4a4a; }
              .cluster-sub-feed-description {
                display: flex;
                flex: 2;
                align-items: center;
                margin-right: 10px;
                font-size: 14px;
                font-weight: normal;
                font-style: normal;
                font-stretch: normal;
                line-height: normal;
                letter-spacing: normal;
                color: #4a4a4a; }
              .cluster-sub-feed-url {
                display: flex;
                flex: 2;
                align-items: center;
                margin-right: 10px;
                font-size: 14px;
                font-weight: normal;
                font-style: normal;
                font-stretch: normal;
                line-height: normal;
                letter-spacing: normal;
                color: #4a4a4a; }
              .cluster-sub-feed-delete {
                display: flex;
                flex: 1;
                align-items: center;
                justify-content: flex-end;
                color: #adafbd;
                text-align: right;
                padding-right: 10px; } } } }
        .toDelete {
          background-color: #4a4a4a;
          color: #ffffff;
          font-size: 14px;
          font-weight: 500;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal; } }
      .inputters-lines-body-detail {
        box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.5);
        background-color: #ffffff;
        border: solid 1px #dfe2ed;
        border-collapse: separate;
        &:hover {
          cursor: pointer; }
        //height: 160px
        td {
          padding: 10px 25px;
          border-bottom: solid 1px #dfe2ed;
          border-collapse: separate;
          &.services-cell-detail {
            color: #333333;
            font-size: 10px;
            text-align: center;
            width: 100px;
            max-width: 100px;
            min-width: 100px; }
          &.avatar-cell-detail {
            color: #ffffff;
            text-align: center;
            width: 100px;
            max-width: 100px;
            min-width: 100px; }
          &.name-cell-detail {
            padding-left: 5px;
            padding-right: 5px;
            font-size: 14px;
            text-align: left;
            width: 310px;
            max-width: 310px;
            min-width: 310px;
            word-wrap: break-word; }
          &.address-cell-detail {
            font-size: 12px;
            text-align: left;
            padding-left: 5px;
            padding-right: 5px;
            width: 260px;
            max-width: 260px;
            min-width: 260px;
            word-wrap: break-word;
            .feed-count-wrapper {
              background-color: transparent;
              .feed-count {
                font-family: 'Poppins';
                display: inline-block;
                padding: 2px 10px;
                border-radius: 8.5px;
                background-color: #eff1f5;
                font-size: 14px;
                font-weight: normal;
                font-style: normal;
                font-stretch: normal;
                line-height: normal;
                letter-spacing: normal;
                color: #27272d; } } }
          &.description-cell-detail {
            font-size: 14px;
            color: #4a4a4a;
            text-align: left;
            padding-left: 5px;
            padding-right: 5px;
            width: 310px;
            max-width: 310px;
            min-width: 310px;
            word-wrap: break-word; }
          &.date-cell-detail {
            font-size: 12px;
            color: #4a4a4a;
            text-align: center;
            width: 100px;
            max-width: 100px;
            min-width: 100px; }
          &.delete-cell-detail {
            text-align: center;
            width: 100px;
            min-width: 100px;
            max-width: 100px; } } }
      .inputters-lines-body-delete {
        background-color: #1e1e1e;
        border: solid 1px #1e1e1e;
        border-collapse: separate;
        td {
          padding: 10px 25px;
          border-bottom: solid 1px #dfe2ed;
          border-collapse: separate;
          &.services-cell {
            color: #FFFFFF;
            font-size: 14px; }
          &.avatar-cell {
            color: #ffffff; }
          &.name-cell-delete {
            color: #ffffff;
            font-size: 14px; }
          &.address-cell {
            font-size: 14px;
            color: #FFFFFF;
            text-align: center; }
          &.description-cell {
            font-size: 14px;
            color: #FFFFFF;
            text-align: center; }
          &.date-cell-delete {
            font-size: 14px;
            color: #FFFFFF;
            margin-right: 10px; } } }

      .letter-circle {
        border-radius: 25px;
        width: 35px;
        height: 35px;
        font-weight: bold;
        text-transform: uppercase;
        color: #ffffff;
        text-align: center;
        line-height: 35px; } }

    ul.pagination {
      justify-content: flex-end;
      margin-top: 20px;
      li {
        a {
          cursor: pointer;
          border: 1px solid #e4eaec;
          color: #76838f;
          padding: 9px 15px;
          margin-left: -1px;
          background-color: #ffffff;
          transition: background .2s ease-out,border-color 0s ease-out,color .2s ease-out;
          &:hover {
            text-decoration: none;
            transition: background .2s ease-out,border-color .2s ease-out,color .2s ease-out;
            z-index: 2;
            color: #89bceb;
            background-color: #f3f7f9;
            border-color: #e4eaec; } }
        &.active a {
          background-color: #62a8ea;
          border-color: #62a8ea;
          color: #ffffff; }
        &.disabled {
          color: #ccd5db;
          cursor: not-allowed; } } } } }

.outputters-wrapper {
  padding: 20px;
  .title {
    margin: 20px 0;
    font-size: 16px;
    color: #4a4a4a;
    font-weight: 600;
    text-transform: uppercase; }
  input:not(button) &input:not([type='color']) {
    border-radius: 5px;
    border: 2px solid #eff1f5;
    margin-bottom: 10px;
    outline: none;
    margin: 0;
    text-align: left;
    padding: 3px 0 3px 6px;
    color: #9b9b9b;
    &.error {
      border-color: red; } }
  select {
    border: 2px solid #eff1f5;
    border-radius: 5px;
    height: 35px;
    color: #888888;
    background: #fff;
    &.error {
      border-color: red; }
    &:disabled {
      background: #eff1f5;
      color: transparent; } }
  ul.pagination {
    justify-content: flex-end;
    margin-top: 20px;
    li {
      a {
        cursor: pointer;
        border: 1px solid #e4eaec;
        color: #76838f;
        padding: 9px 15px;
        margin-left: -1px;
        background-color: #ffffff;
        transition: background .2s ease-out,border-color 0s ease-out,color .2s ease-out;
        &:hover {
          text-decoration: none;
          transition: background .2s ease-out,border-color .2s ease-out,color .2s ease-out;
          z-index: 2;
          color: #89bceb;
          background-color: #f3f7f9;
          border-color: #e4eaec; } }
      &.active a {
        background-color: #62a8ea;
        border-color: #62a8ea;
        color: #ffffff; }
      &.disabled {
        color: #ccd5db;
        cursor: not-allowed; } } }
  .outputters-nav-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .outputters-nav-text {
      font-family: 'Roboto', sans-serif;
      font-size: 13px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: left;
      color: #4a4a4a;
      margin-right: 15px;
      cursor: pointer; }
    .outputters-nav-text-selected {
      font-family: 'Roboto', sans-serif;
      font-size: 13px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: left;
      color: #2a9add;
      margin-right: 15px;
      cursor: pointer; }
    .outputters-nav-link {
      color: #4a4a4a;
      font-weight: 500;
      padding: 10px;
      text-transform: uppercase;
      font-size: 16px;
      i.fas, i.fab {
        margin-right: 5px;
        font-size: 18px; } } }
  .modal-create-host {
    .modal-content {
      border: none;
      background-color: white;
      .modal-body {
        padding: 20px;
        border: none;
        .host-create-modal-actions {
          display: flex;
          justify-content: space-between;
          flex-direction: row;
          button {
            border-radius: 3px;
            outline: none;
            cursor: pointer;
            margin: 0 5px;
            border: none;
            width: 100%;
            padding: 10px 0;
            &.cancel {
              background-color: #d3d6dc;
              font-size: 14px;
              font-weight: bold;
              color: #ffffff; }
            &.apply {
              background-color: $fluxio-blue;
              font-size: 14px;
              font-weight: bold;
              color: #ffffff; } } }
        .modalTitle {
          text-transform: uppercase;
          margin: 0px;
          font-size: 16px;
          color: #4a4a4a; }
        .additional-options {
          width: 100%;
          display: flex;
          margin: 10px 0;
          justify-content: space-around; }
        .group-form-selection {
          border-radius: 5px;
          display: flex;
          justify-content: center;
          align-items: flex-start;
          flex-direction: column;
          padding: 20px 0;
          .group-input-default {
            width: 100%;
            input,
            select {
              width: 100%; } }
          .group-input-name {
            width: 100%;
            .group-suffix {
              .content-domain {
                width: 100%; } } }
          .group-input-domain {
            width: 100%;
            .group-suffix {
              display: flex;
              flex-direction: row;
              .content-domain {
                width: 100%; } } }
          .group-input {
            padding: 10px 6px;
            width: 100%;
            transition: all .3s ease;
            &.not-grow {
              height: 32px; }
            &.grow-custom {
              background-color: white; }
            .table-txt-record {
              width: 100%;
              margin-top: 20px;
              .table-txt-record-head {
                tr {
                  border: 2px solid #eff1f5;
                  background-color: #4fcfff;
                  th {
                    text-align: center;
                    font-size: 12px;
                    color: #767a81;
                    width: 50%;
                    padding: 5px; } } }
              .table-txt-record-body {
                tr {
                  border: 2px solid #eff1f5;
                  td {
                    text-align: center;
                    font-size: 12px;
                    width: 50%;
                    padding: 5px; } } } }
            .group-form {
              display: flex;
              justify-content: center;
              align-items: flex-start;
              flex-direction: column;
              padding-top: 10px;
              .theme-color-create {
                margin: 0 5px 0 0;
                .cookie-switch {
                  margin-left: 10px; }
                .theme-color-create-label {
                  font-size: 9px;
                  font-weight: 600;
                  color: #767a81;
                  text-transform: uppercase;
                  margin: 0; } }
              .cookie-warning-create {
                margin: 0 5px 0 0;
                .cookie-switch {
                  margin-left: 10px; }
                .cookie-warning-create-label {
                  font-size: 9px;
                  font-weight: 600;
                  color: #767a81;
                  text-transform: uppercase;
                  margin: 0; } }
              .name {
                font-size: 9px;
                font-weight: 600;
                color: #767a81;
                text-transform: uppercase;
                margin: 0;
                &.domain-suffix-name {
                  margin-top: 10px; }
                &.error {
                  color: red; } }
              input.content {
                border-radius: 5px;
                border: 2px solid #eff1f5;
                margin-bottom: 10px;
                width: 100%; }
              input.content-domain {
                border-radius: 5px;
                border: 2px solid #eff1f5;
                margin-bottom: 10px;
                &.suffix {
                  border-top: 2px solid #eff1f5;
                  border-bottom: 2px solid #eff1f5;
                  border-left: 2px solid #eff1f5;
                  border-right: none;
                  border-top-left-radius: 5px;
                  border-bottom-left-radius: 5px;
                  border-top-right-radius: 0px;
                  border-bottom-right-radius: 0px;
                  outline: none;
                  margin: 0;
                  text-align: right;
                  padding: 3px 0 3px 6px;
                  color: #9b9b9b; }
                &.suffix-name {
                  border: 2px solid #eff1f5;
                  border-radius: 5px;
                  outline: none;
                  margin: 0;
                  text-align: left;
                  padding: 3px 0 3px 6px;
                  color: #9b9b9b; }
                &.error {
                  border-color: red; } }
              .name-suffix {
                margin: 0;
                border-top: 2px solid #eff1f5;
                border-bottom: 2px solid #eff1f5;
                border-right: 2px solid #eff1f5;
                border-left: none;
                border-top-left-radius: 0px;
                border-bottom-left-radius: 0px;
                border-top-right-radius: 5px;
                border-bottom-right-radius: 5px;
                padding: 3px 6px 3px 0;
                color: #27272d;
                &.error {
                  border-color: red; } } } } }
        .loading-form {
          display: flex;
          width: 100%;
          justify-content: center;
          align-items: center; } } } }
  .add-domain,
  .add-audience {
    background-color: $fluxio-blue;
    color: #ffffff;
    padding: 15px;
    border: none;
    font-weight: 600;
    font-size: 14px;
    outline: none; }
  .domain-table-wrap,
  .audience-table-wrap {
    height: calc(100vh - 245px);
    overflow-y: auto;
    .table-header-wrapper {
      display: flex;
      div {
        display: flex;
        align-items: center;
        padding: 10px;
        text-transform: lowercase;
        p {
          margin: 0;
          color: #4a4a4a;
          font-size: 14px;
          font-weight: normal; }
        &.first-column,
        &.second-column,
        &.third-column,
        &.fourth-column,
        &.fifth-column,
        &.sixth-column {
          width: 100%; }
        &.last-column {
          width: 4%; } } } } }

.detailed-source {
  position: absolute;
  background-color: #ffffff;
  color: #4a4a4a;
  width: 100%;
  padding: 10px;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.5);
  .detailed-content {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    .avatar {
      padding: 0 10px 0 0;
      img {
        width: 60px !important;
        height: 60px !important;
        border-radius: 50% !important;
        .letter-circle {
          width: 60px;
          height: 60px;
          border-radius: 50%;
          text-align: center;
          line-height: 60px;
          color: #ffffff;
          font-size: 25px; } }
      .description, .name {
        padding: 0 10px 0 10px; }
      .options {
        display: flex;
        flex-direction: column;
        z-index: 20; } } } }
