.experience-manager,
.profile-dashboard {
  font-family: 'Nunito Sans', sans-serif;
  height: 100%;
  .experience-manager-navbar {
    &.experience-manager-navbar-object-template {
      background: $fluxio-green-60;
      .tab, .btn {
        &:hover,
        &.selected {
          background: $fluxio-green; } } } } }

.single-object {
  .single-object-content {
    &.single-object-symbol {
      background: $fluxio-green-60;
      &.single-object-selected {
        background: $fluxio-green; } } } }
