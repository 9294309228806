.voting-wrap{
  display: flex;
  flex-direction: row;
  background-color: transparent;
  align-items: center;
  justify-content: center;
  width: 65px;
  i{
    &.fas, &.fab{
      margin: 5px;
      font-size: 25px;
    }
    &.thumbsBlue{
      color: #0099ff;
    }
    &.thumbsGrey{
      color: #D4D4D4;
    }
    &.colorMaster{
      border: none;
    }
  }
}
