.global-style {
  .preview {
    background-color: #F2F2F2;
    padding: 50px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    &::after {
      content: 'preview';
      position: absolute;
      bottom: 0;
      right: 0;
      text-transform: uppercase;
      font-size: 10px;
      font-weight: bold;
      color: #b1b1b1;
      letter-spacing: 1px; }
    img {
      max-width: 100%; }
    i {
      position: absolute; } } }
.global-style-selector {
  background-color: #f2f2f2;
  padding: 8px;
  border-radius: 3px;
  font-size: 12px;
  font-weight: 600;
  color: #868686;
  hr {
    margin: 5px 0 10px; }
  .global-style-selected {
    font-size: 11px; }
  //TEMP FIX UNTIL FLUXIO-FORM IS THE NORM
  .fluxio-form {
    .form-group {
      &.row {
        margin-right: 0;
        margin-left: 0; }
      .input-group {
        .input-group-append {
          padding: 0;
          .input-group-text {
            border: none;
            height: 100%;
            .fas {
              font-size: 12px; } } } }
      .col-sm-10 {
        justify-content: flex-end; }
      label {
        font-size: 11px; } } } }
