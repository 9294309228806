$MediaRadius: 5px;

@mixin fade-animation-off {
  visibility: hidden;
  transition: visibility 0s, opacity 0.3s linear;
  opacity: 0; }
@mixin fade-animation-on {
  visibility: visible;
  opacity: 1; }

.media-library {
  .drop-zone {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    .drop-info {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 200px;
      height: 100px;
      color: #fff;
      border: 5px dashed #fff;
      border-radius: 5px; } }
  .preview-multiple-media {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-bottom: 20px;
    img {
      width: 100%;
      height: 180px;
      object-fit: cover; } }
  .preview-media {
    position: relative;
    height: 180px;
    .cover {
      position: absolute;
      width: 50%;
      height: 50%;
      right: 0;
      bottom: 0;
      transition: opacity 1s ease-in-out;
      &:hover {
        opacity: 0.1; } }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 5px; }
    .loading-media {
      font-size: 50px;
      color: #fff;
      background-color: rgba(0, 0, 0, 0.5);
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      display: flex;
      justify-content: center;
      align-items: center; }
    .info-media {
      font-size: 14px;
      color: #fff;
      background-color: rgba(0, 0, 0, 0.5);
      position: absolute;
      bottom: 0;
      right: 0;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-end;
      padding: 5px; } }
  .media-card {
    margin-bottom: 10px;
    border-radius: $MediaRadius;
    position: relative;
    height: 150px;
    background: -webkit-linear-gradient(45deg, rgba(0, 0, 0, 0.0980392) 25%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.0980392) 75%, rgba(0, 0, 0, 0.0980392) 0), -webkit-linear-gradient(45deg, rgba(0, 0, 0, 0.0980392) 25%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.0980392) 75%, rgba(0, 0, 0, 0.0980392) 0), white;
    background: -moz-linear-gradient(45deg, rgba(0, 0, 0, 0.0980392) 25%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.0980392) 75%, rgba(0, 0, 0, 0.0980392) 0), -moz-linear-gradient(45deg, rgba(0, 0, 0, 0.0980392) 25%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.0980392) 75%, rgba(0, 0, 0, 0.0980392) 0), white;
    background: linear-gradient(45deg, rgba(0, 0, 0, 0.0980392) 25%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.0980392) 75%, rgba(0, 0, 0, 0.0980392) 0), linear-gradient(45deg, rgba(0, 0, 0, 0.0980392) 25%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.0980392) 75%, rgba(0, 0, 0, 0.0980392) 0), white;
    background-repeat: repeat, repeat;
    background-position: 0px 0, 5px 5px;
    -webkit-transform-origin: 0 0 0;
    transform-origin: 0 0 0;
    -webkit-background-origin: padding-box, padding-box;
    background-origin: padding-box, padding-box;
    -webkit-background-clip: border-box, border-box;
    background-clip: border-box, border-box;
    -webkit-background-size: 10px 10px, 10px 10px;
    background-size: 10px 10px, 10px 10px;
    -webkit-box-shadow: none;
    box-shadow: none;
    text-shadow: none;
    -webkit-transition: none;
    -moz-transition: none;
    -o-transition: none;
    transition: none;
    -webkit-transform: scaleX(1) scaleY(1) scaleZ(1);
    transform: scaleX(1) scaleY(1) scaleZ(1);
    &.small {
      height: 150px;
      margin-right: 10px; }
    &.big {
      height: 200px;
      margin-right: 10px; } //needs custom-gutter-right at row level
    img {
      border-radius: $MediaRadius;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: top;
      transition: object-position 1s ease-in-out,transform .2s ease;
      &:hover {
        transform: translateY(-4px);
        object-position: 100%;
        transition: object-position 5s ease-in-out,transform .2s ease; } }
    .media-card-body {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      top: 0;
      background-color: rgba(0, 0, 0, 0.3);
      border-radius: $MediaRadius;
      padding: 10px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      &.page-selected {
        border: 2px solid #4253FC; }
      .media-title {
        font-size: 12px;
        font-weight: 600;
        color: #fff; }
      .media-name {
        font-size: 11px;
        font-weight: 400;
        color: #fff; }
      .media-card-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #4253FC;
        .media-actions {
          @include fade-animation-off;
          > * {
            font-size: 12px;
            color: #fff;
            padding: 5px;
            border-radius: 3px; } }
        ////For the select icon
        .fas {
          &.selected {
            @include fade-animation-on; }
          &.not-selected {
            @include fade-animation-off; } } }
      .media-card-info {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        color: #fff;
        .fas, .far {
          @include fade-animation-off;
          font-size: 12px;
          color: #fff;
          padding: 5px;
          border-radius: 3px; } }
      &:hover {
        .media-card-header {
          .media-actions {
            @include fade-animation-on;
            >*:hover {
              background-color: rgba(0, 0, 0, 0.5);
              cursor: pointer; } } }
        .media-card-info {
          >*:hover {
            background-color: rgba(0, 0, 0, 0.5);
            cursor: pointer; }
          .fas, .far {
            @include fade-animation-on; } } } } }
  .media-preview {
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 50px; }
  .media-filter-form {
    display: grid;
    gap: 5px;
    .media-filters {
      display: grid;
      gap: 5px;
      grid-template-columns: 1fr 1fr; } }
  .media-filter-side-by-side {
    grid-template-columns: 1fr 1fr; } }

//Can live outside of mediaLibrary
.media-player {
  p {
    font-weight: 600;
    font-size: 12px;
    margin-bottom: 5px; }
  .btn-play,
  .btn-pause {
    background: transparent;
    border: 0;
    color: #A8ABBF;
    &:focus {
      outline: none; } }
  input[type=range] {
    -webkit-appearance: none;
    width: 100%;
    background: transparent;
    height: 20px; }
  //seeker ball
  input[type=range]::-webkit-slider-thumb {
    -webkit-appearance: none;
    border: 2px solid #A8ABBF;
    width: 15px;
    height: 15px;
    background-color: #fff;
    border-radius: 50%;
    cursor: pointer;
    margin-top: -7px; }
  input[type=range]::-moz-range-thumb {
    border: 2px solid #A8ABBF;
    width: 15px;
    height: 15px;
    background-color: #fff;
    border-radius: 50%;
    cursor: pointer; }
  input[type=range]::-ms-thumb {
    border: 2px solid #A8ABBF;
    width: 15px;
    height: 15px;
    background-color: #fff;
    border-radius: 50%;
    cursor: pointer; }
  //seeker bar
  input[type=range]::-webkit-slider-runnable-track {
    width: 100%;
    background-color: #CFD2E1;
    height: 1px;
    cursor: pointer; }
  input[type=range]::-moz-range-track {
    width: 100%;
    background-color: #CFD2E1;
    height: 1px;
    cursor: pointer; }
  input[type=range]::-ms-track {
    width: 100%;
    background-color: #CFD2E1;
    height: 1px;
    cursor: pointer; }
  input[type=range]:focus {
    outline: none; }
  input[type=range]::-ms-track {
    width: 100%;
    cursor: pointer;
    /* Hides the slider so custom styles can be added */
    background: transparent;
    border-color: transparent;
    color: transparent; } }
.media-controls {
  padding: 15px;
  border-bottom: 1px solid #E8E8E8; }
.media-bulk-entry {
  border: 1px solid #E8E8E8;
  position: relative;
  border-radius: 3px;
  margin-bottom: 20px; }
.media-bulk-error {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 0, 0, 0.5);
  z-index: 1;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  p {
    color: #fff;
    text-transform: uppercase; } }
.big-picture {
  .preview-image {
    width: 100%; } }
