.badge {
  font-size: 12px;
  word-wrap: break-all;
  &.badge-secondary {
    background: #D3D6DC; }
  &.lg {
    padding: 10px 12px; }
  &.badge-signal {
    display: inline-block;
    padding: 0;
    min-width: 10px;
    min-height: 10px;
    border-radius: 100%;
    vertical-align: middle;
    .fas {
      font-size: 10px; } }
  .badge-icon-primary {
    color: $fluxio-blue; } }


