.marketplace-container {
  .marketplace-header {
    background: #fff;
    padding: 70px 0;
    margin-bottom: 20px;
    text-align: center;
    .marketplace-header-title {
      font-size: 45px;
      font-weight: 300;
      line-height: 1.2;
      margin: 0;
      &+.marketplace-header-lead {
        margin-top: 8px; } }
    .marketplace-header-lead {
      font-size: 20px;
      font-weight: 300;
      color: #6c757d; }
    .marketplace-header-nav-tabs {
      .nav-tab {
        font-size: 14px;
        color: inherit;
        margin-right: 15px;
        color: $fluxio-blue;
        &.active {
          border-bottom: 1px solid $fluxio-blue; }
        &:last-child {
          margin-right: 0; }
        &:hover {
          text-decoration: none;
          color: $fluxio-blue; } } } }
  .marketplace-body {
    display: flex;
    &.body-light {
      background: rgb(247, 247, 247); }
    .marketplace-body-pull {
      margin-top: -75px;
      display: flex;
      justify-content: center; }
    .marketplace-template-preview {
      width: 100%;
      box-shadow: 0px 0px 5px 1px #c7c7c7;
      border-radius: 3px;
      margin-bottom: 20px; }
    .marketplace-template-preview-placeholder {
      width: 100%;
      height: 800px;
      background: #eff1f2;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #c3c3c3;
      font-size: 30px;
      font-weight: bold; }
    .marketplace-panel {
      padding: 20px 0; } } }


