.youtube-post{
  //border: solid 1px #ddd;
  -webkit-box-shadow: 0px 0px 10px 1px rgba(230,230,230,1);
  -moz-box-shadow: 0px 0px 10px 1px rgba(230,230,230,1);
  box-shadow: 0px 0px 10px 1px rgba(230,230,230,1);
  padding: 5px;
  margin-bottom: 20px;
  transition: all 1s ease;

  &:hover{
    transform: scale(1.02);
    -webkit-box-shadow: 0px 0px 10px 2px rgba(214,214,214,1);
    -moz-box-shadow: 0px 0px 10px 2px rgba(214,214,214,1);
    box-shadow: 0px 0px 10px 2px rgba(214,214,214,1);
  }
  .ratio-16-9{
    @include aspect-ratio(16, 9);

    .fas, .fab{
      color: white;
      position: absolute;
      left: 50%;
      top: 50%;
      z-index: 10;
      transform: translate(-50%, -50%);
      font-size: 50px;
      text-shadow: 0 0 40px black;
    }
  }
  .youtube-title{
    font-size: 14px;
    font-weight: 700;
    color: #333;
    display: block;
    text-align: center;
  }
  .channel{
    display: block;
    color: #767676;
    font-size: 11px;
  }
}

.image-modal{
  .modal-dialog{
    width: auto;
  .modal-content{
    justify-content: center;
    border-radius: 0px;
    background-color: transparent;
    border: none;
    .modal-body{
      padding: 0px;
      justify-content: center;
      background-color: transparent;
      .custom-close{
        display: flex;
        padding: 10px;
        justify-content: flex-end;
        flex-direction: row;
        align-items: center;
        background-color: transparent;
        color: #FFFFFF;
        i{
          cursor: pointer;
        }
      }
    }
  }
  }
}

.posts{
  width: 100%;
  margin-bottom: 10px;
  overflow: hidden;
  word-break: break-word;
  &.facebook{
    //border: 5px solid red;
    .img-wrap{
      text-align: center;
    }
  }
  .date-wrap{
    word-break: keep-all;
  }
  .card-title-a{
    &:hover{
      text-decoration: none !important;
    }
  }
  a{
    h4.card-title{
      margin-bottom: 0;
      color: black;
      &:hover{
        text-decoration: none !important;
      }
    }
  }
  .fullBG{
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .message{
    word-break: break-word;
    margin-bottom: 0;
  }
  .message-box{
    padding: 15px;
    align-items: center;
    .message{
      @include overflow-text-vertical(5, 1.5em);
    }
  }
  .entity{
    display: flex;
    align-items: center;
    padding: 10px 10px;
    border-bottom: 1px solid rgba(0,0,0,.125);
    img{
      width: 40px;
      height: 40px;
      border-radius: 50%;
    }
    span{
      margin-left: 10px;
    }
    .date{
        float: right;
        font-size: 14px;
        flex: 1;
        text-align: right;
    }
    .name{
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      flex: 3;
      text-align: left;
      color: #777a7f;
      font-weight: bold;
    }
  }
  .facebookPost{
    padding: 0;

    &.facebook-video{
      .message-box{
        background-color: #fff;
      }
    }

    &.facebook-photo{
      .dangerousHTML{
        &.description{
          width: 100%;
          word-break: break-all;
        }
      }
      .photo-wrap{
        background-color: #fff;
        .message{
          margin: 10px;
        }
        .image{
          width: 100%;
          height: auto;
        }
      }
    }

    &.facebook-link{
      .dangerousHTML{
        &.description{
          width: 100%;
          word-break: break-all;
        }
      }
      .link-wrap{
        background-color: #fff;
        padding: 10px;
        .message{
          font-size: 14px;
          margin-bottom: 5px;
        }
        .link-box{
          .image{
            width: 100%;
            height: auto;
          }
          .info-box{
            padding: 10px;
            p{
              margin: 0;
              &.title{
                font-weight: 400;
                font-size: 20px;
              }
              &.description{
                font-size: 14px;
              }
              &.contact{
                font-size: 14px;
                text-transform: uppercase;
                color: #ccc;
              }
            }

          }
          border: 1px solid #ddd;
          border-radius: 3px;
        }
      }
    }

    &.facebook-event{
      .event-wrap{
        background-color: white;
        padding: 10px;
        .message{
          font-size: 14px;
          margin-bottom: 5px;
        }
        .event-box{
          border: 1px solid #ddd;
          border-radius: 3px;
          .event-img{
            width: 100%;
            height: auto;
          }
          .event-bar{
            border-top: 1px solid #ddd;
            padding: 10px;
            a{
              color: #000;
              &:hover{
                text-decoration: none;
              }
              .title{
                font-weight: bold;
                margin: 0;
              }
            }
          }
        }
      }
    }

    .row.no-gutters{
      background-color: #f1f4f9;
    }

    .card-title{
      padding: 15px;
      margin-bottom: 0;
    }
  }
}

.ql-video{
  width: 100%;
}

.youtube-posts{
  .entity{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .img-wrap{
    text-align: center;
  }
  .contact-info{
    display: flex
  }
  .contacts-wrapper{
    margin-left: 10px;
    p, span{
      margin: 0;
      padding: 0;
    }
  }
}

.feedlypost{
  padding: 5px;
  margin-bottom: 10px;
  .img-wrap{
    text-align: center;
  }
  h3.title{
    font-weight: 400;
    font-size: 1.5em;
    padding: 0 0.75em;
    margin: 0.75em 0;
  }
  hr.line{
    margin: 5px 0.75em;
  }
  .entity{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  .contact-info{
    display: flex;
    width: 60%;
  }
  .contacts-wrapper{
    margin-left: 10px;
    width: 100%;
    p, span{
      margin: 0;
      padding: 0;
    }
  }
}

.cramer-links{
  //border: 5px solid blue;
  .card{
    border: 0 !important;
    //box-shadow: 0 1px 1px #797979;
  }
  .card-block{
    &.cramer-image{
      display: flex;
      justify-content: center;
      align-items: center;
    }
    padding: 0 1.25em 1.25em;
  }
  .date-wrap{
    padding: 1.25em 1.25em 0.5em;
    display: flex;
    justify-content: space-between;
    span{
      font-size: 14px;
      color: #797979;
    }
  }
}

.center-alone{
  display: flex;
  justify-content: center;
}

.infinite-scroll-component .row{
  margin-left: 0 !important;
  margin-right: 1px !important;
}


.twitter{
  //border: 5px solid green;
  .twitter-photo{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .twitter-video{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: red;
  }
  .twitter-retweet{
    margin: 0 20px 20px;
    color: #d8d8d8;
    font-style: italic;
  }
  .twitter-link{
    &:hover{
      color: black !important;
      text-decoration: none;
    }
    color: black;
    text-decoration: none;
    margin: 20px;

    .link-content{
      border-radius: 0.5em;
      border: 1px solid #E1E8ED;
      text-align: center;
      img{
        border-top-left-radius: 0.5em;
        border-top-right-radius: 0.5em;
      }
      .link-info{
        padding: 10px;
        text-align: left;
        h3{
          margin: 0 0 5px 0;
          font-size: 1em;
        }
        p{
          margin: 0;
          font-size: 0.85em;
        }
      }
    }
  }
  img{
    background: #E6ECF0;
  }
}

.card-footer{
  border-top: none;
}

.Collapsible{
  margin-top: 5px;
}

.collapseMaster{
  .dangerousHTML{
    tr td:first-child{
      width: auto;
    }
    &.expanded{
      max-height: 450px;
      overflow: hidden;
    }
  }
  .trigger{
    cursor: default;
    background: white;
    border: 1px solid #eee;
    box-shadow: 1px 1px 5px #eee;
    color: #aaa;
    font-weight: bold;
    letter-spacing: 1px;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
  }
}

.modal-post-expand{
  .modal-dialog{
    max-width: 900px;
    .modal-header{
      background-color: #F1F0F0;
      border-top-right-radius: 2px;
      border-top-left-radius: 2px;
    }
    .modal-content{
      .modal-body{
        max-height: 70vh;
        overflow-y: auto;
      }
    }
    .modal-footer{
      background-color: #F1F0F0;
      border-bottom-right-radius: 2px;
      border-bottom-left-radius: 2px;
      justify-content: space-between;
      .btn{
        font-size: 14px;
        background-color: #ED572A;
        color: #fff;
        border-radius: 2px;
        &:hover{
          filter: contrast(80%);
        }
        .fas, .fab{
          font-size: 12px;
        }
      }
    }
    @media screen and (max-width: 900px) {
      .modal-dialog {
        width: 100%;
      }
    }
  }
}

.newPost{
  display: none;
  font-weight: bold;
  background-color: pink;
  padding: 10px;
  text-align: center;
  border: 1px solid black;
  border-radius: 5px;
  &:before{
    content: 'post - network: ';
    color: #363636;
    font-weight: 400;
  }
}

@mixin aspect-ratio($width, $height) {
  $devidedValue: $height / $width;
  position: relative;
  &:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: $devidedValue * 100%;
  }
  > .video-preview {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

.video-preview{
  @include aspect-ratio(16, 9);
  width: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}