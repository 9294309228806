.default-select-container {
  min-width: 100px;

  .custom-option-icon {
    position: relative;
    i {
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;
      font-size: 15px; } } }
