@import url(https://fonts.bunny.net/css?family=space-grotesk:300,400,600,700,800,900&display=swap);
.jobs-library {
  .job-tab {
    .nav-item.nav-link {

      color: #495057; }
    .nav-item.nav-link.active {
      color: #1d44c4; } }

  .buttonDoJob {
    border: none;
    box-sizing: border-box;
    min-width: 0px;
    width: 20%;
    margin: 10px 0px 0px 0px;
    background-color: #e6e9f4;
    color: #000000;
    text-align: center;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    font-size: 18px;
    height: 40px;
    border-radius: 5px;
    padding: 5px;
    &:hover {
      border: solid 1px #1d44c4; } }
  .buttonJTransparent {
    display: block;
    width: 100%;
    text-decoration: none;
    background-color: transparent;
    border: none;
    &:focus {
      outline: none; } }
  .buttonJ {
    box-sizing: border-box;
    min-width: 0px;
    margin: 0px;
    color: rgb(255, 255, 255);
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    font-weight: 500;
    font-size: 0.875rem;
    height: 28px;
    border-radius: 22px;
    width: fit-content;
    padding: 0.25rem 0.75rem 0.25rem 0.5rem; }
  .buttonJ.completed {
    background-color: rgb(0, 137, 51); }
  .buttonJ.failed {
    background-color: rgb(150, 0, 8); }
  .buttonJ.running {
    background-color: rgb(2, 123, 255); }
  .buttonJ.pending {
    background-color: #8b8c89; }
  .buttonJText {
      width: 1.25rem;
      height: 1.25rem;
      min-width: 1.25rem;
      color: rgb(255, 255, 255); }
  .buttonJ div:first-of-type {
    margin-right: 0.25rem; }
  .buttonJ.short div:first-of-type {
    margin-right: 0rem; }
  .buttonJ.short {
     padding: 0.25rem; }
  .table td {
    vertical-align: middle; }
  .table {
    .actions {
      display: flex;
      flex-direction: row;
      justify-content: center;
      column-gap: 5px; }
    &:hover {
      .actions {
        .hidden-actions {
          @include fade-animation-on;
          >* {} } } } }
  .table-striped table tr:nth-of-type(odd) {
    background-color: transparent; }
  .table-hover table tr:hover {
    color: inherit;
    background-color: transparent; }
  .table-bordered .table {
    border: none; }
  .table-bordered .table td {
    border: none; }
  .table-bordered .table th {
    border: none; }
  .card {
    width: 100%;
    box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.09), 0 1px 1px 0 rgba(0, 0, 0, 0.05), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
    .card-media-box {
      display: flex;
      justify-content: flex-end;
      position: relative;
      .cover {
        position: absolute;
        width: 50%;
        height: 50%;
        bottom: 0;
        right: 0;
        z-index: 1;
        img {
          width: 100%;
          height: 100%;
          transition: opacity 1s ease-in-out;
          &:hover {
            opacity: 0.1; } } } }
    .card-media {
      height: 125px;
      width: 100%;
      object-fit: cover;
      border-radius: 5px 5px 0px 0px; }
    .card-body {
      position: relative;
      padding: 10px;
      .actions {
        justify-content: flex-end; } }
    .title {
      font-weight: 700;
      font-size: 16px; }
    .sub-title {
      font-weight: 600;
      font-size: 16px; }
    .description {
      font-size: 13px;
      font-weight: 400;
      margin-bottom: 5px;
      img {
        width: 100%; } }
    .link {
      color: #007bff;
      font-size: 14px;
      margin-bottom: 5px; }
    .card-tags {
      .tag {
        background: #999999;
        color: #fff;
        font-size: 11px;
        font-weight: bold;
        text-transform: lowercase;
        padding: 2px;
        border-radius: 2px;
        margin-right: 2px; } }
    &:hover {
      @include fade-animation-on;
      .actions {
        .hidden-actions {
          cursor: pointer;
          @include fade-animation-on;
          >* {
            cursor: pointer; } } } }
    .actions {
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      .not-selected {
        visibility: visible; }
      .selected {
        visibility: visible;
        color: $fluxio-blue; }
      .hidden-actions {
        display: flex;
        height: 100%;
        @include fade-animation-on;
        > * {
          line-height: 2;
          height: 100%;
          font-size: 12px;
          color: rgba(0, 0, 0, 1);
          padding: 2px;
          border-radius: 3px; } } } }
  .msg {
    font-size: 14px;
    color: #333; }
  .runs-grid {
    display: grid;
    gap: 10px;
    @media all and (min-width: 2400px) {
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr; }
    @media all and (max-width: 2399px) {
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr; }
    @media all and (max-width: 1999px) {
        grid-template-columns: 1fr 1fr 1fr 1fr; }
    @media all and (max-width: 1299px) {
        grid-template-columns: 1fr 1fr 1fr; } }
  .jobs-grid {
    display: grid;
    gap: 10px;
    @media all and (min-width: 2400px) {
        grid-template-columns: 1fr 1fr 1fr 1fr; }
    @media all and (max-width: 2399px) {
        grid-template-columns: 1fr 1fr 1fr 1fr; }
    @media all and (max-width: 1999px) {
        grid-template-columns: 1fr 1fr 1fr 1fr; }
    @media all and (max-width: 1299px) {
        grid-template-columns: 1fr 1fr 1fr 1fr; }
    @media all and (max-width: 1109px) {
        grid-template-columns: 1fr 1fr 1fr; } } }
.jobs-modal {
  .card-tags {
    .tag {
      background: #999999;
      color: #fff;
      font-size: 11px;
      font-weight: bold;
      text-transform: lowercase;
      padding: 2px;
      border-radius: 2px;
      margin-right: 2px; } } }
.animRunning {
  transformOrigin: center center;
  animation: 8s linear -1s infinite normal none running animation-cnf17l;
  @keyframes animation-cnf17l {
    0% {
      transform: rotate(0deg); }
    25% {
      transform: rotate(360deg); }
    50% {
      transform: rotate(540deg); }
    75% {
      transform: rotate(900deg); }
    100% {
      transform: rotate(1080deg); } } }
.jobs-main-panel {
  font-family: 'Space Grotesk', sans-serif;
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  align-content: flex-start;
  .buttonLink {
    background-color: transparent;
    padding: 2px;
    max-width: 130px;
    text-align: left; }
  .main-panel-full {
    display: flex;
    width: 100%;
    flex-direction: column;
    row-gap: 30px;
    padding: 30px 30px 30px 30px; }
  .main-panel-85 {
    width: 85%; }
  .main-panel {
    display: flex;
    width: 85%;
    flex-direction: column;
    row-gap: 30px;
    padding: 30px 30px 30px 30px;
    background-color: rgb(249, 249, 252); }
  .main-panel-100 {
    width: 100%;
    height: 100%; } }
.jobs-right-sidebar {
  display: flex;
  flex-direction: column;
  place-content: flex-start;
  background-color: white;
  height: 100vh;
  width: 15%;
  min-width: 15%;
  max-width: 260px;
  padding: 30px 30px 30px 30px;
  box-shadow: 0 2px 4px rgba(0,0,0,.3);
  z-index: 11;
  transition: all .25s;
  // sidebar colapsed/ extend
  //  position: absolute
  //  z-index: 11
  //  background-color: #fff
  //  left: 0
  //  top: 100%
  //  border: 1px solid #dcdfe5
  //  border-radius: 3px
  //  .drop-list-container
  //    padding: 0 10px
  //  .scroll-list
  //    overflow-y: auto
  //    height: 100%
  //    max-height: 50vh
  //  .drop-entry
  //    display: flex
  //    justify-content: flex-start
  //    align-items: center
  //    padding: 10px
  //    color: black
  //    &:hover
  //      background-color: #fff
  //      text-decoration: none
  //    p.name
  //      margin: 0 10px
  //    img
  //      width: 50px
  //      height: 50px
  //  .drop-entry-create
  //    cursor: pointer
  //    height: 70px
  //    padding: 10px
  //    .dashed
  //      border: 3px dashed #2a9add
  //      border-radius: 10px
  //      display: flex
  //      justify-content: center
  //      align-items: center
  //      width: 100%
  //      height: 100%
  //      p
  //        margin: 0
  //        font-weight: bold
  //        color: #2a9add
  //        letter-spacing: 2px
  //    &:hover
  //      background-color: #fff
  //      text-decoration: none
  //  .menu-list
  //    &:after
  //      content: ""
  //      display: block
  //      margin: 0 auto
  //      width: 90%
  //      margin-bottom: 10px
  //      border-bottom: 1px solid #dcdfe5
  //      padding-bottom: 10px
  //    .menu-entry
  //      a,
  //      .menu-header,
  //      &>span
  //        padding: 12px
  .navigation {
    // reset contianer side padding
    .menu-list,
    .menu-entry,
    a {
      margin-left: -20px;
      padding-left: 20px;
      margin-right: -20px;
      padding-right: 20px; }
    .menu-entry {
      position: relative;
      a {
        padding-left: 45px; }
      .collapse-icon {
        float: right; } } }
  .menu-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
    transition: max-height 0.5s ease-out;
    height: auto;
    max-height: 500px;
    overflow: hidden;
    &.collapse {
      max-height: 0;
      display: block; }
    .menu-list {
      .menu-entry {
        &:hover,
        a.active {
          background: #dee0e1; }
        .menu-header {
          padding: 10px 0 10px 28px; }
        a,
        &>span {
          padding-left: 45px; } } }
    .menu-entry {
      cursor: pointer;
      &:hover,
      &.active {
        background: rgba(163,175,183, 0.1); }
      a,
      .menu-header,
      &>span {
        color: inherit;
        text-decoration: none;
        display: block;
        padding: 10px 0 10px 0; }
      .fas {
        font-size: 15px;
        margin-right: 10px;
        color: #466576; }
      &.selected {
        background-color: #f5f7f9;
        border-bottom: 4px solid $fluxio-blue; } } } }
.jobs-title-white {
  font-size: 18px;
  font-weight: 600;
  color: #ffffff; }
.jobs-description-white {
  font-size: 13px;
  font-weight: 400;
  color: #ffffff; }
.jobs-block-flex {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; }
.dojob-color {
  color: #1d44c4; }
.topic-tree-background {
  border-radius: 5px;
  background-color: #f9f9f9;
  .topic-tree-head {
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    row-gap: 20px;

    .topic-tree-header {
      display: flex;
      cursor: pointer;
      flex-direction: column;
      padding: 10px 10px 10px 10px;
      border-radius: 5px;


      &:hover {
        box-shadow: #e1e1e1 5px 5px 20px;
        background-color: #f9f9f9;
        .tt-actions-bar {
          cursor: pointer;
          height: 100%;
          @include fade-animation-on;
          > * {
            color: rgba(0, 0, 0, 1);
            padding: 8px; } } } }
    .tt-actions-bar {
      display: flex;
      column-gap: 1px;
      justify-content: flex-end;
      cursor: pointer;
      align-items: center;
      > * {
        color: rgba(0, 0, 0, 0);
        padding: 8px; } }
    .tt-actions-bar.show {
      @include fade-animation-on;
      > * {
        color: rgba(0, 0, 0, 1); } }
    .ql-editor {
      font-size: 16px;
      padding-top: 15px;
      min-height: 200px; }

    .tt-show-detail {
      padding-left: 20px;
      padding-top: 20px;
      padding-bottom: 20px;
      .tt-detail {
        border-left: solid 0px grey;
        margin-left: 10px;
        margin-top: 20px; }
      .tt-detail-button {
        font-size: 30px;
        font-weight: 600; } }
    .tt-show-actions {
      padding: 20px;
      min-height: 300px;
      display: flex;
      flex-direction: column;
      row-gap: 10px; } } }
.messages-container {
  display: flex;
  max-width: 90%;
  flex-direction: column;
  padding: 30px 10px 30px 10px;
  max-height: 400px;
  overflow-y: auto;
  row-gap: 10px;
  .user-container {
    display: flex;
    align-items: center;
    column-gap: 5px;
    flex-direction: row-reverse;
    justify-content: flex-start;
    &:hover {
      .copy-message {
        opacity: 1; } } }
  .copy-message {
    transition: opacity .2s ease,transform .2s ease; }
  .copy-hidden {
    opacity: 0; }
  .assistant-container {
    display: flex;
    align-items: center;
    column-gap: 5px;
    &:hover {
      .copy-message {
        opacity: 1; } } }
  .user-message {
    align-self: end;
    max-width: 95%;
    width: fit-content;
    padding: 5px 10px 5px 10px;
    border-radius: 5px;
    background-color: #1d44c499; }
  .assistant-message {
    max-width: 95%;
    background-color: #fcc503a1;
    width: fit-content;
    padding: 5px 10px 5px 10px;
    border-radius: 5px; }
  .streaming-message {
    max-width: 95%;
    background-color: #f2f2f2a6;
    width: fit-content;
    padding: 5px 10px 5px 10px;
    border-radius: 5px;
    white-space: break-spaces; }
  .loading-indicator {
    font-size: 24px;
    display: flex;
    flex-direction: row;
    column-gap: 2px;
    color: #007bff;
    .loading-dot {
      width: 7px;
      height: 7px;
      background-color: #007bff;
      border-radius: 50%;
      margin: 0 4px;
      animation: color-change 1.5s infinite linear;
      @keyframes color-change {
        0%, 33% {
          background-color: #007bffb8; }
        33%, 75% {
          background-color: #748ad8cf; }
        75%, 100% {
          background-color: #007bff; } } } } }
.rowQuizz {
  display: flex;
  flex-direction: row;
  column-gap: 5px;
  align-items: flex-end;
  padding-top: 20px; }
.form-w70 {
  width: 70%; }
.form-w50 {
  width: 50%; }
.radio-row {
  display: flex;
  align-items: flex-start;
  row-gap: 5px;
  flex-direction: column;
  input {
    appearance: none;
    border-radius: 50%;
    width: 16px;
    height: 16px;
    border: 2px solid #999;
    transition: 0.2s all linear;
    margin-right: 5px;
    position: relative;
    top: 4px; }
  input:checked {
    border: 6px solid black; } }
.card-s {
  width: 100%;

  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.09), 0 1px 1px 0 rgba(0, 0, 0, 0.05), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
  .card-s-grid {
    display: grid;
    max-width: fit-content;
    align-items: center;
    grid-template-columns: [start actions] min-content  [summary] 90% [end]; }
  .card-s-media-box {
    display: flex;
    justify-content: flex-start;
    position: relative;
    .cover {
      position: absolute;
      width: 50%;
      height: 50%;
      bottom: 0;
      right: 0;
      z-index: 1;
      img {
        width: 100%;
        height: 100%;
        transition: opacity 1s ease-in-out;
        &:hover {
          opacity: 0.1; } } } }
  .card-s-media {
    height: 125px;
    width: 100%;
    object-fit: cover;
    border-radius: 5px 5px 0px 0px; }
  .card-s-body {
    position: relative;
    padding: 10px;
    .actions {
      justify-content: flex-end; } }
  .card-s-body-expand {
    display: none; }
  .card-s-body-expand.show {
    display: block; }
  .title {
    font-weight: 700;
    font-size: 16px; }
  .title-overflow {
    overflow: hidden;
    max-width: 100%;
    text-overflow : ellipsis;
    white-space : nowrap; }
  .sub-title {
    font-weight: 600;
    font-size: 16px; }
  .description {
    font-size: 13px;
    font-weight: 400;
    margin-bottom: 5px;
    img {
      width: 100%; } }
  .link {
    color: #007bff;
    font-size: 14px;
    margin-bottom: 5px; }
  .card-s-tags {
    .tag {
      background: #999999;
      color: #fff;
      font-size: 11px;
      font-weight: bold;
      text-transform: lowercase;
      padding: 2px;
      border-radius: 2px;
      margin-right: 2px; } }
  &:hover {
    @include fade-animation-on;
    .actions {
      .hidden-actions {
        cursor: pointer;
        @include fade-animation-on;
        >* {
          cursor: pointer; } } } }
  .actions {
    display: flex;
    justify-content: flex-end;
    padding: 3px;
    align-items: flex-end;
    .not-selected {
      visibility: visible;
      color: #dedada; }
    .selected {
      visibility: visible;
      color: $fluxio-blue; }
    .hidden-actions {
      display: flex;
      height: 100%;
      @include fade-animation-on;
      > * {
        line-height: 2;
        height: 100%;
        font-size: 12px;
        color: rgba(0, 0, 0, 1);
        padding: 2px;
        border-radius: 3px; } } } }
.resize-handle-outer {
  flex: 0 0 1em;
  position: relative;
  outline: none;
  --background-color: transparent;
  &:hover {
    .resize-handle-line {
      background-color: #1d44c4;
      opcaity: 0.9; }
    .h-handle-container {
      opacity: 1;
      .h-handle-icon {
        visibility: visible; } } } }
.resize-handle-outer[data-resize-handle-active] {
  --background-color: var(--color-solid-resize-bar-handle); }
.resize-handle-line {
  width: 3px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0px;
  background: #f2f2f2;
  opacity: .5;
  pointer-events: none;
  transition-duration: .22s;
  transition-property: left,opacity,width;
  transition-timing-function: cubic-bezier(.2,0,0,1); }
.h-handle-container {
  width: 24px;
  height: 24px;
  padding: 5px;
  position: absolute;
  background-color: transparent;
  border-radius: var(--ds-border-radius-circle, 50%);
  box-shadow: 0 0 0 1px rgba(9,30,66,.08), 0 2px 4px 1px rgba(9,30,66,.08);
  color: var(--ds-text-subtle, #6B778C);
  cursor: pointer;
  inset-block-start: var(--ds-space-400, 32px);
  inset-inline-start: 0;
  opacity: 0;
  outline: 0;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  -webkit-transition: background-color 100ms linear,color 100ms linear,opacity 350ms cubic-bezier(.2,0,0,1);
  transition: background-color 100ms linear,color 100ms linear,opacity 350ms cubic-bezier(.2,0,0,1);
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
  transform-origin: 7px; }
.h-handle-icon {
  width: 1em;
  height: 1em;
  position: absolute;
  left: calc(50% - 0.5rem);
  top: calc(50% - 0.5rem);
  visibility: hidden; }
.r-panel-container {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  .r-panel-nav {
    min-height: 50px; }
  .r-panel-header {
    padding-right: 10px;
    min-height: 50px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .hoot-hoot.show {
      box-shadow: 0 0 0 1px rgba(9,30,66,.08), 0 0px 0px 1px rgba(9,30,66,.08); } } }
.panel-separator {
  height: 2px;
  padding-left: 2px;
  padding-right: 2px;
  background-color: #f2f2f2; }
.l-panel-container {
  display: flex;
  flex-direction: column;
  padding-left: 8px;
  padding-right: 8px;
  row-gap: 0px;
  .l-panel-nav {
    min-height: 50px;
    background-color: {} }
  .l-panel-header {
    min-height: 50px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 2px; }
  .l-panel-content {}
  .tree-item {
    cursor: pointer;
    display: flex;
    align-items: center;
    column-gap: 4px;
    padding: 3px 0px 3px 0px;
    &:hover {
      background-color: #F1F2FB;
      > .tree-text.subtle {
        color: inherit; } } }
  .tree-text {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis; }
  .tree-text.subtle {
    color: grey;
    &:hover {
      color: inherit; } }
  .tree-icon {
    display: flex;
    align-items: center;
    > i {
      font-size: 10px; } } }
