.page-settings-panel {
  .modal-header {
    padding: 0;
    border-bottom: 1px solid #E8ECF1;
    .close {
      margin: 0; } }
  .list-group {
    overflow-x: auto;
    overflow-y: hidden; }
  .setting-description {
    display: flex;
    align-items: center;
    color: #6c757d;
    font-size: 13px; }
  .settings-panel-tabs {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 1;
    .settings-panel-tab {
      position: relative;
      padding: 15px;
      cursor: pointer;
      flex: 1;
      justify-content: center;
      align-items: center;
      display: flex;
      &:first-child {
        &:after {
          display: none !important; } }
      &:after {
        content: "";
        background: #E8ECF1;
        position: absolute;
        bottom: 0;
        left: 0;
        height: 60%;
        width: 1px;
        bottom: 15%; }
      &.active {
        border-bottom: 2px solid #4253fc; } } }
  .favicon-settings {
    .card-footer {
      display: flex;
      justify-content: space-between;
      align-items: center; }
    .list-group-item {
      flex-direction: column; } }
  .cookie-option {
    display: grid;
    grid-template-columns: 50px 2fr 1fr;
    border-bottom: 1px solid #eee;
    &>div {
      padding: 10px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      background: #f8f9fa; }
    .cookie-title {
      font-size: 1.2rem;
      font-weight: 700;
      margin-bottom: 10px;
      line-height: 1; }
    .cookie-description {
      font-size: 0.9rem;
      margin-bottom: 10px; }
    .cookie-subject {
      font-size: 0.9rem;
      color: #6c757d; }
    &.cookie-option-head {
      &>p {
        font-size: 0.9rem;
        padding: 10px;
        font-weight: 700;
        text-transform: uppercase;
        line-height: 1;
        color: #ffffff;
        background: #7c7c7c; } }
    input[type=checkbox] {
      transform: scale(1.5);
      &:checked {
        background-color: var(--fluxio-blue);
        border: 1px solid var(--fluxio-blue);
        &:after {
          content: "";
          display: block;
          width: 10px;
          height: 10px;
          border-radius: 3px;
          margin: 3px; } } } } }
.lead {
  font-size: 9px;
  text-transform: uppercase;
  color: #aaa;
  font-weight: bold; }
.avatar {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  color: #fff;
  border-radius: 50%;
  margin-right: 5px;
  &.facebook-color {
    background-color: #3B5998; }
  &.google-color {
    background-color: #DB4437; }
  &.hotjar-color {
    background-color: #fd3a5c; }
  &.mixpanel-color {
    background-color: rgb(66, 132, 251); } }
.br-1 {
  border-right: 1px solid #eee; }
//Move to card specific layout
.delete-button {
  width: 100%;
  height: 100%;
  background-color: transparent;
  border: none;
  outline: none; }
