.experience-manager,
.profile-dashboard {
  font-family: 'Nunito Sans', sans-serif;
  height: 100%;
  .experience-manager-page-list {
    background: #fff;
    .page-list {
      display: flex;
      flex-direction: column; }
    .page-item {
      font-size: 12px;
      color: black;
      padding: 5px 10px;
      &:hover {
        background-color: rgba(64, 80, 252, 0.2);
        text-decoration: none; } } }
  .experience-manager-loading {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 999;
    color: #ffffff;
    font-size: 18px;
    i {
      font-size: 40px;
      margin-bottom: 20px; } }
  .experience-manager-navbar {
    background: rgba(64, 80, 252, 0.6);
    height: 40px;
    display: flex;
    align-items: center;
    color: #fff;
    width: 100%;
    .tab {
      padding: 5px;
      cursor: pointer;
      flex: 1 1 0px;
      text-align: center;
      font-weight: 600;
      font-size: 13px;
      letter-spacing: 0.3px;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center; }
    .btn {
      border-radius: 0;
      padding: .375rem .75rem;
      margin: 0;
      padding-left: 8px;
      padding-right: 8px;
      img {
        width: 20px;
        height: 20px;
        filter: invert(1); } }
    i {
      color: #fff; }
    .btn, .tab {
      &:hover,
      &.selected {
        background: $fluxio-blue; } }
    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0; }
    /* Firefox */
    input[type=number] {
      -moz-appearance: textfield; } }
  .body-section {
    height: 100%; }
  .created-loading {
    position: absolute;
    z-index: 11;
    left: 0;
    top: 0;
    height: 100vh;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    .modal-loading {
      background-color: #ffffff;
      border-radius: 4px;
      padding: 10px;
      width: 100%;
      .event-handler {
        text-align: center;
        img.bob {
          width: 200px;
          height: auto; } }
      .title {
        font-size: 20px;
        text-transform: uppercase;
        font-weight: 500;
        letter-spacing: -0.1px;
        text-align: center;
        color: #717171;
        margin: 0 0 5px; }
      .msg {
        font-size: 14px;
        font-weight: 500;
        letter-spacing: -0.1px;
        text-align: center;
        color: #a3a7af;
        margin: 0; } } }
  .row-profiles {
    background-color: #fff;
    padding: 20px;
    height: 100%;
    .card {
      padding: 0 5px;
      .card-header {
        background-color: #fff; }
      .card-body {
        padding: 0;
        .profile-info {
          width: 100%;
          height: 50px;
          border-bottom: 1px solid #3e3e3e;
          display: flex;
          justify-content: space-between;
          align-items: center;
          border-radius: 3px;
          border-bottom: 1px solid #d6d6d6;
          padding: 5px 15px;
          color: #3e3e3e;
          span.pre-title {
            font-size: 14px; }
          p.name {
            font-weight: bold;
            text-transform: capitalize; }
          img.avatar {
            border-radius: 50%;
            width: 35px;
            height: 35px;
            border: 1px solid #eee; } } } } }
  .header-section {
    height: auto;
    min-height: auto;
    //max-height: 120px
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 5px 20px;
    &.childs {
      //flex-direction: row
      //align-items: flex-end
      //justify-content: space-between
      .resize-wrap {
        display: flex;
        justify-content: center;
        align-items: flex-end;
        button.resize {
          padding: 0 10px;
          background-color: #f4f6f8;
          border: none;
          border-bottom: 2px solid #ffffff;
          outline: none;
          &.mobile {
            font-size: 18px; }
          &.tablet {
            font-size: 24px; }
          &.desktop {
            font-size: 28px; }
          &.selected {
            border-bottom: 2px solid $fluxio-blue; } } }
      .text-holder {
        display: flex;
        justify-content: flex-end;
        flex-direction: column; }
      .clipboard-handler {
        font-size: 20px;
        color: #767a81; } }
    .header-options {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      .fas {
        padding: 0 5px;
        font-size: 20px; }
      .label-small {
        cursor: pointer;
        font-size: 12px;
        .fas,.far {
          padding: 0 3px 0 0;
          font-size: 18px; } } }
    h1.title {
      font-size: 20px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: -0.2px;
      text-align: left;
      color: #1e2230;
      margin: 0; }
    p.description {
      font-size: 11px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: -0.1px;
      text-align: left;
      color: #767a81;
      margin: 0; } }
  .hub-explorer {
    height: calc(100% - 40px); // 40px is the height of the tabs
    display: flex;
    flex-direction: column;
    .action-wrap-btns {
      position: relative;
      padding: 10px;
      background-color: #fff;
      display: flex;
      margin-top: auto;
      z-index: 10;
      button.action {
        flex: 1;
        border: none;
        padding: 5px;
        text-align: center;
        color: #ffffff;
        border-radius: 4px;
        width: 100%;
        margin: 0 2px;
        font-size: 12px;
        &.btn-action,
        &.btn-danger {
          flex: 0 0 100%;
          margin: 0;
          margin-top: 5px; }
        &:hover {
          background-color: #7ec9ff; } } }
    .body-section,
    .body-section-extended {
      font-family: 'Eina';
      font-weight: 400;
      background-color: #fff;
      //with header
      height: 100%;
      overflow-y: auto;
      position: relative;
      .error {
        text-align: center; }
      .create-exp-btn {
        background-color: $fluxio-blue;
        color: #ffffff;
        font-size: 13px;
        font-weight: 500;
        border: none;
        border-radius: 5px;
        padding: 10px 20px;
        margin: 10px 0 0; }
      .formulas-wrap {
        .warning {
          text-align: center;
          padding: 20px;
          .msg {
            color: #868686;
            font-size: 14px; } }
        .add-object-gizmo {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 0 5px 0 45px;
          height: 27px;
          cursor: pointer;
          margin: 0 0 -10px;
          &:not(:first-child) {
            margin: -10px 0; }
          .gizmo-line {
            width: 100%;
            height: 3px;
            background-color: $fluxio-blue;
            border-radius: 5px;
            display: none; }
          img {
            width: 39px;
            display: none; }
          &:hover {
            .gizmo-line,
            img {
              display: block; } } } }

      .experience-wrap {
        background-color: #27272d;
        border-radius: 5px;
        //.redo height
        top: 0;
        z-index: 2;
        padding: 10px 15px;
        position: sticky;
        width: 100%;
        margin: 0;
        .name-edit-wrap {
          display: flex;
          align-items: center;
          .name-editor {
            border: none;
            outline: none;
            border-bottom: 1px solid rgba(256, 255, 255, 0.3);
            background: transparent;
            font-size: 22px;
            color: #ffffff;
            font-weight: 600;
            width: 100%;
            &:focus {
              border-bottom: 1px solid #3fa6ff; } } }
        .exp-details {
          display: flex;
          flex-direction: column;
          .error-msg {
            font-size: 18px;
            color: #ffffff;
            font-weight: 600;
            margin: 0; }
          .exp {
            font-size: 10px;
            text-transform: uppercase;
            color: rgba(39, 39, 45, 0.4);
            font-weight: 600;
            margin: 0; }
          .name {
            font-size: 22px;
            color: #ffffff;
            font-weight: 800;
            margin: 0 5px 0 0; } }
        .exp-commands {
          color: #ffffff;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding-top: 10px;
          .ellipsis {
            padding: 0 5px; } } }
      .selected-group-component {
        background-color: $fluxio-blue;
        padding: 10px 15px;
        border-radius: 5px;
        box-shadow: 0px 0px 5px #e3e3e3;
        display: flex;
        flex-direction: column;
        margin: 5px 35px 5px 45px;
        .handler-actions {
          display: flex;
          justify-content: space-between;
          color: #ffffff;
          font-size: 13px;
          padding-top: 5px;
          .commands {
            display: flex;
            width: 100%;
            justify-content: space-between; }
          .add-wrap {
            cursor: pointer; }
          i {
            padding-right: 5px; } }
        .wrapper {
          .items-wrap {
            input,
            span.formula,
            span.component,
            span.placeholder {
              background: transparent;
              outline: none;
              border: none;
              width: 100%;
              color: #ffffff;
              padding: 5px 0;
              border-bottom: solid 1px #7dc7ff;
              &:focus {
                border-bottom: 1px solid #fff; } }
            input {
              &::placeholder {
                font-size: 11px;
                font-weight: bold;
                color: #a7d9ff;
                text-transform: uppercase;
                padding: 9px 0; } }
            span.placeholder {
              font-size: 11px;
              font-weight: bold;
              color: #a7d9ff;
              text-transform: uppercase;
              padding: 9px 0; }
            .wrap-action {
              position: relative;
              display: flex;
              align-items: center;
              padding: 2px 0;
              .additional-actions {
                display: none;
                position: absolute;
                right: 0;
                button.finder {
                  background-color: transparent;
                  border: none; } }
              &:hover {
                .additional-actions {
                  display: flex; } } } } } }
      .add-object-wrap {
        display: flex;
        padding: 20px 35px;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        p.info {
          font-size: 22px;
          font-weight: bold;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.18;
          letter-spacing: -0.2px;
          text-align: center;
          color: #d3d6dc; }
        button.add-object {
          border-radius: 6px;
          width: 100%;
          padding: 20px 0;
          background-color: transparent;
          border: solid 2px $fluxio-blue;
          font-size: 16px;
          font-weight: bold;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: -0.2px;
          color: $fluxio-blue; } }
      .redo {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-size: 14px;
        color: #767a81;
        padding: 10px 15px;
        position: sticky;
        top: 90px;
        background-color: #eff1f5;
        z-index: 2;
        color: $fluxio-blue;
        .breadcrumb {
          margin: 0;
          background: transparent;
          padding: 0px;
          .breadcrumb-item {
            &:before {
              display: none; }
            &:after {
              display: inline-block;
              padding-right: .5rem;
              padding-left: .5rem;
              color: #6c757d;
              content: "/"; } } }
        .multiple {
          span.back {
            font-weight: bold; } }
        .fas,
        img {
          color: $fluxio-blue;
          margin: 0 10px 0 0; } }
      // individual draggable object
      .draggable-wrap {
        margin-left: 10px;
        display: flex;
        flex-direction: column;
        padding: 0 35px;
        .selected-group {
          width: 8px;
          background-color: $fluxio-blue;
          position: absolute;
          left: 0;
          top: 0;
          height: 100%;
          border: 2px solid #fff;
          border-radius: 5px;
          z-index: 1; }
        .group-content {
          width: 100%; }
        .group-handler-wrapper {
          width: 100%;
          display: flex;
          background-color: #ffffff;
          border-radius: 6px;
          border: solid 2px #d3d6dc;
          position: relative;
          max-height: 200px;
          animation: animateHeight 0.8s ease-out;
          &.noAnimation {
            animation: none; }
          .group-card,
          .group-own-visibility,
          .group-own-actions,
          .handlers {
            opacity: 1;
            animation: animateOpacity 0.8s ease;
            @keyframes animateOpacity {
              0% {
                opacity: 0; }
              40% {
                opacity: 0; }
              100% {
                opacity: 1; } } }
          @keyframes animateHeight {
            0% {
              max-height: 0px; }
            50% {
              max-height: 100px; }
            100% {
              max-height: 200px; } }
          .component-group-wrap {
            position: relative;
            .group-card {
              display: flex;
              position: relative;
              .items-wrap {
                width: 100%;
                .group-item {
                  .form-group,
                  .formula-group,
                  .component-group {
                    margin: 0;
                    position: relative;
                    .input-wrap-action,
                    .wrap-action {
                      position: relative;
                      display: flex;
                      align-items: center;
                      padding: 2px 0;
                      .additional-actions {
                        display: none;
                        position: absolute;
                        right: 0;
                        button.finder {
                          background-color: transparent;
                          border: none; } }
                      span.placeholder {
                        border-bottom: solid 1px #d3d6dc;
                        padding: 7px 20px;
                        width: 100%;
                        font-size: 9px;
                        font-weight: bold;
                        font-style: normal;
                        font-stretch: normal;
                        line-height: normal;
                        letter-spacing: -0.1px;
                        color: #a3a7af;
                        text-transform: uppercase; }
                      span.formula,
                      span.component,
                      input {
                        border: none;
                        outline: none;
                        border-bottom: solid 1px #d3d6dc;
                        padding: 7px 20px;
                        width: 100%;
                        font-size: 12px;
                        font-weight: 600;
                        font-style: normal;
                        font-stretch: normal;
                        line-height: normal;
                        letter-spacing: -0.1px;
                        color: #27272d;
                        border-radius: 0;
                        &::placeholder {
                          font-size: 9px;
                          font-weight: bold;
                          font-style: normal;
                          font-stretch: normal;
                          line-height: normal;
                          letter-spacing: -0.1px;
                          color: #a3a7af;
                          text-transform: uppercase; }
                        &:focus {
                          border-bottom: 1px solid #3fa6ff; } }
                      &:hover {
                        .additional-actions {
                          display: flex; } } }
                    .loading-formula-overlay {
                      position: absolute;
                      z-index: 1;
                      width: 100%;
                      height: 100%;
                      top: 0;
                      left: 0;
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      background-color: rgba(255, 255, 255, 0.5); } } } }
              .group-own-visibility {
                position: absolute;
                bottom: -30px;
                left: 45px; }
              .group-own-actions {
                position: absolute;
                bottom: -32px;
                right: 9px; } } } }
        .handlers {
          display: flex;
          justify-content: space-between;
          padding: 5px 30px 5px 5px;
          .group-actions {
            display: flex;
            justify-content: center;
            align-items: center;
            img {
              margin: 0 10px; } } }
        .object-wrap-stripe {
          height: 4px;
          border-radius: 5px;
          border-bottom: 2px solid #d3d6dc;
          background-color: #ffffff; } } }
    .body-section-extended {
      background: #fff;
      padding: 15px;
      height: 100%; } }
  .outputters-sign {
    margin-bottom: 20px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .msg {
      font-size: 12px;
      margin-right: 5px; }
    .out-btn {
      cursor: pointer;
      background-color: #cccccc;
      color: #ffffff;
      border: none;
      border-radius: 3px;
      font-size: 12px; } }
  .save-modal {
    font-family: 'Nunito Sans', sans-serif;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10000;
    .save-modal-body {
      background-color: #ffffff;
      border-radius: 4px;
      padding: 20px;
      min-width: 400px;
      max-width: 600px;
      h3.title {
        font-size: 20px;
        font-weight: 600;
        color: #1e2230; }
      .phrase {
        margin: 0;
        font-size: 16px;
        font-weight: 600;
        color: #1e2230; }
      .state {
        margin: 0;
        font-size: 12px;
        font-weight: 600;
        color: #1e2230;
        text-align: right; }
      .wrap-btns {
        display: flex;
        justify-content: center;
        align-items: center;
        button {
          border: none;
          border-radius: 4px;
          font-size: 18px;
          font-weight: 500;
          padding: 10px 20px;
          margin: 10px 5px 0 0;
          width: 100%;
          &.disabled {
            cursor: not-allowed;
            background-color: #d3d6dc;
            color: #7b7b7b; } } } } }
  .empty-content {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 50px 25px;
    .msg {
      font-size: 13px;
      font-weight: 500;
      letter-spacing: -0.1px;
      text-align: center;
      color: #a3a7af;
      margin: 0; } }
  .icon-uploaded-wrap {
    position: relative;
    img {
      width: 100%;
      height: auto; }
    .fa-times {
      color: #ffffff !important;
      position: absolute;
      right: 10px;
      top: 10px;
      background-color: red;
      border-radius: 50%;
      padding: 8px 10px; }
    .fa-spinner {
      color: #ffffff !important;
      position: absolute;
      right: 10px;
      top: 10px;
      background-color: #7ec9ff;
      border-radius: 50%;
      padding: 8px 8px; } }
  .draft {
    // normalize text size inside text editor
    .ql-editor {
      h1, h2, h3, h4, h5, h6 {
        font-size: inherit !important; } } } }
.tippy-tooltip {
  background-color: transparent;
  &.transparent-theme {
    padding: 0; } }
.own-actions {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: $fluxio-blue;
  padding: .4rem 0;
  border-radius: 4px;
  span.action {
    width: 100%;
    padding: 5px 10px;
    cursor: pointer;
    color: #ffffff;
    text-align: left;
    &:hover {
      background: #3348d1; }
    &.disabled {
      color: rgba(255, 255, 255, 0.5);
      cursor: default; }
    i, img {
      color: #000;
      margin-right: 5px;
      font-size: 14px; } } }

//Error box
.error-wrapper {
  background-color: #f44336;
  border-radius: 4px;
  padding: 10px;
  h4.error {
    color: #ffffff;
    font-weight: bold;
    font-size: 20px; }
  p.error {
    margin: 0;
    color: #ffffff !important; }
  p.warning,
  p.msg {
    margin: 0;
    color: #ffffff !important;
    font-size: 12px;
    text-align: center;
    border-top: 1px solid #ffffff;
    padding-top: 10px; } }

.object-details {
  width: 100%;
  background-color: #fff;
  font-size: 12px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: 600;
  box-shadow: 4px 2px 5px rgba(0, 0, 0, 0.11);
  z-index: 1;
  .object-details-wrap {
    width: 100%; }
  .object-details-name {
    word-break: break-all;
    margin-right: 5px; }
  img {
    max-width: 75px;
    height: auto;
    margin-right: 15px; }
  i {
    font-size: 18px;
    color: #868686;
    cursor: pointer; }
  .detach-btn {
    font-size: 12px;
    color: #333;
    //cursor: pointer
    padding-left: 3px; }
  .object-details-symbol {
    padding: 20px 0px 0px;
    h6 {
      font-size: 12px;
      font-weight: bold; }
    span {
      font-size: 11px;
      color: #333; } } }
.loading-container {
  display: flex;
  flex-direction: column;
  color: #fff;
  justify-content: center;
  align-items: center;
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.3); }
.flex-1 {
  flex: 1; }

.small-screen-warning {
  display: none;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 1000;
  justify-content: center;
  align-items: center;
  color: white;
  flex-direction: column;
  h1 {
    font-size: 26px; } }

$big-left-panel: 400px;
$big-right-panel: 400px;
$small-left-panel: 300px;
$small-right-panel: 300px;
$small-total-panel: $small-left-panel + $small-right-panel;
$big-total-panel: $big-left-panel + $big-right-panel;
.side-panels {
  min-width: $small-left-panel;
  flex: 0 0 $small-left-panel; }
.center-panel {
  width: calc(100% - #{$small-total-panel}); }
.center-panel-expanded {
  width: 100%; }
.finder-wrap {
  width: $small-right-panel; }
.settings-wrapper {
  min-width: $small-right-panel;
  .card {
    .card-title {
      font-size: $meshapp-font-size-md;
      font-weight: $meshapp-font-weight-semibold;
      letter-spacing: -0.1px;
      color: $meshapp-dark; }
    .card-text {
      font-size: $meshapp-font-size-sm;
      font-weight: $meshapp-font-weight-semibold;
      line-height: 1.33;
      letter-spacing: -0.1px;
      color: $meshapp-grey-dark; } } }
@media all and (min-width: 2048px) {
  .side-panels {
    min-width: $big-left-panel;
    flex: 0 0 $big-left-panel; }
  .center-panel {
    width: calc(100% - #{$big-total-panel}); }
  .center-panel-expanded {
    width: 100%; }
  .finder-wrap {
    width: $big-right-panel; }
  .settings-wrapper {
    min-width: $big-right-panel; } }

@media all and (max-width: 999px) {
  .small-screen-warning {
    display: flex; } }
