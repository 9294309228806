.eva-tabs{
  margin-top: -10px;

  .nav.nav-pills.nav-justified{
    display: table;
    width: 100%;
    .nav-item{
      display: table-cell;
    }
  }
  .full-sticky{
    width: 100% !important;
    left: 0;
  }

    .tab-content {
        text-align: left;
        margin-top: 0;
    }
    .nav-pills {
      margin-bottom: 20px;

      .nav-item{
        box-shadow: 0 2px 0px #e2e2e2;
      }

      .nav-link {
      	font-size: 14px;
      	color: white;
      	line-height: 14px;
      	letter-spacing: 0.6px;
      	text-transform: uppercase;
      	display: block;
      	padding: 18px 5px;
        height: 100%;

      }
	}
    .nav-pills .nav-link{
        border-radius: 0;
        &:hover{
          text-decoration: none;
        }
    }
}
