//use entire height space in APP
.fh-screen {
  height: calc(100vh - 50px); // minus navbar height
  display: flex;
  flex-direction: column;
  overflow: hidden auto;
  &.fh-screen-em {
    height: calc(100vh - 100px); } // minus navbar
  &.fh-screen-em-pagination {
    height: calc(100vh - 155px); } // minus navbar/sidepanel-header/pagination
  &.fh-screen-pagination {
    height: calc(100vh - 110px); } // minus navbar/pagination
  &.fh-screen-modal {
    height: calc(100vh - 200px); } }
.fh-screen-actions {
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: rgba(255, 255, 255, 0.5);
  padding-right: 15px;
  padding-left: 15px;
  margin-right: -15px;
  margin-left: -15px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0,0.1); }
.custom-gutter-right {
  margin-right: -10px; }
