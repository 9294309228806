.board-wrap {
  .debug {
    display: none;
  }
  display: flex;
  .col,
  .col-2,
  .col-md-2,
  .col-3,
  .col-md-3,
  .col-4,
  .col-md-4,
  .col-6,
  .col-md-6,
  .col-md-8,
  .col-9,
  .col-md-9,
  .col-md-12 {
    margin: 0;
    padding-left: 0;
    padding-right: 0;
  }
  .board-message {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    flex-direction: column;
  }
  .action-filter {
    margin: 5px 5px;
    display: flex;
    button {
      margin: 0 5px;
    }
  }
  .sadface {
    font-size: 50px;
    font-weight: bold;
    letter-spacing: 2px;
  }
  background-color: #ffffff;
  .content {
    width: 100%;
  }
  .sidebar {
    width: 200px;
    transition: 700ms ease-in;
    background-color: rgb(25, 25, 25);
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .top-content {
      .profile-switch {
        padding: 10px 15px;
        .profile-modal {
          margin: 10px;
          position: absolute;
          background-color: #191919;
          border: 1px solid #333;
          border-radius: 3px;
          max-height: 50vh;
          overflow: auto;
          z-index: 10;
          .modal-items {
            display: flex;
            align-items: center;
            cursor: pointer;
            padding: 10px;
            &:hover {
              background-color: #3c3c3c;
            }
            img {
              width: 40px;
              height: 40px;
              object-fit: cover;
              border-radius: 3px;
            }
            p {
              color: #eee;
              padding: 0 15px;
              margin: 0;
            }
          }
        }
        .profile-info {
          display: flex;
          align-items: center;
          cursor: pointer;
          p {
            color: #fff;
            margin: 0 0 0 5px;
            padding: 0;
          }
          img.avatar {
            border-radius: 3px;
            width: 40px;
          }
        }
      }
    }
    .bot-content {
      display: flex;
      color: #fff;
      flex-wrap: wrap;
      .logBox {
        flex: 0 0 100%;
        padding: 10px 5px;
        .btn-log-state {
          display: block;
          text-align: center;
          background-color: #262626;
          color: #fff;
          border-radius: 3px;
          padding: 5px;
          text-transform: uppercase;
          letter-spacing: 2px;
          font-size: 11px;
          cursor: default;
          &:hover {
            text-decoration: none;
            background-color: #363636;
          }
        }
      }
      .footer-btn {
        font-size: 12px;
        margin: 0 5px 5px;
        color: #d4d4d4;
        &:hover {
          text-decoration: none;
          color: #fff;
        }
        &p {
          margin: 0;
          padding: 0;
        }
      }
      .profile-info {
        display: flex;
        align-items: center;
        cursor: pointer;
        p {
          color: #fff;
          margin: 0 0 0 5px;
          padding: 0;
        }
        img.avatar {
          border-radius: 3px;
          width: 40px;
        }
      }
      .fa-sign-out,
      .fa-sign-in {
        display: none;
      }
    }
    img {
      &.logo {
        margin: 10px 15px;
        width: 35px;
        border-radius: 5px;
      }
    }
    ul {
      margin: 0;
      padding: 0;
      &.main-menu {
        color: #fff;
        list-style-type: none;
        li {
          padding: 5px 20px;
          border-left: 5px solid rgb(25, 25, 25);
          &:hover {
            background-color: #000;
            border-left: 5px solid rgb(7, 169, 234);
            cursor: default;
          }
          &.active {
            background-color: #000;
            border-left: 5px solid rgb(7, 169, 234);
          }
          i.fas,
          i.fab {
            margin: 0 20px 0 0;
          }
        }
      }
    }
    &.collapsed {
      width: 80px;
      .top-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        img.logo {
          margin: 10px 0;
        }
        .profile-switch {
          margin: 10px 0;
          padding: 0;
          .profile-info {
            p {
              display: none;
            }
            .avatar-name {
              display: none;
            }
          }
        }
        ul.main-menu {
          width: 100%;
          li {
            padding: 0;
            text-align: center;
            .fas,
            .fab {
              margin: 0;
            }
            span {
              display: none;
            }
          }
        }
      }
      .bot-content {
        .btn-log-state {
          .fas,
          .fab {
            display: block;
          }
          span {
            display: none;
          }
        }
        .footer-btn {
          display: none;
        }
      }
    }
  }
  .xFormula {
    background-color: rgb(38, 38, 38);
    color: #d3d3d3;
    padding: 20px;
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100vh - 145px);
    .xEditFormula {
      padding: 15px 0px;
    }
    button.edit-formula-btn {
      background-color: #4f4f4f;
      border: none;
      border-radius: 3px;
      color: #fff;
      width: 100%;
      margin: 0 2px;
      text-transform: uppercase;
      letter-spacing: 3px;
      font-size: 12px;
      padding: 5px;
    }
    .profile-info-banner {
      margin: -20px -20px 5px;
      .banner {
        background-position: center center;
        background-size: cover;
        background-repeat: no-repeat;
        display: flex;
        padding: 5vh 25px;
        align-items: flex-end;
        .avatar {
          width: 100px;
          height: 100px;
          border: 2px solid #eee;
          box-shadow: 2px 2px 5px #333;
        }
        .name {
          font-weight: bold;
          text-transform: uppercase;
          color: #fff;
          text-shadow: 3px 3px 0px rgba(0, 0, 0, 0.2);
        }
      }
    }
    .line-xformula {
      border-radius: 3px;
      padding: 0 3px;
      display: flex;
      justify-content: flex-start;
      align-items: flex-end;
      font-size: 12px;
      .new-formula-square {
        position: relative;
        display: inline-block;
        border-radius: 3px;
        border: 3px dotted gray;
        img {
          border-radius: 3px;
        }
        &:after {
          content: '';
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          display: inline-block;
          background: -moz-linear-gradient(
            top,
            rgba(0, 0, 0, 0) 0%,
            rgba(0, 0, 0, 0.65) 100%
          ); /* FF3.6+ */
          background: -webkit-gradient(
            linear,
            left top,
            left bottom,
            color-stop(0%, rgba(0, 0, 0, 0.65)),
            color-stop(100%, rgba(0, 0, 0, 0))
          ); /* Chrome,Safari4+ */
          background: -webkit-linear-gradient(
            top,
            rgba(0, 0, 0, 0) 0%,
            rgba(0, 0, 0, 0.65) 100%
          ); /* Chrome10+,Safari5.1+ */
          background: -o-linear-gradient(
            top,
            rgba(0, 0, 0, 0) 0%,
            rgba(0, 0, 0, 0.65) 100%
          ); /* Opera 11.10+ */
          background: -ms-linear-gradient(
            top,
            rgba(0, 0, 0, 0) 0%,
            rgba(0, 0, 0, 0.65) 100%
          ); /* IE10+ */
          background: linear-gradient(
            to bottom,
            rgba(0, 0, 0, 0) 0%,
            rgba(0, 0, 0, 0.65) 100%
          ); /* W3C */
          filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a6000000', endColorstr='#00000000',GradientType=0 ); /* IE6-9 */
          border-radius: 3px;
        }
      }
      .gradient {
        position: relative;
        display: inline-block;
        border-radius: 10px;
        img {
          border-radius: 3px;
        }
        &:after {
          content: '';
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          display: inline-block;
          background: -moz-linear-gradient(
            top,
            rgba(0, 0, 0, 0) 0%,
            rgba(0, 0, 0, 0.65) 100%
          ); /* FF3.6+ */
          background: -webkit-gradient(
            linear,
            left top,
            left bottom,
            color-stop(0%, rgba(0, 0, 0, 0.65)),
            color-stop(100%, rgba(0, 0, 0, 0))
          ); /* Chrome,Safari4+ */
          background: -webkit-linear-gradient(
            top,
            rgba(0, 0, 0, 0) 0%,
            rgba(0, 0, 0, 0.65) 100%
          ); /* Chrome10+,Safari5.1+ */
          background: -o-linear-gradient(
            top,
            rgba(0, 0, 0, 0) 0%,
            rgba(0, 0, 0, 0.65) 100%
          ); /* Opera 11.10+ */
          background: -ms-linear-gradient(
            top,
            rgba(0, 0, 0, 0) 0%,
            rgba(0, 0, 0, 0.65) 100%
          ); /* IE10+ */
          background: linear-gradient(
            to bottom,
            rgba(0, 0, 0, 0) 0%,
            rgba(0, 0, 0, 0.65) 100%
          ); /* W3C */
          filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a6000000', endColorstr='#00000000',GradientType=0 ); /* IE6-9 */
          border-radius: 3px;
        }
      }
      cursor: default;
      .xline-img {
        display: block;
        width: 100%;
        height: auto;
      }
      .xline-content {
        position: absolute;
        bottom: 0;
        left: 15px;
        right: 15px;
        padding: 15px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .formula-info {
          .formula-icons {
            .far,
            .fas,
            .fab {
              margin-right: 5px;
            }
          }
        }
        .delete-formula {
          padding: 5px;
        }
      }
      .xline-content-top {
        position: absolute;
        top: 0;
        padding: 5px;
        .pop {
          display: none;
        }
        &:hover {
          .show {
            display: none;
          }
          .pop {
            display: block;
          }
        }
      }
      p {
        margin: 0;
        padding: 0;
      }
      .mqlExp {
        font-size: 5px;
      }
    }
  }
  .xDescription {
    margin: 2px;
    text-transform: uppercase;
    letter-spacing: 3px;
    color: #777;
    font-size: 14px;
    font-weight: bold;
  }
  .xSmall {
    margin: 2px;
    letter-spacing: 1px;
    color: #555;
    font-size: 12px;
    font-weight: bold;
  }
  .xFilter {
    font-family: 'Poppins', sans-serif;
    padding: 10px;
    height: 100vh;
    overflow-y: auto;
    //all btns
    button.filter-btn {
      background-color: #262626;
      border: none;
      border-radius: 3px;
      color: #fff;
      width: 100%;
      margin: 0 2px;
      text-transform: uppercase;
      letter-spacing: 3px;
      font-size: 12px;
      padding: 5px;
      .fa-times {
        color: red;
      }
      .fa-check {
        color: green;
      }
      &:hover {
        background-color: #363636;
      }
      &.selected-btn {
        background-color: blue;
      }
      &.limit {
        background-color: #00d090;
      }
      &.type {
        background-color: #5e60ba;
      }
    }
    input.filter-input {
      background-color: #262626;
      border: none;
      color: #888;
      font-size: 12px;
      padding: 5px;
      margin: 0 2px;
      width: 100%;
      &.small {
        padding: 5px;
        width: auto;
        margin: 2px 5px 2px;
        height: 20px;
        border-radius: 3px;
      }
      &:focus {
        outline: none;
      }
    }
    .formula-query,
    .xForm-input {
      margin-bottom: 10px;
    }
    .xForm-btn {
      display: flex;
      justify-content: space-around;
    }
    .filter-actions {
      display: flex;
      justify-content: space-around;
      margin-bottom: 10px;
    }
    .condition-wrap {
      border: 1px solid #333;
      border-radius: 3px;
      padding: 5px;
      color: #aaa;
      .condition-actions {
        margin: 10px 0 0;
        display: flex;
        justify-content: space-between;
      }
      .condition-list {
        margin-top: 15px;
      }
      .condition-constructor {
        display: flex;
        align-items: center;
        .fa-trash {
          margin-right: 5px;
        }
        .condition-constructor-item {
          display: flex;
          flex-wrap: wrap;
        }
      }
    }
    .logic-gate,
    .formula-sorter-items,
    .condition-list {
      color: #aaa;
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 10px;
      padding: 5px;
      p {
        margin: 0;
      }
      select.logic-select {
        margin: 2px 5px 2px;
        padding: 0;
        background: #262626;
        color: #aaa;
        border: none;
        border-radius: 3px;
        &:focus {
          outline: none;
        }
      }
    }
  }
  .newXFilter {
    color: #aaa;
    height: 100%;
    overflow-y: auto;
    button.filter-btn {
      background-color: #9f6cd5;
      border: none;
      border-radius: 3px;
      color: #fff;
      width: 100%;
      text-transform: uppercase;
      letter-spacing: 3px;
      font-size: 12px;
      padding: 10px;
      .fa-check {
        color: green;
      }
      &:hover {
        background-color: #7f4fb3;
      }
      &.selected-btn {
        background-color: blue;
      }
      &:focus {
        outline: none;
      }
      &.limit {
        background-color: #00d090;
      }
      &.type {
        background-color: #5e60ba;
      }
    }
    .tags-box {
      margin: 5px;
      display: flex;
      background-color: #ffffff;
      border-radius: 3px;
      .controller {
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 42px;
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
        &.and {
          background-color: #689f38;
        }
        &.or {
          background-color: #303f9f;
        }
        &:hover {
          filter: brightness(0.85);
        }
        span {
          background-color: transparent;
          text-align: center;
          padding: 5px;
          color: white;
        }
      }
      .input-tags {
        padding: 2px;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        overflow-y: auto;
        max-height: 18vh;
        .tag {
          margin: 2px;
        }
      }
    }
    .action-filter {
      margin: 10px 5px;
      display: flex;
      button {
        margin: 0 5px;
      }
    }
    .cloud-tag {
      border-radius: 3px;
      .tag-group {
        .tag-cells {
          overflow-y: auto;
          max-height: 18vh;
          background-color: #e0e3ec;
          margin-bottom: 10px;
          border-bottom-left-radius: 3px;
          border-bottom-right-radius: 3px;
        }
        .tag-cells-x2 {
          overflow-y: auto;
          max-height: 100vh;
        }
        .tag-cat {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 5px;
          background-color: #babeca;
          border-top-left-radius: 3px;
          border-top-right-radius: 3px;
          p {
            margin: 0;
            font-size: 13px;
            text-transform: uppercase;
            letter-spacing: 2px;
          }
        }
        .tag-cells {
          display: flex;
          flex-wrap: wrap;
          padding: 2px;
        }
      }
    }
    span {
      cursor: default;
      font-size: 14px;
      padding: 2px 5px;
      border-radius: 2px;
      margin: 2px 2px;
      background-color: #9f6cd5;
      color: #fff;
      &.content-tag {
        background-color: #9c27b0;
        color: white;
      }
      &.profile-tag {
        background-color: #536dfe;
        color: white;
      }
      &.timestamp-tag {
        background-color: #795548;
        color: white;
      }
      &.format-tag {
        background-color: #e91e63;
        color: white;
      }
      &.source-tag {
        background-color: #009688;
        color: white;
      }
      &.test-tag {
        background-color: #822264;
        color: white;
      }
      &:hover {
        filter: brightness(85%);
      }
      &:active {
        background-color: #7f4fb3;
        color: white;
      }
    }
  }
  .fixed-box-post {
    background-color: #f1f1f1;
    padding: 10px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    .item-count {
      background-color: #fff;
      border-radius: 2px;
      padding: 2px 10px;
    }
  }
  .xPost {
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
    height: calc(100vh - 145px);
    background-color: #f0f1f5;
    padding: 15px;
    .card-columns {
      column-count: 2;
    }
  }

  .Collapsible__trigger {
    color: #666;
    text-transform: uppercase;
    font-size: 0.75em;
    border-top: 1px solid #e3e3e3;
    border-bottom: 1px solid #e3e3e3;
    width: 100%;
    display: inline-block;
    padding: 15px;
    margin-bottom: 10px;
    cursor: pointer;
    .extraPostGalleryInfo {
      display: flex;
      align-items: center;
      flex-direction: row;
      justify-content: space-between;
      p {
        margin: 0;
      }
    }
  }

  .body-info {
    padding: 10px;
    background-color: white;
    .content-info {
      h6 {
        font-size: 0.75em;
        color: #acacac;
        text-transform: uppercase;
      }
      .row {
        .col-sm-6,
        .col-12 {
          margin-bottom: 20px;
        }
        .post-ordering-input-wrapper {
          .post-order-input {
            background-color: #0cd9d7;
            width: 15%;
            text-align: center;
            border: 2px solid #0cd9d7;
            border-radius: 4px;
            font-size: 12px;
            font-weight: 600;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #ffffff;
          }
        }
        .post-ordering-save-wrapper {
          margin-top: 5px;
          background-color: #d3d6dc;
          width: 15%;
          border: 2px solid #d3d6dc;
          border-radius: 4px;
          font-weight: 600;
          font-size: 11px;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: right;
          color: #ffffff;
          padding-right: 5px;
          cursor: pointer;
        }
        .blue-pill {
          background-color: #5a7dde;
          border-radius: 3px;
          color: #fff;
          font-weight: bold;
          padding: 2px 3px;
          border: 0;
          text-align: left;
          font-size: 0.7em;
          margin-right: 2px;
          margin-bottom: 2px;
        }
        .purple-pill {
          background-color: #9c27b0;
          border-radius: 3px;
          color: #fff;
          font-weight: bold;
          padding: 2px 3px;
          border: 0;
          text-align: left;
          font-size: 0.7em;
          margin-right: 2px;
          margin-bottom: 2px;
        }
        .red-pill {
          background-color: #e91e63;
          border-radius: 3px;
          color: #fff;
          font-weight: bold;
          padding: 2px 3px;
          border: 0;
          text-align: left;
          font-size: 0.7em;
          margin-right: 2px;
          margin-bottom: 2px;
        }
        .orange-pill {
          background-color: #ff8000;
          border-radius: 3px;
          color: #fff;
          font-weight: bold;
          padding: 2px 3px;
          border: 0;
          text-align: left;
          font-size: 0.7em;
          margin-right: 2px;
          margin-bottom: 2px;
        }
        .grish-pill {
          background-color: #388e3c;
          border-radius: 3px;
          color: #fff;
          font-weight: bold;
          padding: 2px 3px;
          border: 0;
          text-align: left;
          font-size: 0.7em;
          margin-right: 2px;
          margin-bottom: 2px;
        }
        .gorish-pill {
          background-color: #09a0bf;
          border-radius: 3px;
          color: #fff;
          font-weight: bold;
          padding: 2px 3px;
          border: 0;
          text-align: left;
          font-size: 0.7em;
          margin-right: 2px;
          margin-bottom: 2px;
        }
        .green-pill {
          background-color: #3ac4a2;
          border-radius: 3px;
          color: #fff;
          font-weight: bold;
          padding: 2px 3px;
          border: 0;
          width: 100%;
          text-align: left;
          font-size: 0.75em;
        }
      }
    }
  }
  .footer-info {
    a {
      color: #bbbbbb;
      &.external-link {
        border-radius: 3px;
        padding: 5px 8px;
        border: 1px solid #dddddd;
      }
    }
  }
  .fixed-box {
    height: 130px;
    display: flex;
    align-content: center;
    flex-direction: column;
  }
}

.disable {
  cursor: not-allowed;
  background-color: #161616 !important;
  color: #333 !important;
  .fas,
  .fab {
    color: #333 !important;
  }
  &:hover {
    background-color: #161616 !important;
  }
}
.disabled {
  cursor: not-allowed !important;
  color: #bbb !important;
  .fas,
  .fab {
    color: #bbb !important;
  }
}
.disabled-svg{
  path{
    fill:#bbb;
  }
}

.xSelector {
  border: 0 !important;
  background-color: #262626;
  border-radius: 3px;
  color: #fff;
  padding: 5px;
}

.newFilterWrap {
  height: 100vh;
  overflow-y: hidden;
  font-family: 'Poppins', sans-serif;
  .upper-filter {
    display: flex;
    align-items: center;
    background-color: #ffffff;
    padding: 5px;
    border-radius: 3px;
    .filter-text {
      margin: 0;
      padding: 5px;
      color: #29292b;
    }
    .xSelector {
      border: 0 !important;
      background-color: #ffffff;
      border-radius: 3px;
      color: #29292b;
      border: 1px solid #fcfcfd;
      padding: 5px;
    }
  }
  .lines-config {
    margin: 5px;
    display: flex;
    .filter-btn {
      margin: 0 5px;
    }
    p {
      margin: 0;
      color: #999;
      padding: 5px;
      border: 1px solid #333;
      border-radius: 3px;
      span {
        cursor: pointer;
        padding: 0 5px;
      }
    }
  }
  .accordion-system {
    margin: 10px;
    height: calc(100vh - 295px);
    overflow-y: auto;
    .line-options {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0;
      margin: 0;
      .fa-bullseye {
        padding: 0;
        margin: 0;
      }
      span {
        flex: 0 0 86%;
        font-size: 12px;
        font-weight: bold;
        padding: 3px;
      }
      i {
        text-align: center;
        cursor: pointer;
        color: #999;
        flex: 0 0 7%;
        font-size: 12px;
        padding: 6px 5px;
        margin: -1px;
        &:hover {
          filter: brightness(150%);
        }
      }
    }
    .accordion-line-wrap {
      margin: 10px 0;
      padding: 5px;
      background-color: #f0f1f5;
      border-radius: 3px;
      .line-selected {
        border: 2px solid blue;
      }
      .Collapsible {
        margin: 0;
        padding: 5px;
        .Collapsible__trigger {
          border: 0;
          margin: 0;
          .line-wrap {
            display: flex;
            justify-content: space-between;
            align-items: center;
            p {
              margin: 0;
              color: white;
              font-size: 12px;
            }
          }
        }
      }
    }
  }
}

button.filter-btn {
  font-family: 'Poppins', sans-serif;
  background-color: #262626;
  border: none;
  border-radius: 3px;
  color: #fff;
  width: 100%;
  text-transform: capitalize;
  letter-spacing: 3px;
  font-size: 12px;
  padding: 5px;
  &.apply {
    background-color: $fluxio-blue;
  }
  &.new-filter {
    background-color: #28272d;
  }
  &.limit {
    background-color: #00d090;
  }
  &.type {
    background-color: #5e60ba;
  }
}

button.filter-btn-red {
  background-color: #ff0000;
  border: none;
  border-radius: 3px;
  color: #fff;
  width: 100%;
  text-transform: uppercase;
  letter-spacing: 3px;
  font-size: 12px;
  padding: 5px;
  &.apply {
    background-color: #43a047;
  }
  &.new-filter {
    background-color: #204fa2;
  }
  &.limit {
    background-color: #00d090;
  }
  &.type {
    background-color: #5e60ba;
  }
}

.profile-dashboard {
  .row {
    justify-content: center;
  }
  .wrapper {
    background-color: #0e0e0e;
    padding: 5px;
    border-radius: 3px;
    .block {
      border-bottom: 1px solid #272727;
      &.social-login {
        padding: 5px;
      }
      button.social-btn {
        background-color: #272727;
        display: block;
        border: none;
        color: #fff;
        width: 100%;
        padding: 5px;
        margin: 5px 0;
        border-radius: 3px;
        &.google {
          background-color: #dd4b39;
        }
        &.facebook {
          background-color: #3b5998;
        }
        &.twitter {
          background-color: #00aced;
        }
      }
    }
  }
  .profile-info-banner {
    .banner {
      background-position: center center;
      background-size: cover;
      background-repeat: no-repeat;
      display: flex;
      padding: 10vh 25px;
      align-items: flex-end;
      .avatar {
        width: 100px;
        height: 100px;
        border: 2px solid #eee;
        box-shadow: 2px 2px 5px #333;
      }
      .name {
        font-weight: bold;
        text-transform: uppercase;
        color: #fff;
        text-shadow: 3px 3px 0px rgba(0, 0, 0, 0.2);
      }
    }
  }
}

.date-pick-wrapper {
  display: flex;
  align-items: center;
  background-color: #fff;
  border-radius: 3px;
  margin: 2px 2px;
  width: 100%;
  p.type-date {
    margin: 0 5px;
    color: #555;
  }
}

.react-datepicker-wrapper {
  margin: 2px;
  .react-datepicker__input-container {
    input {
      color: #555;
      padding: 5px;
      background-color: #fff;
      border: none;
      border-radius: 3px;
    }
  }
}
.react-datepicker-popper {
  .react-datepicker {
    border: 1px solid black;
  }
  .react-datepicker__time-container {
    border-left: 1px solid black;
    .react-datepicker__time-box {
      .react-datepicker__time-list {
        padding: 0 !important;
      }
    }
  }
  .react-datepicker__day {
    color: #555;
    &:hover {
      background-color: #7f4fb3;
      color: #fff;
    }
  }
  .react-datepicker__day--selected {
    background-color: #7f4fb3;
    color: #fff;
  }
  .react-datepicker__time {
    background-color: #e0e3ec;
  }
  .react-datepicker__header {
    border: 0;
    background-color: #babeca;
  }
  .react-datepicker__month-container {
    background-color: #e0e3ec;
    color: #555;
  }
  .react-datepicker-time__header {
    color: 555;
  }
  .react-datepicker__day-name {
    color: #555;
  }
  .react-datepicker__time-list-item {
    color: #555;
    &:hover {
      background-color: #7f4fb3 !important;
      color: #fff;
    }
  }
  .react-datepicker__month-dropdown,
  .react-datepicker__year-dropdown {
    background-color: #babeca;
    border: 1px solid #363636;
    .react-datepicker__month-option,
    .react-datepicker__year-option {
      color: 555;
      &:hover {
        background-color: #7f4fb3;
        color: #fff;
      }
      .react-datepicker__month-option--selected,
      .react-datepicker__year-option--selected {
        padding: 0;
        margin: 0;
        background-color: transparent !important;
      }
    }
  }
  .react-datepicker__month-read-view--down-arrow,
  .react-datepicker__year-read-view--down-arrow {
    padding: 0 !important;
  }
  .react-datepicker__time-list-item--selected {
    background-color: #204fa2;
  }
  .react-datepicker__current-month {
    color: #000;
  }
}
.react-datepicker-wrapper .react-datepicker__input-container input {
  &:focus {
    outline: none;
  }
}

span.react-datepicker__month-read-view--selected-month,
span.react-datepicker__month-read-view--down-arrow,
span.react-datepicker__year-read-view--down-arrow,
span.react-datepicker__year-read-view--selected-year {
  background-color: transparent !important;
}

a.react-datepicker__navigation--previous {
  border-right-color: #555;
}
a.react-datepicker__navigation--next {
  border-left-color: #555;
}
span.react-datepicker__year-read-view--down-arrow {
  border-top-color: #555;
}
span.react-datepicker__month-read-view--down-arrow {
  border-top-color: #555;
}

.modal-post-formula-editor {
  .modal-dialog {
    max-width: 700px;
  }
  .modal-select-tags {
    margin: 15px 0px 0;
    padding: 2px;
    .inputWrapper {
      border-style: none;
      border-radius: 3px;
      background-color: #262626;
      input {
        font-size: inherit;
        padding: 0;
      }
    }
  }
  .modal-second-selector {
    margin-top: 15px;
  }
  .modal-content {
    background-color: #161616;
    color: #aaa;
    .modal-header {
      border-bottom: 1px solid #363636;
      h4.modal-title {
        font-size: 18px;
        letter-spacing: 2px;
        text-transform: uppercase;
      }
      .close {
        color: #eee;
      }
    }
    .modal-body {
      padding: 20px 30px;
      text-transform: uppercase;
      letter-spacing: 1px;
      font-size: 14px;
      .form-editor {
        padding-left: 20px;
        padding-right: 20px;
        .form-control {
          color: #777;
          padding: 5px;
          background-color: #262626;
          border: none;
          border-radius: 3px;
        }
      }
    }
    .modal-footer {
      border-top: 1px solid #363636;
    }
  }
  .avatar-drop-zone {
    padding: 0 20px 0 0;
  }
}

.modal-post-ordering {
  .modal-dialog {
    max-width: 500px;
  }
  .modal-content {
    background-color: #ffffff;
    color: #000000;
    .modal-header {
      border-bottom: 1px solid transparent;
      .close {
        color: $fluxio-blue;
      }
    }
    .modal-body {
      font-family: 'Poppins', sans-serif;
      padding: 0px 30px;
      font-size: 20px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: -0.2px;
      text-align: left;
      color: #27272d;
    }
    .modal-footer {
      border-top: 1px solid transparent;
      .cancel-ordering {
        font-family: 'Poppins', sans-serif;
        background-color: #d3d6dc;
        border: none;
        border-radius: 6px;
        color: #27272d;
        width: 100%;
        text-transform: capitalize;
        letter-spacing: -0.1px;
        font-size: 18px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: -0.1px;
        padding: 5px;
      }
      .save-ordering {
        font-family: 'Poppins', sans-serif;
        background-color: $fluxio-blue;
        border: none;
        border-radius: 6px;
        color: #ffffff;
        width: 100%;
        text-transform: capitalize;
        letter-spacing: -0.1px;
        font-size: 18px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        padding: 5px;
      }
    }
  }
  .avatar-drop-zone {
    padding: 0 20px 0 0;
  }
}

/* Blink animation */
.warning-box {
  transition: all 0.5s ease-in-out;
  animation: blink normal 1.5s infinite ease-in-out;
  &:focus {
    outline: none;
  }
}

.collapse-formula-tags {
  .Collapsible {
    width: 100%;
    .Collapsible__trigger {
      border: none;
      margin-bottom: 0;
    }
  }
  .formula-tags-wrapper {
    display: flex;
    flex-wrap: wrap;
    .formula-tag {
      cursor: default;
      font-size: 14px;
      padding: 2px 5px;
      border-radius: 2px;
      margin: 2px 2px;
      background-color: #363636;
    }
    .formula-tag-active {
      cursor: default;
      font-size: 14px;
      padding: 2px 5px;
      border-radius: 2px;
      margin: 2px 2px;
      background-color: #ff3636;
    }
  }
}

.warning-text {
  color: rgba(255, 0, 0, 1) !important;
}

.success-box {
  border: 1px solid rgba(0, 255, 0, 1) !important;
}

.select2-container {
  .select2-selection {
    background-color: #262626;
    border: none;
    .select2-selection__choice {
      background-color: #363636;
      border: none;
      color: #eee;
    }
  }
}

.profileTagsItemExpand {
  .select2-container {
    z-index: 9999;
    .select2-selection {
      background-color: #d3d3d3;
      border: none;
      .select2-selection__choice {
        background-color: #8aacb8;
        border: none;
        color: #eee;
      }
    }
  }
}

@keyframes blink {
  0% {
    border: 1px solid rgba(255, 0, 0, 1);
  }
  50% {
    border: 1px solid rgba(255, 0, 0, 0.5);
  }
  100% {
    border: 1px solid rgba(255, 0, 0, 1);
  }
}

.b-style {
  background-color: #363636;
  p {
    color: #fff;
  }
}

.avatar-uploader {
  padding-right: 10px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  .avatar-pic {
    width: 100%;
    height: auto;
    border-radius: 3px;
  }
}

.delete-formula-modal {
  .modal-header,
  .modal-footer {
    border: none;
  }
  .modal-content {
    color: #333;
  }
}

.post-ordering-input-wrapper {
  .post-order-input {
    background-color: #0cd9d7;
    width: 100%;
    text-align: center;
    border: 2px solid #0cd9d7;
    border-radius: 4px;
    font-size: 12px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
  }
}
.post-ordering-save-wrapper {
  margin-top: 5px;
  background-color: #d3d6dc;
  width: 100%;
  border: 2px solid #d3d6dc;
  border-radius: 4px;
  font-weight: 600;
  font-size: 11px;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  padding-right: 5px;
  cursor: pointer;
}

.body-info {
  background-color: white;
  .content-info {
    h6 {
      font-size: 0.75em;
      color: #acacac;
      text-transform: uppercase;
    }
    .row {
      .col-sm-6,
      .col-12 {
        margin-bottom: 20px;
      }
    }
  }
}
.blue-pill {
  background-color: #5a7dde;
  border-radius: 3px;
  color: #fff;
  font-weight: bold;
  padding: 2px 3px;
  border: 0;
  text-align: left;
  margin-right: 2px;
  margin-bottom: 2px;
}
.purple-pill {
  background-color: #9c27b0;
  border-radius: 3px;
  color: #fff;
  font-weight: bold;
  padding: 2px 3px;
  border: 0;
  text-align: left;
  margin-right: 2px;
  margin-bottom: 2px;
}
.red-pill {
  background-color: #e91e63;
  border-radius: 3px;
  color: #fff;
  font-weight: bold;
  padding: 2px 3px;
  border: 0;
  text-align: left;
  margin-right: 2px;
  margin-bottom: 2px;
}
.orange-pill {
  background-color: #ff8000;
  border-radius: 3px;
  color: #fff;
  font-weight: bold;
  padding: 2px 3px;
  border: 0;
  text-align: left;
  margin-right: 2px;
  margin-bottom: 2px;
}
.grish-pill {
  background-color: #388e3c;
  border-radius: 3px;
  color: #fff;
  font-weight: bold;
  padding: 2px 3px;
  border: 0;
  text-align: left;
  margin-right: 2px;
  margin-bottom: 2px;
}
.gorish-pill {
  background-color: #09a0bf;
  border-radius: 3px;
  color: #fff;
  font-weight: bold;
  padding: 2px 3px;
  border: 0;
  text-align: left;
  margin-right: 2px;
  margin-bottom: 2px;
}
.green-pill {
  background-color: #3ac4a2;
  border-radius: 3px;
  color: #fff;
  font-weight: bold;
  padding: 2px 3px;
  border: 0;
  text-align: left;
}
