//CTA page share
.shareUs{
  position: fixed;
  left: 0;
  top: 50%;
  z-index: 100;
  transform: translateY(-50%);
  text-align: center;
  h1{
    font-size: 10px;
    display: none;
  }
  a{
    text-decoration: none;
    .circle-share{
      text-align: center;
      font-size: 25px;
      width: 45px;
      height: 45px;
      line-height: 45px;
      border-radius: 50%;
      box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
      margin: 4px;
      display: block;
      &.fa-facebook{
        color: white;
        background-color: #3b5998;
      }
      &.fa-twitter{
        color: white;
        background-color: #1da1f2;
      }
      &.fa-google-plus{
        color: white;
        background-color: #dd4b39;
      }
    }
  }
}

//Share under posts
.post-share{
  text-align: center;
  .fas, .fab{
    text-align: center;
    margin-left: 10px;
    color: #8f8f8f;
    padding: 5px;
    &.fa-link:hover{
      background-color: blue;
      color: white;
      padding: 5px;
      border-radius: 2px;
    }
    &.fa-facebook:hover{
      background-color: #3b5998;
      color: white;
      padding: 5px;
      border-radius: 2px;
    }
    &.fa-twitter:hover{
      background-color: #1da1f2;
      color: white;
      padding: 5px;
      border-radius: 2px;
    }
    &.fa-google-plus:hover{
      background-color: #dd4b39;
      color: white;
      padding: 5px;
      border-radius: 2px;
    }
    &.fa-building:hover{
      background-color: #ff00bf;
      color: white;
      padding: 5px;
      border-radius: 2px;
    }
  }
}


// MEDIA QUERIES

@media screen and (max-width: 1200px) {
  .shareUs {
      display: none !important;
  }
}
