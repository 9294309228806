.cta-fixed{
  position: absolute;
  width: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1031;
}

.cta-fixed-exp{
  position: relative;
  width: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1031;
}

.cta-email{
  .container{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    a{
      outline: none;
      margin: 20px;
      border-radius: 25px;
      .mail-cta-meshapp{
        padding: 10px 50px;
        border: none;
        border-radius: 25px;
        color: #fff;
        outline: none;
        cursor: pointer;
      }
    }
  }
}
