.simple-post-wrapper{
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 40px;
  padding-right: 40px;

  .simple-single-post-description{
    text-align: left;
  }

  .simple-post-title-timeline-wrapper{
    display: flex;
    align-items:center;

    .simple-post-title-timeline {
      font-size: 25px;
      text-align: left;
    }

  }

  .simple-post-image {
    width: 100%;
    height: 40%;
    object-fit: cover;
  }

}
