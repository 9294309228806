.experience-manager,
.profile-dashboard {
  font-family: 'Nunito Sans', sans-serif;
  height: 100%;
  .experience-manager-navbar {
    background: rgba(64, 80, 252, 0.6);
    min-height: 40px;
    display: flex;
    align-items: center;
    color: #fff;
    &.experience-manager-navbar-symbol {
      background: $fluxio-orange-60;
      .tab, .btn {
        &:hover,
        &.selected {
          background: $fluxio-orange; } } }
    .tab {
      padding: 5px;
      cursor: pointer;
      flex: 1 1 0px;
      text-align: center;
      font-weight: 600;
      font-size: 13px;
      letter-spacing: 0.3px;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center; }
    .btn {
      border-radius: 0;
      padding-left: 8px;
      padding-right: 8px;
      img {
        width: 20px;
        height: 20px;
        filter: invert(1); } }
    i {
      color: #fff; }
    .btn, .tab {
      &:hover,
      &.selected {
        background: $fluxio-blue; }
      &.selected-symbol {
        &.selected {
          background: $fluxio-orange; } } } } }

.single-object {
  .single-object-content {
    &.single-object-symbol {
      background: $fluxio-orange-60;
      &.single-object-selected {
        background: $fluxio-orange; } } } }

.finder-actions-bar {
  &.symbol-actions-bar {
    .btn.btn-primary {
      background: $fluxio-orange-60;
      &:hover {
        background: $fluxio-orange; } }
    .fas {
      color: $fluxio-orange; } } }
