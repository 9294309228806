.devices-controller {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  .btn {
    &:hover,
    &.selected {
      color: $fluxio-blue;
      border-color: $fluxio-blue; }
    &.btn-outline {
        .fas {
          margin-right: 0 !important;
          color: inherit !important; } }
    &.btn-fluxio {
      padding: .375rem .75rem; } } }
