.ReactCrop {
  background-color: transparent !important;
  img {
    margin: auto;
    width: 100%; } }

.image-upload-preview {
  width: 100px;
  height: auto;
  // max-height: 200px
 }  // object-fit: cover

.fluxio-image-editor-modal {
  .image-editor-preview {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 20px;
    &.resize-preview {
      max-height: 600px;
      overflow: auto;
      padding: 0;
      img {
        max-width: initial; } } }
  .image-editor-actions {
    display: flex;
    padding: 20px;
    width: 100%; } }
