.loading-div {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  i {
    padding-top: 10px; }
  p {
    margin: 0; }
  .message {
    font-size: 13px;
    letter-spacing: 1px; }
  .loading-ball {
    animation: fadein 2s; } }
