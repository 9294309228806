.post-editor-modal {
  .modal-dialog {
    width: 900px;
    max-width: 100%;
    min-width: 50%; }
  .row {
    height: 100%; }
  .post-preview {
    height: 100%;
    justify-content: center;
    align-items: center;
 }    // background: #f7f8f8
  .draft {
    #text-editor {
      height: 150px; }
    .ql-editor {
      padding-top: 10px !important;
      border: 1px solid #e8ecf1; } } }
