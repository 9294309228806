.delete-modal {
  z-index: 1000001;
  .modal-dialog {
    .modal-content {
      border-radius: 0px;
      .modal-header {
        border: none;
        border-radius: 0px;
        color: #fff;
        background-color: #d7575c;
        padding: 1rem;
        .modal-title {
          font-size: 20px; } }
      .modal-body {
        border-bottom: 1px solid #DDDFEB;
        border-radius: 0px; }
      .modal-footer {
        border: none;
        border-radius: 0px;
        background-color: #ffffff;
        position: relative;
        p.success,
        p.error {
          margin: 0;
          color: #666666;
          text-align: center;
          width: 100%; }
        .loading-btn-exp {
          position: absolute;
          left: 0;
          width: 100%;
          background-color: rgba(0, 0, 0, 0.5);
          top: 0;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 20px; } } } } }
.info-modal {
  z-index: 1000001;
  .modal-dialog {
    .modal-content {
      border-radius: 0px;
      .modal-header {
        border: none;
        border-radius: 0px;
        color: #fff;
        background-color: $fluxio-blue;
        padding: 1rem;
        .modal-title {
          font-size: 20px; }
        button span {
          color:#fff {} } }
      .modal-body {
        border-bottom: 1px solid #DDDFEB;
        border-radius: 0px; }
      .modal-footer {
        border: none;
        border-radius: 0px;
        background-color: #ffffff;
        position: relative;
        p.success,
        p.error {
          margin: 0;
          color: #666666;
          text-align: center;
          width: 100%; }
        .loading-btn-exp {
          position: absolute;
          left: 0;
          width: 100%;
          background-color: rgba(0, 0, 0, 0.5);
          top: 0;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 20px; }
        button.btn-modal {
          border: none;
          border-radius: 3px;
          color: #ffffff;
          padding: 5px 10px;
          &.cancel {
            background-color: #cccccc; }
          &.accept {
            background-color: #d7575c; } } } } } }
